import { CatLoadingIndicator } from '@blocks-react/components/LoadingIndicator';
import PropTypes from 'prop-types';

const LoaderIndicator = ({ loaderClassname }) => {
  return (
    <div className={loaderClassname} data-testid="apiLoader">
      <CatLoadingIndicator />
    </div>
  );
};

LoaderIndicator.propTypes = {
  loaderClassname: PropTypes.string
};
export default LoaderIndicator;
