import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Expander } from 'cat-ecommerce-alloy';
import StoreLocationContactInfo from '../../../../common/StoreLocationContactInfo';
import { dealerLocatorStorePropTypes } from '../../../declarations.proptypes';
import DealerLocationServiceOffered from './DealerLocationServiceOffered';
import DealerLocationStoreHours from './DealerLocationStoreHours';
import DealerLocationExpanderTitle from './DealerLocationExpanderTitle';
import DealerLocationExpanderButtonText from './DealerLocationExpanderButtonText';
import { normalizeUrl, replaceTokensInString } from '../../../../../utils';
import endpoints from '../../../../../constants/endpoints';
import styles from './DealerLocation.module.scss';
import { useFormikContext } from 'formik';
import DealerLocationDetails from '../../DealerLocationDetails';
import Conditional from '@app/components/common/Conditional';
import { PCC_DEALER_LOCATION_FLAG } from '@app/constants/featureFlags';

const DealerLocation = ({
  country,
  distance,
  drivingDirections,
  email,
  isClosest,
  isMapView,
  phone,
  serviceOffered = [],
  storeHours,
  uom,
  website,
  alwaysOpen,
  dealerStoreId,
  ...rest
}) => {
  const { catalogId, langId, storeId } = useSelector(s => s?.common);
  const { seoURL } = useSelector(s => s?.dealer);
  const contactUrl = normalizeUrl(
    replaceTokensInString(
      `${seoURL}${endpoints.CONTACT_US_DEALER}`,
      catalogId,
      langId,
      storeId
    )
  );
  const [t] = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const selectedStoreRef = useRef();
  const ctx = useFormikContext();
  const { storeLocation } = ctx.values;
  const { id } = rest;
  const isSelected = storeLocation === id;
  useEffect(() => {
    if (!isSelected) {
      selectedStoreRef.current = undefined;
    }
  }, [isSelected]);
  useEffect(() => {
    if (!isMapView && selectedStoreRef.current !== undefined) {
      setTimeout(() => {
        selectedStoreRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [isMapView]);

  const dealerLocationFeatureflag = useSelector(
    s => s.featureFlag?.[PCC_DEALER_LOCATION_FLAG]
  );

  const handleExpandable = () => {
    setExpanded(!expanded);
  };

  const hasContactInfo = email || phone || website || drivingDirections;
  const hasServiceOffered = serviceOffered.length > 0;
  return (
    <li
      ref={isSelected ? selectedStoreRef : undefined}
      className="list-group-item mb-0 border-start-0 border-end-0 pe-2 ps-0 py-3 text-univers u-color--DarkGray"
    >
      <Expander
        id="storeLocationExpandable"
        title={<DealerLocationExpanderTitle {...rest} />}
        name="dealer-locator__expander-button"
        buttonWrapperText={
          <DealerLocationExpanderButtonText
            distance={distance}
            isClosest={isClosest}
            uom={uom}
          />
        }
        expanded={expanded}
        setExpanded={handleExpandable}
      >
        <Conditional test={dealerLocationFeatureflag && expanded}>
          <DealerLocationDetails
            storeLocation={id}
            dealerStoreId={dealerStoreId}
          />
        </Conditional>
        <Conditional test={!dealerLocationFeatureflag}>
          <p
            className={cx(
              'exclude-global',
              'u-color--DarkGray',
              'm-0',
              'text-univers',
              'text-start',
              styles['dealer-location__details-indent']
            )}
          >
            {country ?? ''}
          </p>
          <div
            className={cx(
              'row',
              'flex-column',
              'flex-lg-row',
              'pt-3',
              styles['dealer-location__details-indent']
            )}
          >
            {(hasContactInfo || hasServiceOffered) && (
              <div className="col">
                {hasContactInfo && (
                  <section className="mb-4">
                    <header>
                      <h3 className="h6 mb-1 text-start">
                        {t('FOOTER_CUSTOMER_SERVICE')}:
                      </h3>
                    </header>
                    <StoreLocationContactInfo
                      contactUrl={contactUrl}
                      email={email}
                      iconSize={16}
                      phone={phone}
                      website={website}
                      drivingDirections={drivingDirections}
                    />
                  </section>
                )}
                {hasServiceOffered && (
                  <DealerLocationServiceOffered
                    serviceOffered={serviceOffered}
                  />
                )}
              </div>
            )}
            {storeHours && (
              <DealerLocationStoreHours
                storeHours={storeHours}
                alwaysOpen={alwaysOpen}
              />
            )}
          </div>
        </Conditional>
      </Expander>
    </li>
  );
};

export default DealerLocation;

DealerLocation.propTypes = {
  ...dealerLocatorStorePropTypes,
  isMapView: PropTypes.bool,
  uom: PropTypes.string
};
