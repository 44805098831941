import AddEquipmentDrawer from '@app/components/pages/my-equipment/AddEquipmentDrawer';
import EditEquipmentDrawer from '@app/components/pages/my-equipment/EditEquipmentDrawer';
import {
  ADD_EQP_DRAWER_ID,
  EDIT_EQP_DRAWER_ID,
  SELECT_EQP_DRAWER_ID,
  SELECTED_EQP_DRAWER_ID,
  SIS_EQP_DRAWER_ID
} from '@app/constants/commonConstants';
import { useDrawer } from '@cat-ecom/pcc-components/src/hooks/useDrawer';
import { useCallback, useEffect, useState } from 'react';
import { MEQ_DRAWER_TYPE } from './constants';
import SelectEquipmentDrawer from '@app/components/pages/my-equipment/SelectEquipmentDrawer';
import SelectedEquipmentDrawer from '@app/components/pages/my-equipment/SelectedEquipmentDrawer';
import SisEquipmentDrawer from '@app/components/pages/my-equipment/SisEquipmentDrawer';

const useEquipmentDrawer = ({ onClose, onNestedDrawerClose } = {}) => {
  const [currentDrawer, setCurrentDrawer] = useState();
  const [isChild, setIsChild] = useState(false);

  const onDrawerClose = () => {
    if (!isChild) {
      document.body.style.overflow = 'unset';
    }
    setIsChild(false);
    setCurrentDrawer(null);
    if (onClose) {
      onClose();
    }
  };

  const {
    drawerProps: addDrawerProps,
    openDrawer: handleAddOpen,
    closeDrawer: handleAddClose,
    isDrawerOpen: isAddDrawerOpen
  } = useDrawer({
    id: ADD_EQP_DRAWER_ID,
    onClose: () => {
      if (addDrawerProps.isActive) {
        onDrawerClose();
      }
    },
    size: 'default'
  });

  const {
    drawerProps: editDrawerProps,
    openDrawer: handleEditOpen,
    closeDrawer: handleEditClose,
    isDrawerOpen: isEditDrawerOpen
  } = useDrawer({
    id: EDIT_EQP_DRAWER_ID,
    onClose: () => {
      if (editDrawerProps.isActive) {
        onDrawerClose();
      }
    },
    size: 'default'
  });

  const {
    drawerProps: selectDrawerProps,
    handleOpen: handleSelectOpen,
    handleClose: handleSelectClose
  } = useDrawer({
    id: SELECT_EQP_DRAWER_ID,
    onClose: () => {
      if (selectDrawerProps.isActive) {
        onDrawerClose();
      }
    },
    size: 'default'
  });

  const {
    drawerProps: selectedDrawerProps,
    openDrawer: handleSelectedOpen,
    closeDrawer: closeSelectedDrawer,
    isDrawerOpen: isSelectedDrawerOpen
  } = useDrawer({
    id: SELECTED_EQP_DRAWER_ID,
    onClose: () => {
      if (selectedDrawerProps.isActive) {
        onDrawerClose();
      }
    },
    size: 'default'
  });

  const {
    drawerProps: sisDrawerProps,
    openDrawer: handleSisOpen,
    closeDrawer: closeSisDrawer,
    isDrawerOpen: isSisDrawerOpen
  } = useDrawer({
    id: SIS_EQP_DRAWER_ID,
    onClose: () => {
      if (sisDrawerProps.isActive) {
        onDrawerClose();
      }
    },
    size: 'default'
  });

  const delay = callback => {
    setTimeout(() => {
      callback();
    }, 300);
  };

  const openAddDrawer = (_e = null, isChild = false) => {
    setCurrentDrawer(MEQ_DRAWER_TYPE.ADD);
    setIsChild(isChild);
    delay(handleAddOpen);
  };

  const openSelectedDrawer = (_e = null, isChild = false) => {
    setCurrentDrawer(MEQ_DRAWER_TYPE.SELECTED);
    setIsChild(isChild);
    delay(handleSelectedOpen);
  };

  const openEditDrawer = (_e = null, isChild = false) => {
    setCurrentDrawer(MEQ_DRAWER_TYPE.EDIT);
    setIsChild(isChild);
    delay(handleEditOpen);
  };
  const openSelectDrawer = (_e = null, isChild = false) => {
    setCurrentDrawer(MEQ_DRAWER_TYPE.SELECT);
    setIsChild(isChild);
    delay(handleSelectOpen);
  };

  const opensisDrawer = (_e = null, isChild = false) => {
    setCurrentDrawer(MEQ_DRAWER_TYPE.SIS);
    setIsChild(isChild);
    delay(handleSisOpen);
  };

  const closeDrawerHandler = useCallback(
    (drawerToClose, closeParent) => {
      if (closeParent && onNestedDrawerClose) {
        onNestedDrawerClose();
      } else {
        drawerToClose();
      }
    },
    [onNestedDrawerClose]
  );

  const getDrawerProps = useCallback(
    currentDrawerProps => {
      if (isChild) {
        return {
          ...currentDrawerProps,
          disableBackdropClick: true,
          hideCloseButton: true
        };
      }
      return currentDrawerProps;
    },
    [isChild]
  );

  const getDrawerComponent = useCallback(
    ({ currentEquipment = null, myEquipmentList = [] } = {}) => {
      switch (currentDrawer) {
        case MEQ_DRAWER_TYPE.ADD:
          return (
            <AddEquipmentDrawer
              onDrawerClose={(closeParent = false) => {
                closeDrawerHandler(handleAddClose, closeParent);
              }}
              drawerProps={getDrawerProps(addDrawerProps)}
              isChild={isChild}
            />
          );
        case MEQ_DRAWER_TYPE.EDIT:
          return (
            <EditEquipmentDrawer
              currentEquipment={currentEquipment}
              isChild={isChild}
              onDrawerClose={(closeParent = false) => {
                closeDrawerHandler(handleEditClose, closeParent);
              }}
              drawerProps={getDrawerProps(editDrawerProps)}
            />
          );
        case MEQ_DRAWER_TYPE.SELECT:
          return (
            <SelectEquipmentDrawer
              drawerProps={getDrawerProps(selectDrawerProps)}
              isChild={isChild}
              onDrawerClose={(closeParent = false) => {
                closeDrawerHandler(handleSelectClose, closeParent);
              }}
              myEquipmentList={myEquipmentList}
            />
          );
        case MEQ_DRAWER_TYPE.SELECTED:
          return (
            <SelectedEquipmentDrawer
              drawerProps={selectedDrawerProps}
              currentEquipment={currentEquipment}
              onDrawerClose={closeSelectedDrawer}
            />
          );
        case MEQ_DRAWER_TYPE.SIS:
          return (
            <SisEquipmentDrawer
              drawerProps={sisDrawerProps}
              onDrawerClose={closeSisDrawer}
            />
          );
        default:
          return null;
      }
    },
    [
      currentDrawer,
      getDrawerProps,
      addDrawerProps,
      isChild,
      editDrawerProps,
      selectDrawerProps,
      selectedDrawerProps,
      sisDrawerProps,
      closeSisDrawer,
      closeSelectedDrawer,
      closeDrawerHandler,
      handleAddClose,
      handleEditClose,
      handleSelectClose
    ]
  );

  useEffect(() => {
    return () => {
      if (!isChild) {
        document.body.style.overflow = 'unset';
      }
      switch (currentDrawer) {
        case MEQ_DRAWER_TYPE.ADD:
          return handleAddClose();
        case MEQ_DRAWER_TYPE.EDIT:
          return handleEditClose();
        case MEQ_DRAWER_TYPE.SELECT:
          return handleSelectClose();
        case MEQ_DRAWER_TYPE.SELECTED:
          return closeSelectedDrawer();
        case MEQ_DRAWER_TYPE.SIS:
          return closeSisDrawer();
        default:
          return null;
      }
    };
  }, []);

  return {
    isAddDrawerOpen,
    isEditDrawerOpen,
    isSelectedDrawerOpen,
    isSisDrawerOpen,
    openAddDrawer,
    openEditDrawer,
    opensisDrawer,
    openSelectDrawer,
    openSelectedDrawer,
    getDrawerComponent,
    currentDrawer
  };
};

export default useEquipmentDrawer;
