import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { removeCookie, replaceTokensInString } from '@app/utils';
import { useTranslation } from 'react-i18next';
import { Modal } from '@cat-ecom/pcc-components';
import { CatIconWarningTriangle } from '@blocks-react/components/Icons/WarningTriangle';
import { CatHeading } from '@blocks-react/components/Heading';
import { noUcidCookieName } from '../constants';

const PendingApprovalModal = ({ modal }) => {
  const [t] = useTranslation();

  const selectedDealer = useSelector(state => state?.common?.selectedDealer);

  const pendingApprovalModalHeading = replaceTokensInString(
    t('MANUAL_APPROVAL_HEADING'),
    selectedDealer
  );
  const pendingApprovalModalMessage = replaceTokensInString(
    t('MANUAL_APPROVAL_MESSAGE'),
    selectedDealer
  );

  const onModalClose = () => {
    removeCookie(noUcidCookieName);
  };

  return (
    <Modal {...modal.modalProps} onBlModalClose={onModalClose}>
      <div className="text-center py-3 mb-5">
        <div className="d-flex justify-content-center">
          <CatIconWarningTriangle color="warning" size="xl" />
        </div>
        <div className="my-3">
          <CatHeading variant="title">{pendingApprovalModalHeading}</CatHeading>
        </div>
        <p>{pendingApprovalModalMessage}</p>
      </div>
    </Modal>
  );
};

export default PendingApprovalModal;

PendingApprovalModal.propTypes = {
  modal: PropTypes.shape({
    initiatorProps: PropTypes.shape({
      onClick: PropTypes.func
    }),
    modalProps: PropTypes.shape({
      isActive: PropTypes.bool,
      onBlModalClose: PropTypes.func,
      disableBackdropClick: PropTypes.bool,
      disableEscClick: PropTypes.bool,
      heading: PropTypes.string,
      size: PropTypes.string,
      backButton: PropTypes.bool
    }),
    modalControl: PropTypes.shape({
      isActive: PropTypes.bool,
      open: PropTypes.func,
      close: PropTypes.func
    })
  })
};
