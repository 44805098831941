export const PDP_FBT_FLAG = 'PCC_PDPFBTFeature';
export const LUCID_INTEGRATION_FLAG = 'PCCLucidIntegration';
export const DEALER_USER_SHARED_LIST_FLAG =
  'PCC_DealerUserSharedSavedListsFlag';
export const PCC_PDP_LUCID_FLAG = 'PCCPDPLucidFlag';
export const PCC_NAVIGATION_HEADER_SUBHEADER_MENU_FLAG =
  'PCCNavigationHeaderSubheaderMenuFlag';
export const PCC_PDP_LUCID_TAX_DISCLAIMER = 'PCC_TaxInformationMessagesFlag';

export const PCC_PC_LUCID_FLAG = 'PCCLucidProductCategoryIntegrationFlag';
export const SIS_TO_CART_FALLBACK_FEATURE_FLAG =
  'PCC_SISToCartFallbackFeatureFlag';
export const PRIVACY_POLICY_FLAG = 'PCC_ECTPrivacyPolicy';
export const TERMS_CONDITIONS_FEATURE_FLAG = 'PCC_ECTTermsAndConditions';
export const PCC_CAL_FOR_QUOTE_FEATURE_FLAG = 'PCC_CalForQuoteFeatureFlag';
export const PCC_CREATE_QUOTE_BUTTON_POPOVER_TOOLTIP_FLAG =
  'PCC_CreateQuoteButtonPopoverTooltipFlag';
export const PCC_HEADLESS_SHOPPING_CART_FLAG = 'PCC_HeadlessBlocksShoppingCart';
export const PCC_LUCID_SOLR_ADDEQUIPMENTCOMPONENT_FLAG =
  'PCCLucidSolrAddEquipmentComponentFlag';
export const PCC_HEADLESS_SEARCH_FLAG = 'PCC_HeadlessBlocksShoppingCart';
export const EMERGENGY_MESSAGE_FLAG = 'PCC_ECTEmergencyMessageFlag';
export const PCC_EQUIPMENT_CVA_FLAG = 'PCCEquipmentCVAFlag';
export const PCC_HEADLESS_SAVED_LISTS_PAGE = 'PCCHeadlessSavedListsPage';
export const PCC_MACHINE_DETAILS_FLAG = 'PCCMachineDetailsFlag';
export const PCC_HEADLESS_PDP_FLAG = 'PCC_HeadlessBlocksPDP_TopSection';
export const PCC_PARTS_DIAGRAM_URL_FLAG = 'PCCPartsDiagramUrlFlag';
export const PCC_DEALER_ALL_ORDER_HISTORY_FLAG =
  'PCC_DealerUserAllOrderHistorySearchFlag';
export const PCC_HEADLESS_MY_EQUIPMENT_FLAG =
  'PCCHeadlessMyEquipmentLayoutFlag';
export const PCC_MACHINE_SUMMARY_FLAG = 'PCCEquipmentComponentFlag';
export const PCC_UPDATE_USEESPOTS_HOOK_FLAG = 'PCC_Update_useEspots_Hook_Flag';
export const PCC_DEALER_LOCATION_FLAG = 'PCC_ECTDealerLocationFlag';
export const PCC_PS_TITLE_UPDATES = 'PCC_PaymentAndSummary_TitleUpdates';
export const PCC_PS_REPOSITIONING_UPDATES =
  'PCC_PaymentAndSummary_RepositioningOfPaymentSection';
export const PCC_PS_PLACING_ORDER_UPDATES =
  'PCC_PaymentAndSummary_PlacingOrderUpdates';
export const PCC_RANGE_SLIDER_SEARCH_PAGE = 'PCC_RangeSliderSearchPage';
