import { useEffect, useState, useMemo, useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import endpoints from '@app/constants/endpoints';
import useFindDealers from '@app/components/dealer-locator/hooks/useFindDealers/useFindDealers';
import { useForm, FormProvider } from 'react-hook-form';
import {
  useDrawer,
  Modal,
  Drawer,
  DealerLocatorHeader,
  ModalBody,
  DrawerBody,
  ModalFooter,
  DrawerFooter,
  DealerLocatorOptions,
  DealerLocatorMapToggle,
  DealerLocatorFilterButton,
  DealerLocatorSearchForm,
  DealerLocatorSearchSuggestions,
  DealerLocatorSearchResult,
  DealerLocatorSearchResultLoader,
  DealerLocatorCancelButton,
  DealerLocatorFiltersHeader,
  DealerLocatorForm,
  DealerLocatorFiltersSortDropdown,
  DealerLocatorFiltersServiceOfferings,
  DealerLocatorFiltersServiceOfferingsChips,
  DealerLocatorFiltersClearServiceOfferingsButton,
  DealerLocatorMapContainer,
  DealerLocatorSearchResults,
  DealerLocatorStoreContactInfo,
  DealerLocatorServicesOffered,
  DealerLocatorStoreHoursInfo,
  DealerLocatorViewMoreResultsButton,
  DealerLocatorTermsLoader
} from '@cat-ecom/pcc-components';
import { CatButton } from '@blocks-react/components/Button';
import { CatLoadingIndicator } from '@blocks-react/components/LoadingIndicator';
import { CatInlineNotification } from '@blocks-react/components/InlineNotification';
import { CatIconWarningCircle } from '@blocks-react/components/Icons/WarningCircle';
import {
  debounce,
  normalizeUrl,
  replaceTokensInString,
  replaceTokensInUrl
} from '@app/utils';
import {
  useHttp,
  useSystemFeedback,
  useBreakpoint,
  useEspot,
  useHideMapsForTractafricDealer,
  useShowToggleForExtendStoresList
} from '@app/hooks';
import {
  GENERIC_USER_ID,
  PAGE_BREAKPOINTS
} from '@app/constants/commonConstants';
import {
  DEALER_NAME,
  DEFAULT_NUM_MAP_RESULTS,
  DEFAULT_NUM_RESULTS,
  DISTANCE,
  MILLISECONDS_DEBOUNCE,
  ACTIONS
} from './constants';
import {
  OPTIONS,
  SELECTED_STORE_ID,
  SORT_ORDER,
  TERMS_CHECKED
} from '@app/constants/dealerLocatorConstants';
import links from '@app/constants/links';
import DealerLocationListErrorEffect from '../dealer-locator/DealerLocationListForm/DealerLocationListErrorEffect/DealerLocationListErrorEffect';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import useLogout from '../login/hooks/useLogout/useLogout';
import {
  sortClosestVisibleMapStores,
  getFilteredAndOrderedDealerStores
} from './utils';
import Conditional from '../common/Conditional/Conditional';
import useSelectStore from '../dealer-locator/hooks/useSelectStore/useSelectStore';
import reducer from './reducer';
import styles from './styles.module.scss';
import { v4 } from 'uuid';
import { TermsWrapper } from '../common/TermsWrapper';
import Espot from '../common/Espot/Espot';
import DealerStoreDetails from '../common/dealer-other-branches/DealerStoreDetails';
import { PCC_DEALER_LOCATION_FLAG } from '@app/constants/featureFlags';

const DealerLocatorModalWrapper = ({
  modal,
  children,
  showShopAsGuestBtn = false,
  isRegistration = false,
  bobRedirect = null,
  shouldRedirectToShoppingCart = false
}) => {
  const [t] = useTranslation();
  const { logout } = useLogout();
  const { clearError } = useSystemFeedback();
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const {
    invoke: invokeHttp,
    data: dataHttp,
    reset: resetSuggestions
  } = useHttp();
  const {
    invoke,
    reset,
    data: { dealerStores, uom, showInCountryToggle } = {},
    error: dealerError,
    loading: loadingResults,
    numInvocations: numDealerInvocations
  } = useFindDealers();
  const { invoke: invokeSelectStore } = useSelectStore();

  const { isToggleShownForExtendStoreList } =
    useShowToggleForExtendStoresList(showInCountryToggle);

  const defaultOptionsValues = useMemo(() => Array(10).fill(false), []);
  const filterMethods = useForm({
    defaultValues: { options: defaultOptionsValues }
  });
  const storeFormMethods = useForm();
  const selectedStoreUniqueId = storeFormMethods.watch('selectedStoreId');

  const [state, dispatch] = useReducer(reducer, {
    firstStoreId: '',
    keepStoreSelection: false,
    scrollToStore: false
  });
  const [location, setLocation] = useState('');
  const [isInputError, setIsInputError] = useState(false);
  const [isMapToggleChecked, setIsMapToggleChecked] = useState(false);
  const [isExtendMapToggleChecked, setIsExtendMapToggleChecked] =
    useState(false);
  const [isLoadingSelectStore, setIsLoadingSelectStore] = useState(false);
  const [filters, setFilters] = useState(null);
  const [numDisplayed, setNumDisplayed] = useState(DEFAULT_NUM_RESULTS);
  const [areFiltersLoading, setAreFiltersLoading] = useState(false);
  const [espot, setEspot] = useState({ name: null, scrollId: null });

  const storeId = useSelector(s => s.common?.storeId);
  const catalogId = useSelector(s => s.common?.catalogId);
  const langId = useSelector(s => s.common?.langId);
  const areTranslationsLoaded = useSelector(
    state => state.common?.areTranslationsLoaded
  );

  const errorInfo = useSelector(
    s => s?.errors?.[ERROR_DOMAIN.DEALER_LOCATOR]?.[ERROR_PATH.LOCATIONS]
  );
  const isCatCSR = useSelector(s => s.common?.isCatCSR) || false;
  const flagTransactAsGuestFromCSRCartList =
    useSelector(
      state =>
        state?.featureFlag?.PCC_CustomerSupportTransactAsGuestFromCSRCartList
    ) || false;
  const isCsrWithEnabledFlag = isCatCSR && flagTransactAsGuestFromCSRCartList;

  const { isMapHiddenForTractafric } = useHideMapsForTractafricDealer();

  const backButtonMessage = modal.modalProps.backButton
    ? replaceTokensInString(t('GENERAL_BACK_TO'), t('CAT_SELECT_STORE'))
    : replaceTokensInString(t('GENERAL_BACK_TO'), modal.modalProps.heading);

  const termsDrawer = useDrawer({
    id: `${v4()}-terms`,
    size: 'md',
    onClose: () => {
      setEspot({ name: null, scrollId: null });
      modal.modalControl.close();
    }
  });

  const filtersForModal = useDrawer({
    id: `${v4()}-filters`,
    size: 'md',
    onClose: modal.modalControl.close
  });

  const options = [
    t('SERVICES_MACHINE'),
    t('SERVICES_ENGINE_SERVICE'),
    t('SERVICES_POWER_SYSTEMS'),
    t('SERVICES_RENTALS'),
    t('SERVICES_EQUIPT_SALES'),
    t('SERVICES_EQUIPT_SERVICE'),
    t('SERVICES_PARTS_PICKUP'),
    t('SERVICES_PARTS_SALES'),
    t('SERVICES_MARINE'),
    t('SERVICES_TECHNOLOGY_SOLUTIONS')
  ];
  const { CONTACT_DEALER_LINK } = links;
  const contactDealerLink = replaceTokensInUrl(
    CONTACT_DEALER_LINK,
    catalogId,
    langId,
    storeId
  );

  const sortingOptions = useMemo(
    () => [
      { label: t('FYD_SORT_CLOSEST'), value: DISTANCE },
      { label: t('CAT_EMAIL_DEALERNAME'), value: DEALER_NAME }
    ],
    [t]
  );

  const {
    filteredAndOrderedVisibleDealerStores,
    allFilteredDealerStoresByDistance
  } = useMemo(
    () =>
      getFilteredAndOrderedDealerStores(dealerStores, filters, numDisplayed),
    [dealerStores, filters, numDisplayed]
  );

  const filteredAndOrderedVisibleMapDealerStores = useMemo(
    () =>
      sortClosestVisibleMapStores(
        allFilteredDealerStoresByDistance.slice(0, DEFAULT_NUM_MAP_RESULTS)
      ),
    [allFilteredDealerStoresByDistance]
  );

  const {
    dealerName,
    address,
    name: storeName,
    id: selectedLocationId,
    storeId: dealerStoreId,
    displayDealerTermsAndCond
  } = filteredAndOrderedVisibleDealerStores.find(
    s => s.id === selectedStoreUniqueId
  ) || {};

  const dealerLocationFeatureflag =
    useSelector(s => s.featureFlag?.[PCC_DEALER_LOCATION_FLAG]) || false;

  const seoURL = address?.seoURL;
  const { isEspotLoading, getEspotContent } = useEspot(
    espot.name,
    '',
    dealerStoreId
  );
  const espotHtml = getEspotContent(espot.name);

  const listedStores = useMemo(
    () =>
      isMapToggleChecked
        ? [
            allFilteredDealerStoresByDistance.find(
              store => store.id === selectedStoreUniqueId
            )
          ]
        : filteredAndOrderedVisibleDealerStores,
    [
      allFilteredDealerStoresByDistance,
      filteredAndOrderedVisibleDealerStores,
      isMapToggleChecked,
      selectedStoreUniqueId
    ]
  );

  const numSkeletonDisplayed = listedStores?.length || numDisplayed;

  const moreResultsAvailable =
    allFilteredDealerStoresByDistance.length > numDisplayed;

  const filtersCounter = Object.values(filters || {}).filter(
    filterValue => filterValue === true
  ).length;

  const isSkeletonLoaderShown = loadingResults;

  const showFilterErrorMessage =
    dealerStores?.length && !filteredAndOrderedVisibleDealerStores?.length;

  const showNoStoresMapErrorMessage =
    dealerStores &&
    !isSkeletonLoaderShown &&
    !errorInfo &&
    filteredAndOrderedVisibleMapDealerStores.length === 0;

  const showStoresList = !isSkeletonLoaderShown && !!listedStores[0];

  const allowResetDefaultStore =
    listedStores[0] && !state.keepStoreSelection && !isMapToggleChecked;

  const allowKeepStoreSelection =
    state.firstStoreId && selectedStoreUniqueId && !areFiltersLoading;

  const actionButtonsDisabled = isLoadingSelectStore && !errorInfo;

  const selectStoreBtnIsDisabled =
    !!errorInfo ||
    !storeFormMethods.watch(TERMS_CHECKED) ||
    showNoStoresMapErrorMessage ||
    isLoadingSelectStore;

  const optionsValues = filterMethods.watch(OPTIONS);
  const showClearFiltersButton = optionsValues.some(option => !!option);

  const networkErrorTranslation = t('UNKNOWN_ERROR_TITLE');
  const networkErrorHeading =
    networkErrorTranslation.charAt(0) +
    networkErrorTranslation.slice(1).toLowerCase();
  const networkErrorMessage = `${t(errorInfo?.message)} ${
    errorInfo?.code
      ? replaceTokensInString(t('MLP_ERROR4'), errorInfo.code)
      : ''
  }`;

  const onSearchHandler = useCallback(
    ({ searchText, isExtendList = false }) => {
      clearError(ERROR_DOMAIN.DEALER_LOCATOR);
      if (searchText.trim() === '') {
        setLocation('');
        setIsInputError(true);
      } else {
        setLocation(searchText);
        invoke(searchText, true, false, isExtendList);
        setNumDisplayed(DEFAULT_NUM_RESULTS);
        dispatch({ type: ACTIONS.KEEP_STORE_SELECTION, payload: false });
      }
    },
    [clearError, invoke]
  );

  const onKeyPressHandler = text => {
    setLocation(text);
  };

  const onSearchThisAreaClick = center => {
    const locationText = `${center.lat}, ${center.lng}`;
    setLocation(locationText);
    setNumDisplayed(DEFAULT_NUM_RESULTS);
    invoke(locationText, false, true, isExtendMapToggleChecked);
    dispatch({ type: ACTIONS.KEEP_STORE_SELECTION, payload: false });
  };

  const setFirstStoreAsDefault = useCallback(() => {
    storeFormMethods.setValue(
      SELECTED_STORE_ID,
      filteredAndOrderedVisibleDealerStores[0]?.id
    );
  }, [storeFormMethods, filteredAndOrderedVisibleDealerStores]);

  const handleModalClose = () => {
    termsDrawer.drawerControl.close();
    filtersForModal.drawerControl.close();
    modal.modalControl.close();
  };

  const handleTermsClick = (name, scrollId = null) => {
    if (!isLoadingSelectStore) {
      setEspot({ name, scrollId });
      termsDrawer.openDrawer();
    }
  };

  const handleFiltersSubmit = data => {
    const filtersData = {
      sortOrder: data.sortOrder.value,
      Machine: data.options[0],
      'Engine Services': data.options[1],
      'Power Systems': data.options[2],
      Rentals: data.options[3],
      'Equipment Sales': data.options[4],
      'Equipment Service': data.options[5],
      'Parts Pick-up': data.options[6],
      'Parts Sales': data.options[7],
      Marine: data.options[8],
      'Technology Solutions': data.options[9]
    };
    if (!state.keepStoreSelection) {
      setFirstStoreAsDefault();
    }
    setFilters(filtersData);
    setAreFiltersLoading(true);
  };

  const handleCloseTerms = () => {
    termsDrawer.drawerControl.close();
    setEspot({ name: null, scrollId: null });
  };

  const handleCancelFilters = () => {
    const { sortOrder, ...options } = filters || {
      sortOrder: sortingOptions[0].value,
      ...defaultOptionsValues
    };
    const sortOrderValue = sortingOptions.find(
      option => option.value === sortOrder
    );
    filterMethods.setValue(SORT_ORDER, sortOrderValue);
    filterMethods.setValue(OPTIONS, Object.values(options));
    filtersForModal.drawerControl.close();
  };

  const opener = {
    ...filtersForModal,
    initiatorProps: {
      ...filtersForModal.initiatorProps,
      onClick: filtersForModal.initiatorProps.onClick
    }
  };

  const handleViewMore = () =>
    setNumDisplayed(currentNum => currentNum + DEFAULT_NUM_RESULTS);

  const handleMapCheck = () => {
    if (isMapToggleChecked) {
      dispatch({ type: ACTIONS.KEEP_STORE_SELECTION, payload: true });
      const indexOfSelectedStore = allFilteredDealerStoresByDistance.findIndex(
        store => store.id === selectedStoreUniqueId
      );
      const withinDisplayed = indexOfSelectedStore < numDisplayed;
      if (!withinDisplayed) {
        setNumDisplayed(indexOfSelectedStore + 1);
      }
      dispatch({ type: ACTIONS.SCROLL_TO_STORE, payload: true });
    }
    setIsLoadingSelectStore(false);
    setIsMapToggleChecked(!isMapToggleChecked);
  };

  const handleExtendMapCheck = () => {
    setIsExtendMapToggleChecked(prevState => {
      const toggleValue = !prevState;
      onSearchHandler({ searchText: location, isExtendList: toggleValue });
      return toggleValue;
    });
  };

  const userId = useSelector(s => s.common.userId);
  const handleStoreSelectSubmit = () => {
    setIsLoadingSelectStore(true);
    let storeId = '';
    if (shouldRedirectToShoppingCart || userId === GENERIC_USER_ID) {
      const store = listedStores.find(
        store => store.id === selectedStoreUniqueId
      );
      storeId = store?.storeId;
    }
    invokeSelectStore({
      dealerName,
      storeName,
      locationId: selectedLocationId,
      isRegistration,
      isConsentSelected: true,
      fromEspot: false,
      consent: true,
      bobRedirect,
      reorderFunc: null,
      shouldRedirectToShoppingCart,
      selectedStoreId: storeId
    });
  };

  const handleSetScroll = useCallback(
    boolean => {
      if (state.scrollToStore) {
        dispatch({ type: ACTIONS.SCROLL_TO_STORE, payload: boolean });
      }
    },
    [state.scrollToStore]
  );

  const returnContactUrl = id =>
    normalizeUrl(
      replaceTokensInString(
        `${endpoints.CONTACT_US_DEALER}`,
        catalogId,
        langId,
        id
      )
    );

  const resetDealerLocator = useCallback(
    (resetFilters = false) => {
      reset();
      resetSuggestions();
      setLocation('');
      setIsInputError(false);
      setNumDisplayed(DEFAULT_NUM_RESULTS);
      setIsMapToggleChecked(false);
      setIsLoadingSelectStore(false);
      dispatch({ type: ACTIONS.KEEP_STORE_SELECTION, payload: false });
      clearError(ERROR_DOMAIN.DEALER_LOCATOR);
      storeFormMethods.setValue(SELECTED_STORE_ID, null);
      storeFormMethods.setValue(
        TERMS_CHECKED,
        isCsrWithEnabledFlag ? true : false
      );
      if (resetFilters) {
        setFilters(null);
        setAreFiltersLoading(false);
        filterMethods.setValue(SORT_ORDER, sortingOptions);
        filterMethods.setValue(OPTIONS, defaultOptionsValues);
      }
    },
    [
      reset,
      resetSuggestions,
      clearError,
      storeFormMethods,
      isCsrWithEnabledFlag,
      filterMethods,
      sortingOptions,
      defaultOptionsValues
    ]
  );

  const debouncedInvoke = useCallback(
    debounce(url => {
      invokeHttp({ method: 'get', url });
    }, MILLISECONDS_DEBOUNCE),
    [invokeHttp]
  );

  // Call location auto suggest when user enters any text in search form
  useEffect(() => {
    if (location) {
      const url = replaceTokensInUrl(
        endpoints.LOCATION_AUTOSUGGEST,
        storeId,
        location
      );
      debouncedInvoke(url);
    } else {
      resetSuggestions();
    }
  }, [location, storeId, debouncedInvoke, resetSuggestions]);

  // Turn map toggle off if there is a dealer locator service error or if the map search doesn't find a store
  useEffect(() => {
    if (dealerError || showNoStoresMapErrorMessage) {
      setIsMapToggleChecked(false);
    }
  }, [dealerError, showNoStoresMapErrorMessage]);

  useEffect(() => {
    filterMethods.setValue(SORT_ORDER, sortingOptions[0]);
  }, [sortingOptions, filterMethods]);

  // Close the filters modal when there are no errors applying filters
  useEffect(() => {
    if (filteredAndOrderedVisibleDealerStores.length > 0 && areFiltersLoading) {
      filtersForModal.drawerControl.close();
      setAreFiltersLoading(false);
    }
  }, [
    filteredAndOrderedVisibleDealerStores,
    filtersForModal,
    areFiltersLoading,
    setFirstStoreAsDefault
  ]);

  useEffect(() => {
    if (
      filteredAndOrderedVisibleDealerStores.length <= DEFAULT_NUM_RESULTS &&
      !state.keepStoreSelection
    ) {
      setFirstStoreAsDefault();
    }
  }, [
    filteredAndOrderedVisibleDealerStores,
    setFirstStoreAsDefault,
    state.keepStoreSelection
  ]);

  // Reset modal onClose
  useEffect(() => {
    if (!modal.modalControl.isActive) {
      resetDealerLocator(true);
    }
  }, [modal.modalControl.isActive, resetDealerLocator]);

  useEffect(() => {
    if (errorInfo) {
      clearError(ERROR_DOMAIN.DEALER_LOCATOR);
      setIsLoadingSelectStore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreUniqueId, clearError]);

  // set the first store as default if the user never changed default radio selection and the first store in the list changes due to numDisplayed increasing
  useEffect(() => {
    dispatch({
      type: ACTIONS.FIRST_STORE_ID,
      payload: listedStores[0]?.id
    });
    if (state.firstStoreId !== listedStores[0]?.id && allowResetDefaultStore) {
      setFirstStoreAsDefault();
    }
  }, [
    state.firstStoreId,
    listedStores,
    numDisplayed,
    setFirstStoreAsDefault,
    allowResetDefaultStore
  ]);

  // turn setKeepStoreSelection to true if the user changes the default radio button
  useEffect(() => {
    if (
      selectedStoreUniqueId !== state.firstStoreId &&
      !!allowKeepStoreSelection
    ) {
      dispatch({ type: ACTIONS.KEEP_STORE_SELECTION, payload: true });
    }
  }, [selectedStoreUniqueId, state.firstStoreId, allowKeepStoreSelection]);

  useEffect(() => {
    if (isCsrWithEnabledFlag) {
      storeFormMethods.setValue(TERMS_CHECKED, true);
    }
  }, [storeFormMethods, isCsrWithEnabledFlag]);

  return (
    <FormProvider {...storeFormMethods}>
      <DealerLocatorForm>
        <Modal
          {...modal.modalProps}
          className={styles['modal']}
          id={v4()}
          onBlModalClose={handleModalClose}
        >
          <ModalBody>
            <DealerLocatorHeader>
              <Conditional
                test={!!children}
                fallback={t('CHECK_PRICE_ENTER_LOCATION')}
              >
                {children}
              </Conditional>
            </DealerLocatorHeader>
            <div className="px-1">
              <DealerLocatorSearchForm
                isVisible={modal.modalControl.isActive}
                placeholder={isMD ? t('ENTER_LOCATION') : undefined}
                value={location}
                onSearch={search =>
                  onSearchHandler({
                    searchText: search,
                    isExtendList: isExtendMapToggleChecked
                  })
                }
                onKeyPress={onKeyPressHandler}
                isInputError={isInputError}
                setIsInputError={setIsInputError}
                onCancel={resetDealerLocator}
                isSearchLoading={loadingResults}
                //setting isMobile to false so the broken mobile overlay doesn't show
                isMobile={false}
                isDisabled={actionButtonsDisabled}
              >
                <Conditional test={isInputError}>
                  <span slot="error" className="mt-1">
                    {t('FYD_INVALID_LOCATION')}
                  </span>
                </Conditional>
                <DealerLocatorSearchSuggestions
                  search={location}
                  suggestions={dataHttp?.suggestions || []}
                />
              </DealerLocatorSearchForm>
              <Conditional test={!!dealerStores?.length || !!loadingResults}>
                <DealerLocatorOptions>
                  <div className="d-flex flex-column row-gap-3">
                    <Conditional test={!isMapHiddenForTractafric}>
                      <DealerLocatorMapToggle
                        label={t('FYD_MAP_VIEW')}
                        isChecked={isMapToggleChecked}
                        setIsChecked={handleMapCheck}
                        isDisabled={actionButtonsDisabled}
                      />
                    </Conditional>

                    <Conditional test={isToggleShownForExtendStoreList}>
                      <DealerLocatorMapToggle
                        label={t('EXTEND_STORE_LISTING_OUTSIDE_MY_COUNTRY')}
                        isChecked={isExtendMapToggleChecked}
                        setIsChecked={handleExtendMapCheck}
                        isDisabled={actionButtonsDisabled}
                      />
                    </Conditional>
                  </div>
                  <DealerLocatorFilterButton
                    filtersDrawer={opener}
                    filtersCounter={filtersCounter}
                    isDisabled={actionButtonsDisabled}
                  >
                    {t('ARIA_LANDMARK_FILTER')}
                  </DealerLocatorFilterButton>
                </DealerLocatorOptions>
              </Conditional>
            </div>
            {/* To increase height of the modal, so that the list of suggestions can be seen completely */}
            <Conditional test={!numDealerInvocations}>
              <div style={{ minHeight: '100px' }} />
            </Conditional>
            <Conditional test={!!errorInfo}>
              <div className="mt-4">
                <CatInlineNotification
                  heading={networkErrorHeading}
                  variant={errorInfo?.severity || 'error'}
                  data-testid="network-error"
                >
                  <CatIconWarningCircle
                    slot="before"
                    data-testid="error-icon"
                  />
                  {networkErrorMessage}
                  <div slot="after">
                    <CatButton
                      type="button"
                      variant="ghost"
                      className="text-nowrap pt-2 pt-md-0"
                      href={contactDealerLink}
                    >
                      {t('FOOTER_CONTACT_US')}
                    </CatButton>
                  </div>
                </CatInlineNotification>
              </div>
            </Conditional>
            <Conditional test={!!numDealerInvocations}>
              <DealerLocationListErrorEffect error={dealerError} />
            </Conditional>
            <Conditional test={isMapToggleChecked}>
              <DealerLocatorMapContainer
                dealerStores={filteredAndOrderedVisibleMapDealerStores}
                selectedStore={listedStores[0]}
                onSearchThisAreaClick={onSearchThisAreaClick}
                searchThisAreaBtnText={t('FYD_SEARCH_THIS_AREA')}
                loadingResults={isSkeletonLoaderShown}
              />
            </Conditional>
            <Conditional test={isSkeletonLoaderShown}>
              <DealerLocatorSearchResultLoader
                numSkeletonLoaders={numSkeletonDisplayed}
              />
            </Conditional>
            <Conditional test={!!showFilterErrorMessage}>
              <div className="mt-4">
                <CatInlineNotification
                  variant={'error'}
                  data-testid="filter-results-error"
                >
                  <CatIconWarningCircle
                    slot="before"
                    data-testid="error-icon"
                  />
                  {`${t('FYD_NO_RESULTS')} ${t('FYD_ADJUST_SEARCH')}`}
                </CatInlineNotification>
              </div>
            </Conditional>
            <Conditional
              test={showNoStoresMapErrorMessage && !showFilterErrorMessage}
            >
              <div className="mt-4">
                <CatInlineNotification
                  variant={'error'}
                  data-testid="map-results-error"
                >
                  <CatIconWarningCircle
                    slot="before"
                    data-testid="error-icon"
                  />
                  {t('FYD_BLOCKED_COUNTRY_LOCATION')}
                  <div slot="after">
                    <CatButton
                      type="button"
                      variant="ghost"
                      className="text-nowrap pt-2 pt-md-0"
                      href={contactDealerLink}
                    >
                      {t('FOOTER_CONTACT_US')}
                    </CatButton>
                  </div>
                </CatInlineNotification>
              </div>
            </Conditional>
            <Conditional test={showStoresList}>
              <DealerLocatorSearchResults visibleStores={listedStores}>
                {({ store }) => {
                  const { id, isInstantAccess } = store;
                  return (
                    <DealerLocatorSearchResult
                      key={id}
                      unitOfMeasure={uom || 'mi'}
                      closestLabel={t('FYD_RESULTS_CLOSEST')}
                      store={store}
                      setScrollToStore={handleSetScroll}
                      isDisabled={
                        actionButtonsDisabled ||
                        (isCsrWithEnabledFlag && !isInstantAccess)
                      }
                      showNonInstantAccessWarning={
                        isCsrWithEnabledFlag && !isInstantAccess
                      }
                      warningMessage={t(
                        'DEALER_DOES_NOT_ALLOW_GUEST_TRANSACTIONS'
                      )}
                    >
                      <Conditional
                        test={!dealerLocationFeatureflag}
                        fallback={
                          <DealerStoreDetails
                            store={store}
                            returnContactUrl={returnContactUrl}
                          />
                        }
                      >
                        <div className="d-flex flex-wrap flex-column flex-lg-row px-1 py-2">
                          <DealerLocatorStoreContactInfo
                            contactInfoDrivingDirectionsLabel={t(
                              'DRIVING_DIRECTIONS'
                            )}
                            contactInfoEmailLabel={t('MA_EMAIL')}
                            contactInfoTitle={`${t('FOOTER_CUSTOMER_SERVICE')}:`}
                            contactInfoWebsiteLabel={t('CU_WEBSITE')}
                            returnContactUrl={returnContactUrl}
                            store={store}
                          />
                          <DealerLocatorServicesOffered
                            servicesOfferedTitle={`${t('SERVICES_OFFERED')}:`}
                            store={store}
                          />
                          <DealerLocatorStoreHoursInfo
                            store={store}
                            storeHoursOpen24HrsTitle={t(
                              'OPEN_24_HOURS_7_DAYS_A_WEEK'
                            )}
                            storeHoursTitle={`${t('STORE_HOURS')}:`}
                          />
                        </div>
                      </Conditional>
                    </DealerLocatorSearchResult>
                  );
                }}
              </DealerLocatorSearchResults>
              <Conditional test={moreResultsAvailable && !isMapToggleChecked}>
                <DealerLocatorViewMoreResultsButton
                  onClick={handleViewMore}
                  isDisabled={actionButtonsDisabled}
                >
                  {t('VIEW_MORE_PRODUCTS')}
                </DealerLocatorViewMoreResultsButton>
              </Conditional>
            </Conditional>
          </ModalBody>
          <ModalFooter>
            <Conditional
              test={!!selectedStoreUniqueId && !isCsrWithEnabledFlag}
            >
              <TermsWrapper
                isDisabled={actionButtonsDisabled}
                dealerName={dealerName}
                dealerURL={seoURL}
                onTermsClick={handleTermsClick}
                dealerStoreId={dealerStoreId}
                displayDealerTermsAndCond={displayDealerTermsAndCond}
              />
            </Conditional>
            <Conditional test={!!numDealerInvocations}>
              <CatButton
                disabled={selectStoreBtnIsDisabled}
                onClick={storeFormMethods.handleSubmit(handleStoreSelectSubmit)}
                type="button"
                variant="primary"
              >
                {t('CAT_SELECT_STORE')}
                {isLoadingSelectStore && !errorInfo && (
                  <CatLoadingIndicator small slot="after" />
                )}
              </CatButton>
            </Conditional>
            <Conditional
              test={
                !!errorInfo || showShopAsGuestBtn || showNoStoresMapErrorMessage
              }
              fallback={
                <DealerLocatorCancelButton
                  onCancel={modal.modalControl.close}
                  isDisabled={isLoadingSelectStore}
                >
                  {t('CANCEL')}
                </DealerLocatorCancelButton>
              }
            >
              <CatButton type="button" variant="ghost" onClick={logout}>
                {t('SHOP_AS_GUEST')}
              </CatButton>
            </Conditional>
          </ModalFooter>
          <Drawer {...termsDrawer.drawerProps}>
            <DealerLocatorHeader
              onBackButtonClick={handleCloseTerms}
              title={backButtonMessage}
            />
            <DrawerBody>
              <Conditional
                test={!isEspotLoading}
                fallback={<DealerLocatorTermsLoader />}
              >
                <Espot html={espotHtml} scrollId={espot.scrollId} />
              </Conditional>
            </DrawerBody>
          </Drawer>
          <FormProvider {...filterMethods}>
            <DealerLocatorForm>
              <Drawer {...filtersForModal.drawerProps}>
                <DealerLocatorHeader
                  title={backButtonMessage}
                  onBackButtonClick={handleCancelFilters}
                />
                <DrawerBody>
                  <DealerLocatorFiltersHeader>
                    {t('FILTER_AND_SORT')}
                  </DealerLocatorFiltersHeader>
                  <Conditional test={showFilterErrorMessage}>
                    <div className="pt-4 mt-md-2 pb-md-2">
                      <CatInlineNotification
                        variant={'error'}
                        data-testid="filter-drawer-results-error"
                      >
                        <CatIconWarningCircle
                          slot="before"
                          data-testid="error-icon"
                        />
                        {`${t('FYD_NO_RESULTS')} ${t('FYD_ADJUST_SEARCH')}`}
                      </CatInlineNotification>
                    </div>
                  </Conditional>
                  <Conditional test={areTranslationsLoaded}>
                    <DealerLocatorFiltersSortDropdown
                      title={t('SORT')}
                      sortingOptions={sortingOptions}
                    />
                  </Conditional>
                  <DealerLocatorFiltersServiceOfferings
                    title={t('FYD_FILTER_SERVICES_OFFERED')}
                  >
                    <Conditional test={showClearFiltersButton}>
                      <DealerLocatorFiltersClearServiceOfferingsButton>
                        {t('CLEAR_ALL_FILTERS')}
                      </DealerLocatorFiltersClearServiceOfferingsButton>
                    </Conditional>
                    <DealerLocatorFiltersServiceOfferingsChips
                      options={options}
                    />
                  </DealerLocatorFiltersServiceOfferings>
                </DrawerBody>
                <DrawerFooter>
                  <CatButton
                    onClick={filterMethods.handleSubmit(handleFiltersSubmit)}
                    type="button"
                    variant="primary"
                  >
                    {t('APPLY_CHANGES')}
                  </CatButton>
                  <DealerLocatorCancelButton onCancel={handleCancelFilters}>
                    {t('CANCEL')}
                  </DealerLocatorCancelButton>
                </DrawerFooter>
              </Drawer>
            </DealerLocatorForm>
          </FormProvider>
        </Modal>
      </DealerLocatorForm>
    </FormProvider>
  );
};

DealerLocatorModalWrapper.propTypes = {
  modal: PropTypes.shape({
    initiatorProps: PropTypes.shape({
      onClick: PropTypes.func
    }),
    modalProps: PropTypes.shape({
      isActive: PropTypes.bool,
      onBlModalClose: PropTypes.func,
      dismissible: PropTypes.bool,
      disableBackdropClick: PropTypes.bool,
      disableEscClick: PropTypes.bool,
      heading: PropTypes.string,
      size: PropTypes.string,
      backButton: PropTypes.bool
    }),
    modalControl: PropTypes.shape({
      isActive: PropTypes.bool,
      open: PropTypes.func,
      close: PropTypes.func
    })
  }),
  children: PropTypes.node,
  showShopAsGuestBtn: PropTypes.bool,
  isRegistration: PropTypes.bool,
  bobRedirect: PropTypes.object
};

export { DealerLocatorModalWrapper };
