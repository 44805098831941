import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';

import { isEmpty, replaceTokensInString } from '../../utils';
import { normalizeError } from '../exception/utils';
import { clearError, setError } from '../exception/actions';
import {
  getStoreHours,
  processDealerStores
} from '@app/components/dealer-locator/DealerLocatorModal/utils';
import {
  getDealerStoreInfo,
  setDealerStoreInfo
} from '@app/services/cacheService/dealerStoreInfo';
import { USER_TYPE_REGISTERED } from '@app/constants/commonConstants';
import { ERROR_DOMAIN, ERROR_PATH } from '../../constants/errorConstants';
import { PCC_DEALER_LOCATION_FLAG } from '@app/constants/featureFlags';

export const getStoreLocationInfo =
  (
    errorInfo = {},
    includeCutOff = false,
    selectedLocationId,
    isOtherDealerDrawerFlow = false,
    locationStoreId
  ) =>
  (dispatch, getState) => {
    dispatch({ type: types.STORE_GET_INFO_BEGIN });
    const { langId, selectedStore, storeId } = getState().common;
    const dealerLocationFeatureflag =
      getState().featureFlag?.[PCC_DEALER_LOCATION_FLAG] || false;
    const dealerStoreId = dealerLocationFeatureflag ? locationStoreId : storeId;
    const http = getHttpInstance();
    const url = replaceTokensInString(
      endpoints.STORE_LOCATION_INFO,
      dealerStoreId ?? storeId,
      isOtherDealerDrawerFlow ? selectedLocationId : selectedStore,
      langId,
      includeCutOff
    );
    return http
      .get(url)
      .then(({ data: payload } = {}) => {
        dispatch({
          type: types.STORE_GET_INFO_SUCCESS,
          payload: isOtherDealerDrawerFlow
            ? {
                [selectedLocationId]: {
                  ...payload,
                  geo: {
                    latitude: payload.latitude,
                    longitude: payload.longitude
                  },
                  storeHours: getStoreHours(payload.storeHours),
                  services: payload.serviceOffered,
                  address: { ...payload.address, phone: payload.phone }
                }
              }
            : payload
        });
        dispatch(
          clearError(ERROR_DOMAIN.HEADER, ERROR_PATH.CURRENT_LOCATION_POPUP)
        );
      })
      .catch(error => {
        dispatch({
          type: types.STORE_GET_INFO_FAIL
        });
        const { domain, path } = errorInfo;
        if (domain && path) {
          dispatch(setError(domain, path, normalizeError(error)));
        }
        dispatch(
          setError(ERROR_DOMAIN.HEADER, ERROR_PATH.CURRENT_LOCATION_POPUP, {
            ...normalizeError(
              error,
              {
                severity: 'warning',
                message: 'UNKNOWN_ERROR_MESSAGE',
                title: undefined
              },
              true
            )
          })
        );
      });
  };
export const getOtherStoresInfo = stores => async (dispatch, getState) => {
  dispatch({ type: types.OTHER_STORES_INFO_BEGIN });
  const {
    langId,
    selectedStore,
    storeId,
    userId,
    selectedCustomerNumber,
    selectedEndUseCode,
    selectedOrderType
  } = getState().common;
  const { dealerName, seoURL } = getState()?.dealer;
  let storeInfo = await getDealerStoreInfo({
    userId,
    langId,
    storeId,
    selectedStore,
    dcn: selectedCustomerNumber,
    endUseCode: selectedEndUseCode?.value,
    orderType: selectedOrderType?.value
  });
  const http = getHttpInstance();
  try {
    if (isEmpty(storeInfo)) {
      const locationData = [];
      const url = replaceTokensInString(
        endpoints.DEALER_CUSTOMER_LOCATIONS,
        storeId,
        selectedCustomerNumber,
        selectedStore
      );
      let { data: payload } = await http.get(url);
      if (payload && !!payload.dealerStores.length) {
        payload = {
          ...payload,
          dealerStores: payload.dealerStores.map(itm => ({
            ...itm,
            dealerStoreId: storeId,
            seoURL,
            dealerName,
            storeHours: []
          }))
        };
      }

      storeInfo = processDealerStores({
        ...payload
      });
      await setDealerStoreInfo({
        langId,
        userId,
        storeId,
        selectedStore,
        dcn: selectedCustomerNumber,
        storeInfo,
        endUseCode: selectedEndUseCode?.value,
        orderType: selectedOrderType?.value
      });
    }
    dispatch({
      type: types.OTHER_STORES_INFO_SUCCESS,
      payload: { otherStores: storeInfo }
    });
  } catch {
    dispatch({
      type: types.OTHER_STORES_INFO_FAIL
    });
  }
};
