import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Conditional,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  HeaderStoreLocationMap
} from '@cat-ecom/pcc-components';
import { AFFILIATION_DEALER } from '@app/constants/commonConstants';
import { CatHeading } from '@blocks-react/components/Heading';
import { useCurrentPage } from '@app/hooks/useCurrentPage';
import useHideMapsForTractafricDealer from '@app/hooks/useHideMapsForTractafricDealer';
import StoreAddress from '../StoreAddress/StoreAddress';
import StoreServiceOfferedWrapper from '../StoreServiceOfferedWrapper/StoreServiceOfferedWrapper';
import StoreHoursWrapper from '../StoreHoursWrapper/StoreHoursWrapper';
import StoreLocationSelector from '../StoreLocationSelector/StoreLocationSelector';
import CustomerServiceWrapper from '../CustomerService/CustomerServiceWrapper';
import { useTranslation } from 'react-i18next';

const StoreDrawer = ({
  closeDrawer,
  drawerProps,
  openAssociatedDealerDrawer,
  openDealerLocatorDrawer
}) => {
  const { t } = useTranslation();
  const latitude = useSelector(s => s?.store?.latitude);
  const longitude = useSelector(s => s?.store?.longitude);
  const dealerName = useSelector(state => state.dealer?.dealerName);
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;

  const { isOnShoppingCartPage } = useCurrentPage();
  const { isMapHiddenForTractafric } = useHideMapsForTractafricDealer();

  const showStoreLocationSelector = !isOnShoppingCartPage || isDealerUser;

  return (
    <Drawer {...drawerProps}>
      <DrawerHeader>
        <CatHeading variant="title">{t('CAT_SELECT_STORE')}</CatHeading>
      </DrawerHeader>
      <DrawerBody>
        <Conditional test={!isMapHiddenForTractafric}>
          <HeaderStoreLocationMap latitude={latitude} longitude={longitude} />
        </Conditional>
        <Conditional test={!!dealerName}>
          <CatHeading level="h2" variant="title-sm">
            {dealerName}
          </CatHeading>
        </Conditional>
        <StoreAddress />
        <CustomerServiceWrapper />
        <StoreServiceOfferedWrapper />
        <StoreHoursWrapper />
      </DrawerBody>
      <Conditional test={showStoreLocationSelector}>
        <DrawerFooter>
          <StoreLocationSelector
            closeDrawer={closeDrawer}
            openAssociatedDealerDrawer={openAssociatedDealerDrawer}
            openDealerLocatorDrawer={openDealerLocatorDrawer}
          />
        </DrawerFooter>
      </Conditional>
    </Drawer>
  );
};

StoreDrawer.propTypes = {
  drawerProps: PropTypes.shape({
    align: PropTypes.string,
    hasBackdrop: PropTypes.bool,
    id: PropTypes.string,
    isActive: PropTypes.bool,
    onBlDrawerClose: PropTypes.func,
    size: PropTypes.string
  }),
  closeDrawer: PropTypes.func.isRequired,
  openAssociatedDealerDrawer: PropTypes.func.isRequired,
  openDealerLocatorDrawer: PropTypes.func.isRequired
};

export default StoreDrawer;
