import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Conditional,
  DealerLocatorServicesOffered,
  DealerLocatorStoreContactInfo,
  DealerLocatorStoreHoursInfo
} from '@cat-ecom/pcc-components';
import { storeHoursPropTypes } from '@app/components/dealer-locator/declarations.proptypes';
import { getStoreLocationInfo } from '@app/store/store/actions';
import { CatSkeleton } from '@blocks-react/components/Skeleton';

const DealerStoreDetails = ({ store, returnContactUrl = () => {} }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const locationCode = store?.id?.substring(store?.storeId?.length);
  const storeDetails = useSelector(s => s?.store?.[locationCode]);
  const locationStoreId = store?.storeId;
  const isDisplayStoreDetailsLoading = useSelector(
    s => s.store?.getStoreLocationInfoLoading
  );

  useEffect(() => {
    if (!storeDetails) {
      dispatch(
        getStoreLocationInfo({}, true, locationCode, true, locationStoreId)
      );
    }
  }, [storeDetails, locationCode, locationStoreId]);
  return (
    <>
      <Conditional
        test={!!storeDetails}
        fallback={
          isDisplayStoreDetailsLoading ? <CatSkeleton size="xl" /> : null
        }
      >
        <div className="d-flex flex-wrap flex-column flex-lg-row px-1 py-2">
          <DealerLocatorStoreContactInfo
            contactInfoDrivingDirectionsLabel={t('DRIVING_DIRECTIONS')}
            contactInfoTitle={`${t('FOOTER_CUSTOMER_SERVICE')}:`}
            contactInfoWebsiteLabel={t('CU_WEBSITE')}
            store={storeDetails}
            returnContactUrl={returnContactUrl}
          />
          <DealerLocatorServicesOffered
            servicesOfferedTitle={`${t('SERVICES_OFFERED')}:`}
            store={storeDetails}
          />
          <DealerLocatorStoreHoursInfo
            store={storeDetails}
            storeHoursOpen24HrsTitle={t('OPEN_24_HOURS_7_DAYS_A_WEEK')}
            storeHoursTitle={`${t('STORE_HOURS')}:`}
          />
        </div>
      </Conditional>
    </>
  );
};
DealerStoreDetails.propTypes = {
  store: PropTypes.shape({
    geo: PropTypes.shape({
      distance: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      isClosest: PropTypes.bool
    }),
    website: PropTypes.string,
    dealerName: PropTypes.string,
    address: PropTypes.shape({
      zipCode: PropTypes.string,
      country: PropTypes.string,
      city: PropTypes.string,
      phone: PropTypes.string,
      state: PropTypes.string,
      line3: PropTypes.string,
      line2: PropTypes.string,
      line1: PropTypes.string,
      email: PropTypes.string,
      seoURL: PropTypes.string
    }),
    storeHours: storeHoursPropTypes,
    name: PropTypes.string,
    storeLocationCode: PropTypes.string,
    id: PropTypes.string,
    services: PropTypes.arrayOf(PropTypes.string),
    storeId: PropTypes.string
  }).isRequired,
  returnContactUrl: PropTypes.func
};

export default DealerStoreDetails;
