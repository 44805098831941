export const FULFILLMENT_TYPE_PICKUP = 'pickUp';
export const FULFILLMENT_TYPE_SHIPTO = 'shopOnline';
export const FULFILLMENT_TYPE_DROPBOX = 'dropBox';

export const IS_FULFILLMENT_TYPE_PICKUP_PASSED = 'isPickupCutOffTimePassed';
export const IS_FULFILLMENT_TYPE_SHIPTO_PASSED = 'isShopOnlineCutOffTimePassed';
export const IS_FULFILLMENT_TYPE_DROPBOX_PASSED = 'isDropBoxCutOffTimePassed';

export const IMAGE_PREFIX = 'checkout-image';
export const SECTION_VIEW = 'showImages';
export const SECTION_PAYMENT = 'paymentInfo';
export const SECTION_FULFILLMENT = 'pickupAndDevliery';
export const SECTION_CART = 'shoppingCart';
export const SECTION_LOGIN = 'login';
export const SECTION_ORDER_DETAILS = 'orderDetails';
export const MAX_FULFILLMENT_INST_LEN = 150;
export const MAX_PO_NUM_LEN = 20;

export const SECTION_VIEW_TITLE = 'VIEW';
export const SECTION_PAYMENT_TITLE = 'PAYMENT_INFORMATION';
export const SECTION_FULFILLMENT_TITLE = 'PICKUP_DELIVERY_LONG';
export const SECTION_CART_TITLE = 'SHOPPINGCART_TITLE';
export const SECTION_LOGIN_TITLE = 'LOGIN';
export const SECTION_ORDER_DETAILS_TITLE = 'MO_ORDERDETAILS';

export const SECTION_VIEW_NAV = 'VIEW';
export const SECTION_PAYMENT_NAV = 'PAYMENT_INFORMATION';
export const SECTION_FULFILLMENT_NAV = 'PICKUP_DELIVERY_SHORT';
export const SECTION_CART_NAV = 'SHOPPINGCART_TITLE';
export const SECTION_LOGIN_NAV = 'LOGIN';
export const SECTION_ORDER_DETAILS_NAV = 'ORDER_DETAILS';

export const SAVE_BTN_TEXT = 'MA_PREF_SAVE_PREFERENCES';
export const ESPOT_TESTID = 'espotData';
export const CUSTOMER_TYPE_IA = 'IA';
export const CUSTOMER_TYPE_CSP = 'CSP';
export const NAVBAR_OFFSET = -155; // Magic number for scrolling a section to the top when navigation bar is present
export const PREF_LANG_CHANGE_SUCCESSFUL = 'isPrefLangChangeSuccessful';

export const DISPLAY_QUOTING_TOGGLE = 'Y';

export const CATSAS_SHOPPINGPREFERENCES_ESPOT_1 =
  'CATSAS_SHOPPINGPREFERENCES_ESPOT-1';
export const CATSAS_SHOPPINGPREFERENCES_ESPOT_2 =
  'CATSAS_SHOPPINGPREFERENCES_ESPOT-2';

export const VIEWS = {
  COMPACT: 'C',
  STANDARD: 'S'
};

export const AVAILABILITY = {
  SOURCE: 'S',
  DATE: 'D'
};
const PART_OR_ITEMS_TOGGLES_DEFAULT = {
  showItemRowNumber: true,
  expandAdditionalInfo: false,
  displayImages: false
};
const DISPLAY_NOTES_TOGGLES_DEFAULT = {
  showEquipmentNumber: true,
  showLineItemNote: true,
  showCustomerPartNumber: true,
  showCustomerItemNumber: true
};
export const DEFAULT_CART_SETTINGS_STANDARD = {
  pageView: 'S',
  showAvailability: 'D',
  showFulfillment: 'P',
  partOrItem: PART_OR_ITEMS_TOGGLES_DEFAULT,
  displayNotes: DISPLAY_NOTES_TOGGLES_DEFAULT,
  isDefaultSettings: true
};

export const DEFAULT_CART_SETTINGS_COMPACT = {
  pageView: 'C',
  showAvailability: 'S',
  showFulfillment: 'P',
  partOrItem: { ...PART_OR_ITEMS_TOGGLES_DEFAULT, displayImages: true },
  displayNotes: DISPLAY_NOTES_TOGGLES_DEFAULT,
  isDefaultSettings: true
};
