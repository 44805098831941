import PropTypes from 'prop-types';
import { Conditional } from '@cat-ecom/pcc-components';
import { CatButton } from '@blocks-react/components/Button';

export const AssociatedDealerViewMoreButton = ({
  showButton,
  onClick,
  disabled,
  children
}) => {
  return (
    <Conditional test={showButton}>
      <div className="w-100 text-center">
        <CatButton
          variant="bare"
          type="button"
          className="text-center"
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </CatButton>
      </div>
    </Conditional>
  );
};

AssociatedDealerViewMoreButton.propTypes = {
  showButton: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.element
};
