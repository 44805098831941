import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { CatToast } from '@blocks-react/components/Toast';
import { CatIconWarningTriangle } from '@blocks-react/components/Icons/WarningTriangle';
import { CatHeading } from '@blocks-react/components/Heading';
import styles from './CSROrderByGuestWarning.module.scss';
import { BANNER_TOASTS } from '../constants';
import BannerToastOnce from '../BannerToastOnce';

const CSROrderByGuestWarning = () => {
  const searchParams = useLocation().search;
  const { orderId } = queryString.parse(searchParams);

  const isOrderDetailsPage =
    searchParams.includes('OrderDetail') ||
    searchParams.includes('OrderReturnRequest');
  const isCatCSR = useSelector(s => s.common.isCatCSR) || false;
  const orderUserType = useSelector(
    s => s?.orders?.byId[orderId]?.userType || null
  );
  const orderCreatedByGuestUser = orderUserType === USER_TYPE_GUEST;
  const isPCCCustomerSupportCSRBannerMessaging =
    useSelector(s => s.featureFlag?.PCC_CustomerSupportCSRBannerMessaging) ??
    false;
  const shouldRender =
    !isPCCCustomerSupportCSRBannerMessaging &&
    isOrderDetailsPage &&
    isCatCSR &&
    orderCreatedByGuestUser;

  if (shouldRender) {
    return (
      <BannerToastOnce name={BANNER_TOASTS.guestWarning.name}>
        <CatToast variant="warning" className={styles.toastGuestWarning}>
          <CatIconWarningTriangle slot="before" />
          <CatHeading variant="label-sm">
            {BANNER_TOASTS.guestWarning.message}
          </CatHeading>
        </CatToast>
      </BannerToastOnce>
    );
  }

  return null;
};

export default CSROrderByGuestWarning;
