import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  ErrorBanner,
  MyEquipmentButton
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CatHeading } from '@blocks-react/components/Heading';
import useEquipmentActions from '@app/hooks/useEquipmentActions';
import EquipmentActionIcon from '@app/components/common/Blocks/EquipmentActionIcon';
import { useSelector } from 'react-redux';
import { CatIconInfo } from '@blocks-react/components/Icons/Info';
import { isMLP, replaceTokensInString } from '@app/utils';
import { setPendingToastforMLPRedirect } from '@app/store/myequipment/utils';
import useEquipmentDrawer from '@app/hooks/useEquipmentDrawer';
import MyEquipmentViewModal from '@app/components/pages/my-equipment/MyEquipmentViewModal';
import { useCallback, useState } from 'react';
import styles from './SelectedEquipmentDrawer.module.scss';
import { SELECT_EQUIPMNET_IMAGE_PARAMS } from '../constant';
import { startCase, camelCase } from 'lodash';
import cx from 'classnames';
import { capitalizeText } from '@app/utils/stringUtils';
import { BUTTON_VARIANTS } from '@app/constants/commonConstants';
import EquipmentActionCard from '../../alp/EquipmentInfo/EquipmentDetails/EquipmentActions/EquipmentActionCard';

const SelectedEquipmentDrawer = ({
  drawerProps,
  currentEquipment,
  onDrawerClose
}) => {
  const [t] = useTranslation();
  const [isModalActive, setIsModalActive] = useState(false);
  const onClose = useCallback(() => {
    onDrawerClose();
  }, [onDrawerClose]);

  const {
    openEditDrawer,
    getDrawerComponent,
    openSelectDrawer,
    openAddDrawer
  } = useEquipmentDrawer({ onNestedDrawerClose: onClose });

  const { equipmentActions } = useEquipmentActions(currentEquipment, {
    openEditDrawer: () => openEditDrawer(null, true),
    openSelectDrawer: () => openSelectDrawer(null, true)
  });

  const {
    VIEW_DASHBOARD,
    SHOP_PARTS,
    CHANGE_EQUIPMENT_SELECTED,
    EDIT_SELECTED,
    CLEAR_SELECTION_SELECTED
  } = equipmentActions;

  const {
    model,
    serialNumber,
    equipmentFamily = '',
    imageURL,
    assetId,
    validatedAsset
  } = currentEquipment;
  const equipmentList = useSelector(
    s => s.myEquipment.equipments.myEquipmentList
  );
  const buttonProp = {
    buttonText: t('ADD_SERIAL_NUMBER_NEW'),
    className: 'text-nowrap ms-n3',
    slot: 'after',
    variant: BUTTON_VARIANTS.GHOST,
    onClick: () => openEditDrawer(null, true)
  };

  const equipment = `${model} ${startCase(camelCase(equipmentFamily))}`;
  const myEquipmentImprovementsFlag = useSelector(
    state => state.featureFlag.PCCMyEquipmentImprovementsFlag
  );

  const showChangeEquipment = equipmentList.length > 1;

  const requiredActions = [
    ...(myEquipmentImprovementsFlag ? [VIEW_DASHBOARD] : []),
    SHOP_PARTS,
    ...(showChangeEquipment ? [CHANGE_EQUIPMENT_SELECTED] : []),
    EDIT_SELECTED,
    CLEAR_SELECTION_SELECTED
  ];

  const imageParams = SELECT_EQUIPMNET_IMAGE_PARAMS;

  const actionHandler = cardAction => {
    if (
      cardAction.text === 'SHOP_PARTS' ||
      cardAction.text === 'MLP_MEQ_KABOB1'
    ) {
      const toastContent = replaceTokensInString(
        t('CURRENTLY_SHOPPING'),
        model,
        capitalizeText(equipmentFamily)
      );
      if (!isMLP()) {
        setPendingToastforMLPRedirect(toastContent, 'createAddToast', false);
      }
      onDrawerClose();
    } else if (cardAction.text === 'MA_EDIT' && validatedAsset) {
      setIsModalActive(true);
    } else if (cardAction.text === 'QUICK_CLEAR') {
      onDrawerClose();
    }
    cardAction.onclickHandler();
  };

  return (
    <>
      <Drawer {...drawerProps} className={`${styles['drawer-index']}`}>
        <DrawerHeader>
          <CatHeading variant="title-sm">
            {capitalizeText(t('CURRENTLY_SHOPPING_FOR'))}
          </CatHeading>
        </DrawerHeader>
        <DrawerBody>
          {!serialNumber && (
            <div className="mb-3">
              <ErrorBanner
                heading={t('MISSING_SERIAL_NUMBER')}
                variant="default"
                buttonProp={buttonProp}
              >
                <CatIconInfo slot="before" />
                {t('ADD_CAT_SERIAL_NUMBER')}
              </ErrorBanner>
            </div>
          )}
          <figure>
            <img src={`${imageURL}${imageParams}`} alt={equipmentFamily} />
          </figure>
          {assetId && <CatHeading variant="title">{assetId}</CatHeading>}
          <div className="mt-1 mb-1">
            <CatHeading variant={!assetId ? 'title' : 'body-sm'}>
              {equipment}
            </CatHeading>
          </div>
          {serialNumber && (
            <CatHeading variant="body-sm">{serialNumber}</CatHeading>
          )}
          <div
            className={cx([
              'pb-1 mt-4',
              styles['equipment-actions__container']
            ])}
          >
            {requiredActions.map(cardAction => (
              <EquipmentActionCard
                key={cardAction.key}
                handleClick={() => actionHandler(cardAction)}
                classname={cx(['p-0', styles['equipment-actions__button']])}
                iconClassName={styles['equipment-actions__button-icon']}
                icon={
                  <EquipmentActionIcon
                    key={cardAction.key}
                    name={cardAction.key}
                    iconControls={{ color: 'content-strong' }}
                  />
                }
              >
                <label
                  className={cx([
                    'pt-2 m-0',
                    'cat-u-theme-typography-label-sm',
                    styles['equipment-actions__button--label']
                  ])}
                >
                  {cardAction.text === 'EDIT'
                    ? t('MEQ_EDIT_EQUIP')
                    : t(cardAction.text)}
                </label>
              </EquipmentActionCard>
            ))}
          </div>
          <MyEquipmentButton onClick={() => openAddDrawer(null, true)}>
            {t('GA_LABEL_ADD_EQUIPMENT')}
          </MyEquipmentButton>
        </DrawerBody>
      </Drawer>
      {getDrawerComponent({
        currentEquipment: currentEquipment,
        myEquipmentList: equipmentList
      })}

      {isModalActive && (
        <MyEquipmentViewModal
          isModalActive={isModalActive}
          setIsActive={setIsModalActive}
          classname={`${styles['modal-index']}`}
        />
      )}
    </>
  );
};

SelectedEquipmentDrawer.propTypes = {
  drawerProps: PropTypes.object,
  currentEquipment: PropTypes.shape({
    serialNumber: PropTypes.string,
    productId: PropTypes.string,
    equipmentFamily: PropTypes.string,
    multiple: PropTypes.bool,
    validatedAsset: PropTypes.bool,
    unValidatedEquipmentId: PropTypes.string,
    validSerialAndModel: PropTypes.bool,
    equipmentUniqueId: PropTypes.string,
    isQRCodeTempEquipment: PropTypes.bool,
    seoUrl: PropTypes.string,
    isCEModel: PropTypes.bool,
    uniqueIndex: PropTypes.string,
    assetId: PropTypes.string,
    imageURL: PropTypes.string,
    model: PropTypes.string,
    dealerMakeCode: PropTypes.string,
    unValidatedItemListId: PropTypes.string
  }),
  onDrawerClose: PropTypes.func
};

export default SelectedEquipmentDrawer;
