import { ErrorBanner } from '@cat-ecom/pcc-components';
import { CatIconWarningCircle } from '@blocks-react/components/Icons/WarningCircle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty, replaceTokensInUrl } from '@app/utils';
import cx from 'classnames';
import styles from './ErrorBanner.module.scss';
import links from '@app/constants/links';

const ErrorBannerMessage = () => {
  const [t] = useTranslation();
  const catalogId = useSelector(state => state.common.catalogId);
  const storeId = useSelector(state => state.common.storeId);
  const langId = useSelector(state => state.common.langId);
  const myEquipmentErrors = useSelector(state => state.myEquipment.error ?? {});
  const isEquipmentError = !isEmpty(myEquipmentErrors);
  const showErrorBanner = isEquipmentError;
  const heading = 'UNKNOWN_ERROR_MESSAGE';

  const handleContactUsClick = e => {
    e.stopPropagation();
    if (catalogId && storeId && langId) {
      window.location.href = replaceTokensInUrl(
        links.CONTACT_DEALER_LINK,
        catalogId,
        langId,
        storeId
      );
    }
  };
  const buttonProp = {
    buttonText: t('FOOTER_CONTACT_US'),
    className: 'text-nowrap',
    slot: 'after',
    type: 'button',
    onClick: e => handleContactUsClick(e)
  };
  const classnames = cx({
    [styles['error-banner-container__marginBotton']]: isEquipmentError
  });
  return (
    <>
      {showErrorBanner && (
        <div className={classnames}>
          <ErrorBanner
            heading={t(heading)}
            variant="error"
            buttonProp={buttonProp}
          >
            <CatIconWarningCircle slot="before" />
          </ErrorBanner>
        </div>
      )}
    </>
  );
};

export default ErrorBannerMessage;
