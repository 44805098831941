import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import {
  AssociatedDealerForm,
  AssociatedDealerPriceWarning,
  DrawerBody,
  DrawerFooter,
  DrawerBackHeader,
  DrawerHeader,
  AssociatedDealerBanner
} from '@cat-ecom/pcc-components';
import { CatButton } from '@blocks-react/components/Button';
import { CatLoadingIndicator } from '@blocks-react/components/LoadingIndicator';
import { CatIconWarningTriangle } from '@blocks-react/components/Icons/WarningTriangle';
import { CatInlineNotification } from '@blocks-react/components/InlineNotification';
import { CatIconAdd } from '@blocks-react/components/Icons/Add';
import Conditional from '@app/components/common/Conditional/Conditional';
import {
  AssociatedDealerCustomerNumberDropdownWrapper,
  AssociatedDealerDealerDropdownWrapper,
  AssociatedDealerEndUseCodeDropdownWrapper,
  AssociatedDealerOrderTypeWrapper,
  AssociatedDealerSkipThisStepCheckbox,
  AssociatedDealerStoreLocationDropdownWrapper
} from '../../AssociatedDealerFields';
import { AssociatedDealerTermsAndConditionsCheckbox } from '../../AssociatedDealerTermsAndConditions';
import useGetDealerCustomerStores from '../../hooks/useGetDealerCustomerStores';
import { useReplaceTokenWithElement } from '@app/hooks';
import { DEALER } from '@app/constants/affiliations';
import {
  SELECT_CUSTOMER_FORM_ID,
  CUSTOMER_NUMBER_DROPDOWN_NAME,
  DEALER_DROPDOWN_NAME,
  STORE_LOCATION_DROPDOWN_NAME,
  END_USE_CODE_DROPDOWN_NAME,
  ORDER_TYPE_RADIO_NAME,
  TERMS_AND_CONDITIONS_CHECKBOX_NAME
} from '../../constants';
import { STATUS } from '@app/constants/commonConstants';
import { StoreLocation } from '../../types';
import styles from '../../styles.module.scss';
import {
  CSR_SHARE_CART_LINK_STOREID_PARAM,
  CSR_SHARE_CART_LINK_STORE_LOCATION_ID_PARAM
} from '@app/components/pages/checkout/shopping-cart/OrderItems/constants';
import { getSessionStorageCartSharingData } from '@app/components/pages/checkout/shopping-cart/ShoppingCartPage/utils';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const AssociatedDealerSelectCustomerForm = ({
  formTitle,
  formMethods,
  onSubmit,
  isSelectDealerLoading,
  onClearSearch,
  handleClose,
  dealer,
  customerNumber,
  stores,
  termsAndConditionsModal,
  termsAndConditionsDrawer,
  setScrollToPrivacyNotice,
  setShowTermsAndConditions,
  openDealerLocator,
  showPricingWarning,
  showSkipThisStep = false,
  isModal = true
}) => {
  const id = SELECT_CUSTOMER_FORM_ID;
  const [t] = useTranslation();
  const { replaceTokenWithElement } = useReplaceTokenWithElement();
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const isDealerUser = userAffiliation === DEALER;
  const dealerAssociations = useSelector(
    s => s.dealerAssociation?.dealerAssociations
  );
  const searchParams = useLocation().search;
  const { storeId, fromAbandonedCart } = queryString.parse(searchParams);

  const dealerList = useMemo(() => {
    return dealerAssociations?.map(element => {
      if (Boolean(fromAbandonedCart)) {
        if (element.value === storeId) {
          return { ...element, default: true };
        } else {
          return { ...element, default: false };
        }
      } else {
        return { ...element };
      }
    });
  }, [dealerAssociations, storeId, fromAbandonedCart]);

  // * previous values
  const prevDealer = useSelector(s => s?.common?.selectedDealer);
  const prevCustomerNumber = useSelector(
    s => s?.common?.selectedCustomerNumber
  );
  const prevStore = useSelector(s => s?.common?.selectedStore);
  const prevEndUseCode = useSelector(s => s?.common?.selectedEndUseCode)?.value;
  const prevOrderType = useSelector(s => s?.common?.selectedOrderType)?.value;

  /**
   * * previous values state
   * whenever we call setPreviousValues, it should be to invalidate the
   * dependencies of each following dropdown, for example:
   * changing the customer dropdown should invalidate the previous values of
   * - store
   * - endUseCode
   * - orderType
   */
  const [previousValues, setPreviousValues] = useState({
    dealer: prevDealer,
    customerNumber: prevCustomerNumber,
    store: prevStore,
    endUseCode: prevEndUseCode,
    orderType: prevOrderType
  });

  const {
    data: storesData,
    status: storesDataStatus,
    invoke: invokeStoresCall,
    abort: abortStoresCall,
    reset: resetStoresData,
    error: errorStore
  } = useGetDealerCustomerStores();
  const storesAreLoading = storesDataStatus === STATUS.PENDING;

  const currentDealerValue = formMethods.watch(DEALER_DROPDOWN_NAME);
  const currentCustomerNumberValue = formMethods.watch(
    CUSTOMER_NUMBER_DROPDOWN_NAME
  );
  const currentStoreLocationValue = formMethods.watch(
    STORE_LOCATION_DROPDOWN_NAME
  );

  const currentOrderType = formMethods.watch(ORDER_TYPE_RADIO_NAME);
  const currentEndUseCode = formMethods.watch(END_USE_CODE_DROPDOWN_NAME);
  const currentTermsAndConditions = formMethods.watch(
    TERMS_AND_CONDITIONS_CHECKBOX_NAME
  );

  const [showWarningCartShare, setShowWarningCartShare] = useState(false);
  const dealerStoreId = dealerAssociations?.find(
    dealerItem => dealerItem?.value === storeId
  );
  const currentDealerLabel = dealerAssociations?.find(
    dealerItem => dealerItem?.label === currentDealerValue
  );
  const selectedDealer = Boolean(fromAbandonedCart)
    ? dealerStoreId
    : (dealer ?? currentDealerLabel);
  const isCSPCustomer = selectedDealer?.isCSPCustomer;
  const customerNumberList = selectedDealer?.customerNumber;
  const selectedCustomerNumber =
    customerNumber ??
    customerNumberList?.find(
      customerNumberObject =>
        customerNumberObject.label === currentCustomerNumberValue
    );
  const storeLocationList = stores ?? storesData?.store;
  const selectedStoreLocation = storeLocationList?.find(
    store => store.label === currentStoreLocationValue
  );
  const dealerDCNAssociations =
    useSelector(s => s.checkout?.dealerDCNAssociations) || [];
  const dcnLoadingStatus = useSelector(s => s.checkout?.dcnLoadingStatus);
  const isGetDealerDCNLoading = useSelector(
    s => s.checkout?.isGetDealerDCNLoading
  );
  const isCatCSR = useSelector(s => s.common?.isCatCSR) || false;
  const isFlagCustomerSupportTransactAsGuestEnabled =
    useSelector(
      state =>
        state?.featureFlag?.PCC_CustomerSupportTransactAsGuestFromCSRCartList
    ) || false;
  const isPCCCustomerSupportCSRBannerMessaging =
    useSelector(
      state => state?.featureFlag?.PCC_CustomerSupportCSRBannerMessaging
    ) || false;
  const endUseCodeList = selectedStoreLocation?.endUseCode;
  const orderTypeList = selectedStoreLocation?.orderType;
  const showWarning =
    !dealerDCNAssociations?.length &&
    isDealerUser &&
    !isGetDealerDCNLoading &&
    dcnLoadingStatus === STATUS.RESOLVED;
  const hideTermsAndConditions =
    isCatCSR &&
    (isFlagCustomerSupportTransactAsGuestEnabled ||
      isPCCCustomerSupportCSRBannerMessaging);

  //Todo something simpler
  //As we get all the values with find in this component we use handleSubmit with the data gattered to put the newData in the onSubmit
  const handleSubmit = () => {
    formMethods.handleSubmit(data => {
      const endUseCode = endUseCodeList?.find(endUseCode => {
        return endUseCode?.label === data[END_USE_CODE_DROPDOWN_NAME];
      });

      const orderType = orderTypeList?.find(orderType => {
        return orderType?.value === data[ORDER_TYPE_RADIO_NAME];
      });
      const newData = {
        ...data,
        dealer: selectedDealer,
        customerNumber: selectedCustomerNumber,
        storeLocation: selectedStoreLocation,
        endUseCode,
        orderType
      };
      onSubmit(newData);
    })();
  };

  const isDisabledContinueButton =
    errorStore ||
    !currentDealerValue ||
    !currentCustomerNumberValue ||
    storeLocationList?.length === 0 ||
    (storeLocationList?.length > 0 && !currentStoreLocationValue) ||
    (orderTypeList?.length > 1 && !currentOrderType) ||
    (endUseCodeList?.length > 0 && !currentEndUseCode) ||
    !currentTermsAndConditions ||
    storesAreLoading;

  const isShowingStoreError = storeLocationList?.length === 0 || errorStore;

  const prepopulateDealerSelectedInCartShare = () => {
    const CSR_SHARE_CART_DATA = getSessionStorageCartSharingData();
    if (
      dealerList &&
      dealerList.length > 0 &&
      CSR_SHARE_CART_DATA &&
      CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM] !==
        currentDealerValue
    ) {
      const findDealerFromShareCartLink = dealerList.find(
        dealerD =>
          dealerD.value ===
          CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM]
      );
      if (findDealerFromShareCartLink) {
        formMethods.setValue(
          DEALER_DROPDOWN_NAME,
          findDealerFromShareCartLink.label
        );
      }
    }
  };

  useEffect(() => {
    prepopulateDealerSelectedInCartShare();
  }, []);

  useEffect(() => {
    const CSR_SHARE_CART_DATA = getSessionStorageCartSharingData();
    if (
      CSR_SHARE_CART_DATA &&
      CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM] ===
        selectedDealer?.value &&
      storeLocationList?.length > 0
    ) {
      const findStoreLocationItem = storeLocationList.find(
        storeLocationItem =>
          storeLocationItem.value ===
          CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STORE_LOCATION_ID_PARAM]
      );
      if (findStoreLocationItem) {
        formMethods.setValue(
          STORE_LOCATION_DROPDOWN_NAME,
          findStoreLocationItem.label
        );
      }
    }
  }, [storeLocationList]);

  useEffect(() => {
    setShowWarningCartShare(false);
    const CSR_SHARE_CART_DATA = getSessionStorageCartSharingData();
    if (CSR_SHARE_CART_DATA) {
      const findDealerFromShareCartLink = dealerList.find(
        dealerD =>
          dealerD.value ===
          CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM]
      );
      if (
        findDealerFromShareCartLink &&
        currentDealerValue !== findDealerFromShareCartLink.label
      ) {
        setShowWarningCartShare(true);
      }
    }
  }, [currentDealerValue]);

  /**
   * This handles the request for getting the store locations
   * It triggers whenever user has selected a dealer and customer number
   */
  useEffect(() => {
    if (isDealerUser || !selectedDealer) {
      return;
    }

    // * Resets the current dealer customer data
    resetStoresData();

    // * If there's any pending calls, we abort them
    if (storesDataStatus === STATUS.PENDING) {
      abortStoresCall();
    }

    // * We call the dealer customer API to fetch the data
    if (
      currentDealerValue &&
      currentCustomerNumberValue &&
      selectedCustomerNumber
    ) {
      invokeStoresCall({
        dealer: selectedDealer?.value,
        customerNumber: selectedCustomerNumber?.value
      });
    }
  }, [currentCustomerNumberValue, selectedDealer]);

  useEffect(() => {
    if (hideTermsAndConditions) {
      formMethods.setValue(TERMS_AND_CONDITIONS_CHECKBOX_NAME, true);
    }
  }, [formMethods, hideTermsAndConditions]);

  const title = isDealerUser
    ? replaceTokenWithElement(t('GENERAL_BACK_TO'), 'm-0', formTitle)
    : t('CAT_MA_ASSOCIATEDDEALERS');

  const subtitle = t('CAT_MA_ASSOCIATEDDEALERS');

  const errorStoreLocationText = t('STORE_NOT_AVAILBALE_ERROR');

  return (
    <>
      {isDealerUser ? (
        <DrawerBackHeader handleClose={handleClose} isActive={true}>
          <h4 className="cat-u-theme-typography-title m-0">{title}</h4>
        </DrawerBackHeader>
      ) : (
        <DrawerHeader>
          <h4 className="cat-u-theme-typography-title m-0">{title}</h4>
        </DrawerHeader>
      )}
      <DrawerBody className={styles['drawer-body']}>
        <Conditional test={showWarningCartShare}>
          <CatInlineNotification
            variant="warning"
            heading={t('LOSS_CART_ITEMS_WARNING')}
            data-testid="manual-approval-message-share-cart"
            className={styles['warning-message__content']}
          >
            <CatIconWarningTriangle slot="before" data-testid="warning-icon" />
            {t('LOSS_CART_ITEMS_WARNING_VERBIAGE')}
            <div slot="after">
              <cat-button onClick={prepopulateDealerSelectedInCartShare}>
                {t('SHIP_GO_BACK')}
              </cat-button>
            </div>
          </CatInlineNotification>
        </Conditional>
        <Conditional test={showWarning}>
          <CatInlineNotification
            variant="warning"
            heading={t('DEALER_USER_NO_REGISTERED_DCN')}
            data-testid="manual-approval-message"
            className={styles['warning-message__content']}
          >
            <CatIconWarningTriangle slot="before" data-testid="warning-icon" />
            {t('DEALER_USER_ASSIST_DCN')}
          </CatInlineNotification>
        </Conditional>
        <Conditional test={isShowingStoreError}>
          <AssociatedDealerBanner variant={'error'}>
            {errorStoreLocationText}
          </AssociatedDealerBanner>
        </Conditional>
        <AssociatedDealerForm
          id={id}
          className={styles['drawer-form']}
          formMethods={formMethods}
          onSubmit={onSubmit}
        >
          <Conditional test={!isDealerUser && showPricingWarning}>
            <AssociatedDealerPriceWarning
              title={t('CAT_PRICE_MSG')}
              message={t('CAT_PROMO_MSG')}
            />
          </Conditional>
          <Conditional test={isDealerUser}>
            <h4 className="cat-u-theme-typography-title m-0">{subtitle}</h4>
          </Conditional>
          <AssociatedDealerDealerDropdownWrapper
            dealerList={dealerList}
            dealer={dealer}
            selectedValue={previousValues?.dealer}
            onSelect={() => {
              setPreviousValues(null);
            }}
          />
          <Conditional test={!isDealerUser}>
            <CatButton
              className={('mt-3', styles['cat-buttons'])}
              onClick={openDealerLocator}
            >
              <CatIconAdd slot="before" />
              {t('ADD_A_DEALER')}
            </CatButton>
          </Conditional>
          <AssociatedDealerCustomerNumberDropdownWrapper
            isCSPCustomer={isCSPCustomer}
            customerNumberList={customerNumberList}
            customerNumber={customerNumber}
            selectedValue={previousValues?.customerNumber}
            onSelect={() => {
              setPreviousValues({ dealer: previousValues.dealer });
            }}
          />
          <AssociatedDealerStoreLocationDropdownWrapper
            storeLocationList={storeLocationList}
            loading={storesAreLoading}
            selectedValue={previousValues?.store}
            onSelect={() => {
              setPreviousValues({
                dealer: previousValues.dealer,
                customerNumber: previousValues.customerNumber
              });
            }}
          />
          <AssociatedDealerOrderTypeWrapper
            selectedStoreLocation={selectedStoreLocation}
            selectedValue={previousValues?.orderType}
            onChange={() => {
              setPreviousValues({
                dealer: previousValues.dealer,
                customerNumber: previousValues.customerNumber,
                store: previousValues.store
              });
            }}
          />
          <AssociatedDealerEndUseCodeDropdownWrapper
            endUseCodeList={endUseCodeList}
            selectedValue={previousValues?.endUseCode}
            onSelect={() => {
              setPreviousValues({
                dealer: previousValues.dealer,
                customerNumber: previousValues.customerNumber,
                store: previousValues.store
              });
            }}
          />
          <AssociatedDealerSkipThisStepCheckbox
            showSkipThisStep={showSkipThisStep}
          />
        </AssociatedDealerForm>
      </DrawerBody>
      <DrawerFooter className={styles['drawer-footer']}>
        <Conditional test={!hideTermsAndConditions}>
          <FormProvider {...formMethods}>
            <AssociatedDealerTermsAndConditionsCheckbox
              termsAndConditionsDrawer={termsAndConditionsDrawer}
              isModal={isModal}
              termsAndConditionsModal={termsAndConditionsModal}
              setScrollToPrivacyNotice={setScrollToPrivacyNotice}
              setShowTermsAndConditions={setShowTermsAndConditions}
              dealer={dealer}
            />
          </FormProvider>
        </Conditional>
        <div className={styles['drawer-footer-buttons']}>
          <CatButton
            className={('mt-3', styles['cat-buttons'])}
            variant="primary"
            onClick={handleSubmit}
            disabled={isDisabledContinueButton || isSelectDealerLoading}
          >
            {t('EF_CONTINUE')}
            <Conditional test={isSelectDealerLoading}>
              <CatLoadingIndicator slot={'after'} small={true} />
            </Conditional>
          </CatButton>
          <Conditional test={isDealerUser}>
            <CatButton variant="link" onClick={onClearSearch}>
              {t('CLEAR_SEARCH')}
            </CatButton>
          </Conditional>
        </div>
      </DrawerFooter>
    </>
  );
};

AssociatedDealerSelectCustomerForm.propTypes = {
  formTitle: PropTypes.string,
  formMethods: PropTypes.any,
  onSubmit: PropTypes.func,
  isSelectDealerLoading: PropTypes.bool,
  onClearSearch: PropTypes.func,
  handleClose: PropTypes.func,
  showPricingWarning: PropTypes.bool,
  showSkipThisStep: PropTypes.bool,
  dealer: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  customerNumber: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  stores: PropTypes.arrayOf(StoreLocation),
  termsAndConditionsDrawer: PropTypes.any,
  setScrollToPrivacyNotice: PropTypes.func,
  openDealerLocator: PropTypes.func
};
