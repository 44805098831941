import { CatSkeleton } from '@blocks-react/components/Skeleton';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { availability as availabilityPropTypes } from '../Availability.proptypes';
import AvailabilityByDateContent from '../AvailabilityByDateContent';
import AvailabilityBySourceContent from '../AvailabilityBySourceContent';
import { sourcingDetailsPropTypes } from '../AvailabilityBySourceContent/AvailabilityBySourceContent.proptypes';
import TooltipDisclaimer from '../TooltipDisclaimer';
import { getAvailabilityPickupText, getAvailabilityTitle } from './util';
import styles from './ModernAvailability.module.scss';

const PrimaryAvailability = ({
  availabilityInformation,
  catEntryId,
  className,
  hideLabel,
  isLoading,
  quantity,
  sourcingDetailsInformation,
  availabilityTypeShowToggle,
  hoseAssemblyDetails,
  forceShowAvailabilityByDate = false,
  showAdditionalInfo = false
}) => {
  const [t] = useTranslation();

  const showContactDealerOnly = (availabilityInformation || []).some(
    ({ quantityAvailable, showContactDealerPopUp }) =>
      !quantityAvailable && showContactDealerPopUp ? true : false
  );

  const isNotVoidOrZero = value => !!value && value !== '0';

  const showAvailabilityBySource = useSelector(
    state =>
      !!state?.shoppingPreferences?.shoppingPreferencesData
        ?.showSourcingDetailToggle
  );
  const isDeliveryEnabled = useSelector(
    state => state.common.isDeliveryEnabled
  );
  const isPickUpEnabled = useSelector(state => state.dealer.isPickupEnabled);

  const isQuantityAvailable = () =>
    (availabilityInformation || []).some(({ quantityAvailable }) =>
      isNotVoidOrZero(quantityAvailable)
    );

  const getLabel = () =>
    !hideLabel && (
      <div className="d-flex align-items-center justify-content-start mb-2">
        <span className="text-sm text-uppercase text-univers--bold u-color--ExtraDarkGray m-0">
          {getAvailabilityTitle(isDeliveryEnabled, isPickUpEnabled, t)}
        </span>
        <TooltipDisclaimer />
      </div>
    );
  const getDisplayDetails = showBySource => {
    if (hoseAssemblyDetails.hoseAssemblyIndicator) {
      const hoseLabel = hoseAssemblyDetails.hoseAvailabilityLabel?.trim();
      const hoseDetails = hoseLabel || t('HOSE_DEFAULT_MESSAGE');
      return (
        <span
          className={`${styles['hose-availability-text']} ${styles['print__availability-text']}`}
        >
          {hoseDetails}
        </span>
      );
    }

    if (showBySource && !forceShowAvailabilityByDate) {
      return (
        <AvailabilityBySourceContent
          sourcingDetailsInformation={sourcingDetailsInformation}
          catEntryId={catEntryId}
        />
      );
    } else {
      return (
        <AvailabilityByDateContent
          availabilityInformation={availabilityInformation}
          catEntryId={catEntryId}
          quantity={quantity}
        />
      );
    }
  };

  const getAvailabilityTable = () =>
    (isQuantityAvailable() ||
      showContactDealerOnly ||
      hoseAssemblyDetails.hoseAssemblyIndicator) && (
      <>
        {getLabel()}
        {showAvailabilityBySource &&
          getDisplayDetails(!availabilityTypeShowToggle)}

        {!showAvailabilityBySource &&
          getDisplayDetails(availabilityTypeShowToggle)}
        {showAdditionalInfo &&
          getAvailabilityPickupText(
            isDeliveryEnabled,
            isPickUpEnabled,
            t,
            'ps-2'
          )}
      </>
    );

  return (
    <div className={className}>
      {isLoading ? (
        <>
          {getLabel()}
          <CatSkeleton size="sm" />
        </>
      ) : (
        getAvailabilityTable()
      )}
    </div>
  );
};

PrimaryAvailability.propTypes = {
  className: PropTypes.string,
  catEntryId: PropTypes.string,
  hideLabel: PropTypes.bool,
  isLoading: PropTypes.bool,
  showAdditionalInfo: PropTypes.bool,
  availabilityInformation: availabilityPropTypes,
  quantity: PropTypes.number,
  sourcingDetailsInformation: sourcingDetailsPropTypes,
  availabilityTypeShowToggle: PropTypes.bool,
  hoseAssemblyDetails: PropTypes.shape({
    hoseAssemblyIndicator: PropTypes.bool,
    hoseAvailabilityLabel: PropTypes.string
  }),
  forceShowAvailabilityByDate: PropTypes.bool
};

export default PrimaryAvailability;
