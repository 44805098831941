import PropTypes from 'prop-types';
import { CatHeading } from '@blocks-react/components/Heading';
import {
  MyEquipmentHeader,
  MyEquipmentLinkContent,
  MyEquipmentDrawerLinkImage,
  MyEquipmentDrawer
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import {
  ASSET_PLATE_BEFORE,
  ASSET_PLATE_BEFORE_MODEL,
  ENGINES_AND_GENERATORS,
  ENGINES_AND_GENERATORS_MODEL,
  NEWER_ASSETS,
  NEWER_ASSETS_MODEL,
  WHERE_TO_FIND_SERIAL
} from '../../constant';
import styles from './AddEquipmentFindSerialDrawer.module.scss';

const AddEquipmentFindSerialDrawer = ({
  drawerSerialLink,
  showBackButton,
  isChild = false
}) => {
  const [t] = useTranslation();
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const onCloseFindSerial = e => {
    e.stopPropagation();
    drawerSerialLink.drawerProps.onBlDrawerClose(e);
  };
  const onBackButtonClickOnSerial = () => {
    drawerSerialLink.handleClose();
  };
  return (
    <MyEquipmentDrawer
      drawer={drawerSerialLink}
      size="lg"
      onCloseHandler={onCloseFindSerial}
      isChild={isChild}
    >
      <MyEquipmentHeader
        className="d-flex"
        variant={'title-sm'}
        onBackButtonClick={!showBackButton ? onBackButtonClickOnSerial : ''}
      >
        {t('FIND_SERIAL_GUIDE_TEXT')}
      </MyEquipmentHeader>

      <MyEquipmentLinkContent classname={styles['grid']}>
        <MyEquipmentHeader
          className="pb-3 pb-md-0"
          variant={isMD ? 'title' : 'headline'}
        >
          {t('FMS_SLIDE1_TITLE')}
        </MyEquipmentHeader>
        <MyEquipmentDrawerLinkImage
          src={NEWER_ASSETS.src}
          alt={NEWER_ASSETS.alt}
          classname={styles['image']}
        />
        <MyEquipmentHeader className="py-3" variant="body-sm">
          {t('FMS_SLIDE1_BODY')}
        </MyEquipmentHeader>
        <MyEquipmentDrawerLinkImage
          src={NEWER_ASSETS_MODEL.src}
          alt={NEWER_ASSETS_MODEL.alt}
        />
      </MyEquipmentLinkContent>

      <MyEquipmentLinkContent>
        <MyEquipmentHeader className="pt-3 pb-md-3 pb-1" variant="label">
          {t('FMS_SLIDE1_BODY2')}
        </MyEquipmentHeader>
      </MyEquipmentLinkContent>
      <hr />
      <MyEquipmentLinkContent classname={styles['grid']}>
        <MyEquipmentHeader
          className="pb-3 pt-1 p-md-0 "
          variant={isMD ? 'title' : 'headline'}
        >
          {t('FMS_SLIDE2_TITLE')}
        </MyEquipmentHeader>
        <MyEquipmentDrawerLinkImage
          src={ASSET_PLATE_BEFORE.src}
          classname={styles['image']}
          alt={ASSET_PLATE_BEFORE.alt}
        />
        <MyEquipmentDrawerLinkImage
          classname="d-block pt-3 pt-md-0"
          src={ASSET_PLATE_BEFORE_MODEL.src}
          alt={ASSET_PLATE_BEFORE_MODEL.alt}
        />
      </MyEquipmentLinkContent>
      <MyEquipmentLinkContent>
        <MyEquipmentHeader className="pb-3 d-inline-block" variant="body-sm">
          {t('FMS_SLIDE2_BODY')}
        </MyEquipmentHeader>
        <MyEquipmentHeader className="pb-3" variant="body-sm">
          {t('FMS_SLIDE2_BODY2')}
        </MyEquipmentHeader>
        <MyEquipmentHeader className="pb-3" variant="body-sm">
          <CatHeading variant="label" className="d-inline-block">
            {t('PREFIX')}
          </CatHeading>
          {t('FMS_SLIDE2_BODY_PREFIX')}
        </MyEquipmentHeader>
        <MyEquipmentHeader className="pb-0 pb-md-3" variant="body-sm">
          <CatHeading variant="label" className="d-inline-block">
            {t('SEQUENCE_ID')}
          </CatHeading>
          {t('FMS_SLIDE2_BODY_SEQID')}
        </MyEquipmentHeader>
      </MyEquipmentLinkContent>

      <hr />
      <MyEquipmentLinkContent classname={styles['grid']}>
        <MyEquipmentHeader
          className="pb-3 pt-1 -md-0"
          variant={isMD ? 'title' : 'headline'}
        >
          {t('FMS_SLIDE3_TITLE')}
        </MyEquipmentHeader>
        <MyEquipmentDrawerLinkImage
          src={ENGINES_AND_GENERATORS.src}
          classname={styles['image']}
          alt={ENGINES_AND_GENERATORS.alt}
        />
        <MyEquipmentHeader className="py-3" variant="body-sm">
          {t('FMS_SLIDE3_BODY')}
        </MyEquipmentHeader>
        <MyEquipmentDrawerLinkImage
          src={ENGINES_AND_GENERATORS_MODEL.src}
          alt={ENGINES_AND_GENERATORS_MODEL.alt}
        />
      </MyEquipmentLinkContent>
      <hr />
      <MyEquipmentLinkContent classname={styles['grid']}>
        <MyEquipmentHeader
          className="pb-3 pt-1 p-md-0"
          variant={isMD ? 'title' : 'headline'}
        >
          {t('FMS_SLIDE4_TITLE')}
        </MyEquipmentHeader>
        <MyEquipmentDrawerLinkImage
          classname={styles['image']}
          src={WHERE_TO_FIND_SERIAL.src}
          alt={WHERE_TO_FIND_SERIAL.alt}
        />
        <MyEquipmentHeader className="py-3" variant="body-sm">
          {t('FMS_SLIDE4_BODY')}
        </MyEquipmentHeader>
      </MyEquipmentLinkContent>
    </MyEquipmentDrawer>
  );
};

AddEquipmentFindSerialDrawer.propTypes = {
  drawerSerialLink: PropTypes.shape({
    handleClose: PropTypes.func,
    drawerProps: PropTypes.shape({
      onBlDrawerClose: PropTypes.func
    })
  }),
  showBackButton: PropTypes.bool,
  isChild: PropTypes.bool
};

export default AddEquipmentFindSerialDrawer;
