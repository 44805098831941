import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CatIconWarningCircle } from '@blocks-react/components/Icons/WarningCircle';
import { CatIconSuccess } from '@blocks-react/components/Icons/Success';
import { CatInlineNotification } from '@blocks-react/components/InlineNotification';

const InlineNotification = ({
  title = '',
  alternative = 'error',
  customClasses = '',
  closable = false,
  close = () => {},
  children
}) => {
  if (!children && !title) {
    return null;
  }

  return (
    <section className={customClasses}>
      <CatInlineNotification
        heading={title}
        variant={alternative}
        dismissible={closable}
        close={close}
        data-testid="errorWarning"
      >
        <Fragment key=".0">
          {alternative === 'error' && <CatIconWarningCircle slot="before" />}
          {alternative === 'success' && <CatIconSuccess slot="before" />}
          {children}
        </Fragment>
      </CatInlineNotification>
    </section>
  );
};

InlineNotification.propTypes = {
  title: PropTypes.string,
  alternative: PropTypes.string,
  customClasses: PropTypes.string,
  closable: PropTypes.bool,
  children: PropTypes.node,
  close: PropTypes.func
};

export default InlineNotification;
