import { replaceTokensInUrl } from '@app/utils';
import { getHttpInstance } from './defaultHttpService';
import endpoints from '@app/constants/endpoints';

//Duplicate  saved cart list by copying and deleting existing list
export const duplicateSavedList = async (
  listId,
  state,
  dispatchError,
  itemId = null,
  partNumber = null
) => {
  const isCATCSR = state.common?.isCatCSR || false;
  let isCustomerSupportSharedCartListsEnabled =
    state.featureFlag?.PCC_CustomerSupportSharedCartLists || false;

  const currentUserId = Number(state.common?.userId);
  const currentOrderCreateById = Number(
    state.savedListDetailUI?.orderCreatedBy
  );
  const isOwner = currentUserId === currentOrderCreateById;

  //check whether the list is self created list
  //if self created duplicate and return the new listId or return the old one
  if (isCustomerSupportSharedCartListsEnabled && isCATCSR && !isOwner) {
    try {
      const http = getHttpInstance();
      const {
        common: { storeId, locale }
      } = state;
      const cloneAndDeleteURL = replaceTokensInUrl(
        endpoints.SAVED_LIST_CSR_UPDATE,
        listId,
        storeId,
        locale
      );
      // Call BFF endpoint to Copy & Delete
      const { data: cloneResponse } = await http.post(cloneAndDeleteURL, null, {
        withCredentials: true
      });
      const newListId = cloneResponse?.shoppingList.shoppingListId;
      const orderItemId = partNumber
        ? cloneResponse?.shoppingList?.orderItems?.find(
            item => item?.partNumber === partNumber
          )?.orderItemId
        : null;

      return {
        newListId,
        orderItemId
      };
    } catch (error) {
      dispatchError(error);
    }
  } else {
    return { newListId: listId, orderItemId: itemId };
  }
};
