import { useSelector } from 'react-redux';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  HeaderCartList,
  useModal
} from '@cat-ecom/pcc-components';
import { CatHeading } from '@blocks-react/components/Heading';
import { useTranslation } from 'react-i18next';
import { getCurrentOrderItemCount } from '../utils';
import EmptyCart from '../EmptyCart/EmptyCart';
import { STATUS } from '@app/constants/commonConstants';
import { CatButton } from '@blocks-react/components/Button';
import { normalizeUrl } from '@app/utils';
import useHeaderAnalytics from '../../hooks/useHeaderAnalytics';
import PropTypes from 'prop-types';
import { DealerLocatorModalWrapper } from '@app/components/dealer-locator-react';
import { CreateAccountDescription } from '@app/components/dealer-locator-react/CreateAccountDescription/CreateAccountDescription';
import Conditional from '@app/components/common/Conditional';
import useShoppingCartFeatureFlag from '@app/hooks/useShoppingCartFeatureFlag';

const CartDrawer = ({ closeDrawer, ...drawerProps }) => {
  const currentOrderId = useSelector(s => s?.orders?.current);
  const currentOrderItems =
    useSelector(s => s?.orders?.byId[currentOrderId]?.ascendingOrderItems) ||
    [];
  const orderStatus = useSelector(s => s?.orders?.getCurrentOrderStatus);
  const { productClickEvent } = useHeaderAnalytics();
  const { t } = useTranslation();
  const numItems = getCurrentOrderItemCount(currentOrderItems);
  const isCartEmpty = currentOrderItems.length === 0;
  const isLoading =
    orderStatus === STATUS.IDLE || orderStatus === STATUS.PENDING;

  const shoppingCartUrl = useShoppingCartFeatureFlag();

  const modal = useModal({
    isRequired: true,
    heading: `${t('STEP_1')}: ${t('REGISTER_MODAL_SELECT_DEALER_STORE')}`,
    backButton: false,
    size: 'md'
  });

  return (
    <>
      <Drawer {...drawerProps}>
        <DrawerHeader>
          <CatHeading variant="title">
            {t('SC_ITEMS_IN_YOUR_CART')} ({numItems})
          </CatHeading>
        </DrawerHeader>
        <DrawerBody>
          {isCartEmpty ? (
            <EmptyCart
              closeDrawer={closeDrawer}
              openDealerLocator={modal.initiatorProps.onClick}
            />
          ) : (
            <HeaderCartList
              items={[...currentOrderItems].reverse()}
              isLoading={isLoading}
              quantityLabel={t('QUANTITY')}
              onItemClick={productClickEvent}
            />
          )}
        </DrawerBody>
        <Conditional test={!isCartEmpty}>
          <DrawerFooter>
            <CatButton
              fullWidth
              variant="primary"
              href={normalizeUrl(shoppingCartUrl)}
            >
              {t('MSC_GO_TO_CART')}
            </CatButton>
          </DrawerFooter>
        </Conditional>
      </Drawer>
      <DealerLocatorModalWrapper isRegistration modal={modal}>
        <CreateAccountDescription />
      </DealerLocatorModalWrapper>
    </>
  );
};

export default CartDrawer;

CartDrawer.propTypes = {
  closeDrawer: PropTypes.func
};
