import {
  CHECKOUT_APPLY_PROMO_SUCCESS,
  CHECKOUT_CALC_FREIGHT_BEGIN,
  CHECKOUT_CALC_FREIGHT_FAIL,
  CHECKOUT_CALC_FREIGHT_SUCCESS,
  CHECKOUT_CALC_FREIGHT_RESET,
  CHECKOUT_LOAD_PICKUP_DELIVERY_BEGIN,
  CHECKOUT_LOAD_PICKUP_DELIVERY_FAIL,
  CHECKOUT_LOAD_PICKUP_DELIVERY_SUCCESS,
  CHECKOUT_LOAD_REQUIRED_FIELDS_BEGIN,
  CHECKOUT_LOAD_REQUIRED_FIELDS_FAIL,
  CHECKOUT_LOAD_REQUIRED_FIELDS_SUCCESS,
  CHECKOUT_LOAD_SUMMARY_BEGIN,
  CHECKOUT_LOAD_SUMMARY_FAIL,
  CHECKOUT_LOAD_SUMMARY_SUCCESS,
  CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_CANCEL,
  CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_FAIL,
  CHECKOUT_MIDTRANS_GET_TOKEN_BEGIN,
  CHECKOUT_MIDTRANS_GET_TOKEN_FAIL,
  CHECKOUT_MIDTRANS_GET_TOKEN_SUCCESS,
  CHECKOUT_REMOVE_PROMO_SUCCESS,
  CHECKOUT_RESET,
  CHECKOUT_SAVE_ADDRESS_BEGIN,
  CHECKOUT_SAVE_ADDRESS_SUCCESS,
  CHECKOUT_SAVE_ADDRESS_FAIL,
  CHECKOUT_SAVE_PAYMENT_DETAILS_SUCCESS,
  CHECKOUT_SAVE_PONUMBER_SUCCESS,
  CHECKOUT_VERIFY_ADDRESS_BEGIN,
  CHECKOUT_VERIFY_ADDRESS_FAIL,
  CHECKOUT_VERIFY_ADDRESS_SUCCESS,
  CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_BEGIN,
  CHECKOUT_LOAD_CART_SUCCESS,
  CHECKOUT_LOAD_CART_FAIL,
  CHECKOUT_LOAD_CART_BEGIN,
  CHECKOUT_SUMMARY_SET_BILLING_ADDRESS,
  CHECKOUT_SUMMARY_SET_BILLING_METHOD,
  CHECKOUT_SUMMARY_SET_ALTERNATIVE_PAYMENT,
  GET_DEALER_DCN_CUSTOMER_SUCCESS,
  GET_DEALER_DCN_CUSTOMER_BEGIN,
  GET_DEALER_DCN_CUSTOMER_FAIL,
  CHECKOUT_PLACE_ORDER_BEGIN,
  CHECKOUT_PLACE_ORDER_FAIL,
  CHECKOUT_PLACE_ORDER_END,
  CHECKOUT_SET_ORDER_INFORMATION_SUCCESS,
  CHECKOUT_SET_ORDER_INFORMATION_BEGIN,
  CHECKOUT_SET_ORDER_INFORMATION_FAIL,
  CHECKOUT_SET_ORDER_INFORMATION_IDLE,
  CHECKOUT_CART_FORM_SUBMIT_BEGIN,
  CHECKOUT_CART_FORM_SUBMIT_SUCCESS,
  CHECKOUT_SIS_TO_CART_FAIL,
  CHECKOUT_CART_FORM_SUBMIT_FAIL,
  CHECKOUT_CART_FORM_SUBMIT_IDLE,
  GET_SOS_DETAILS_SUCCESS,
  GET_SOS_DETAILS_BEGIN,
  GET_SOS_DETAILS_FAIL,
  ADD_TO_CART_MODIFICATIONS_RESET,
  GET_DEALER_PRICE_AND_AVAILABILITY_SUCCESS,
  GET_DEALER_PRICE_AND_AVAILABILITY_BEGIN,
  GET_DEALER_PRICE_AND_AVAILABILITY_FAIL,
  GET_DEALER_PRICE_AND_AVAILABILITY_RESET,
  ADD_QO_TO_CART_BEGIN,
  ADD_QO_TO_CART_SUCCESS,
  ADD_QO_TO_CART_FAIL,
  ADD_TO_CART_SET_PHASE,
  SET_IS_ADD_TO_CART_AND_CHECKOUT_FLOW,
  CHECKOUT_SUMMARY_SET_ADDITIONAL_BILLING_METHOD,
  CHECKOUT_APPLY_PREPAID_CERTIFICATE_BEGIN,
  CHECKOUT_APPLY_PREPAID_CERTIFICATE_SUCCESS,
  CHECKOUT_APPLY_PREPAID_CERTIFICATE_FAIL,
  CHECKOUT_DELETE_PREPAID_CERTIFICATE_BEGIN,
  CHECKOUT_DELETE_PREPAID_CERTIFICATE_SUCCESS,
  CHECKOUT_ADD_TO_CART_BEGIN,
  CHECKOUT_ADD_TO_CART_FAIL,
  CHECKOUT_ADD_TO_CART_SUCCESS,
  ADD_TO_CART_RESET_PHASE,
  UPDATE_PARTS,
  CHECKOUT_CAT_CREDIT_TOOLTIP,
  PICKUP_DELIVERY_SAVE_ADDRESS_BEGIN,
  PICKUP_DELIVERY_SAVE_ADDRESS_SUCCESS,
  PICKUP_DELIVERY_SAVE_ADDRESS_FAILS,
  PICKUP_DELIVERY_SUBMIT_BEGIN,
  PICKUP_DELIVERY_SUBMIT_SUCCESS,
  PICKUP_DELIVERY_SUBMIT_FAILS,
  PICKUP_DELIVERY_EXPORT_COMPLIANCE_SUCCESS,
  PICKUP_DELIVERY_EXPORT_RESET,
  PICKUP_DELIVERY_EXPORT_COMPLIANCE_FAILS,
  PICKUP_DELIVERY_REQUEST_BY_DATE_FAILS,
  QUICK_ORDER_CHECKOUT,
  PIX_PAYMENT_STATUS_BEGIN,
  PIX_PAYMENT_STATUS_SUCCESS,
  PIX_PAYMENT_STATUS_FAIL,
  ADD_TO_CART_RESET_PHASE_AND_FLAGS,
  RESET_CURRENT_MODAL_PHASE,
  CHECKOUT_GET_CAT_CARD_PROMOS_BEGIN,
  CHECKOUT_GET_CAT_CARD_PROMOS_SUCCESS,
  CHECKOUT_GET_CAT_CARD_PROMOS_FAIL,
  CHECKOUT_CLEAR_PROMOTIONS,
  CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_SUCCESS,
  CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_BEGIN,
  CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_FAIL,
  CHECKOUT_SELECT_CAT_CARD_PROMOTION,
  SET_CURRENT_MODAL_PHASE,
  BUILD_SHARE_CART_LINK_BEGIN,
  BUILD_SHARE_CART_LINK_SUCCESS,
  BUILD_SHARE_CART_LINK_FAIL,
  CHECKOUT_CALC_FREIGHT_CANCEL,
  CHECK_SALESFORCE_ACCOUNT_BALANCE_BEGIN,
  CHECK_SALESFORCE_ACCOUNT_BALANCE_FAIL,
  CHECK_SALESFORCE_ACCOUNT_BALANCE_SUCCESS,
  CHECK_SALESFORCE_ACCOUNT_BALANCE_CLEAR,
  CAT_CREDITS_DELETE_ACCOUNT_BEGIN,
  CAT_CREDITS_DELETE_ACCOUNT_FAIL,
  CAT_CREDITS_DELETE_ACCOUNT_SUCCESS,
  CHECKOUT_APPLY_CAT_CREDITS_BEGIN,
  CHECKOUT_APPLY_CAT_CREDITS_SUCCESS,
  CHECKOUT_APPLY_CAT_CREDITS_FAIL,
  CHECKOUT_APPLY_CAT_CREDITS_PLACE_ORDER,
  SAVE_CAT_CREDITS_ACCOUNT_GLOBAL_EXPANSION_CHECKBOX,
  SHARE_CONFIRMATION_EMAIL_BEGIN,
  SHARE_CONFIRMATION_EMAIL_SUCCESS,
  SHARE_CONFIRMATION_EMAIL_FAIL,
  SHARE_CONFIRMATION_EMAIL_IDLE,
  APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_BEGIN,
  APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_SUCCESS,
  APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_FAIL,
  RESET_ADDITIONAL_BILLING_METHOD
} from './constants';
import {
  SHOP_ONLINE,
  DROPBOX,
  PICKUP
} from '../../components/pages/checkout/PickUpAndDelivery/constants';
import {
  STATUS,
  PIX_PAYMENT_STATUS_TYPES,
  CAT_CREDIT,
  BILLING_METHOD_CAT_PREPAID_CERT
} from '@app/constants/commonConstants';
import { checkoutInitialStore } from './initialStore';
import { replaceTokensInString } from '@app/utils';

const checkoutReducer = (state = checkoutInitialStore, action) => {
  switch (action.type) {
    case CHECKOUT_APPLY_PROMO_SUCCESS:
      return {
        ...state,
        orderHeaderInformation: {
          ...(state.orderHeaderInformation || {}),
          appliedPromotionCode: action.payload.promoCode
        },
        orderTotals: { ...action.payload.orderTotals },
        applyCatCreditsGlobal: {
          ...state.applyCatCreditsGlobal,
          applyCatCreditsGlobalBalance: {
            amountUsedCatCreditsGlobal:
              action.payload.orderTotals.amountUsedCatCreditsGlobal,
            usedAmountForeignCurrency:
              action.payload.orderTotals.usedAmountForeignCurrency,
            remainingCatCreditsGlobal:
              action.payload.orderTotals.remainingCatCreditsGlobal,
            remainingAmountForeignCurrency:
              action.payload.orderTotals.remainingAmountForeignCurrency
          }
        }
      };
    case CHECKOUT_APPLY_PREPAID_CERTIFICATE_BEGIN:
      return {
        ...state,
        prepaidCertificates: {
          certificates: state.prepaidCertificates.certificates || [],
          catCreditStatus: STATUS.PENDING
        }
      };
    case CHECKOUT_APPLY_PREPAID_CERTIFICATE_SUCCESS:
      const {
        cards,
        orderTotals,
        catCreditsLimitAmount,
        catCreditsLimitMet,
        catCreditsRemainingAmount
      } = action.payload;
      const catCreditsLength = cards.length;
      //display name is just a placeholder
      const selectedAdditionalBillingMethod =
        catCreditsLength !== 0
          ? state.selectedAdditionalBillingMethod
          : {
              displayName: 'Select billing method',
              value: '',
              default: 'true'
            };

      return {
        ...state,
        selectedAdditionalBillingMethod,
        prepaidCertificates: {
          ...state.prepaidCertificates,
          catCreditStatus: STATUS.RESOLVED,
          certificates: [...cards]
        },
        catCreditTotals: orderTotals,
        catCreditsLimitAmount,
        catCreditsLimitMet,
        catCreditsRemainingAmount
      };
    case CHECKOUT_APPLY_PREPAID_CERTIFICATE_FAIL:
      return {
        ...state,
        prepaidCertificates: {
          ...state.prepaidCertificates,
          certificates: state.prepaidCertificates.certificates || [],
          catCreditStatus: STATUS.REJECTED
        }
      };
    case CHECKOUT_DELETE_PREPAID_CERTIFICATE_BEGIN:
      return {
        ...state,
        prepaidCertificates: {
          ...state.prepaidCertificates,
          catCreditStatus: STATUS.PENDING
        }
      };
    case CHECKOUT_DELETE_PREPAID_CERTIFICATE_SUCCESS:
      return {
        ...state,
        prepaidCertificates: {
          ...state.prepaidCertificates,
          catCreditStatus: STATUS.RESOLVED
        }
      };
    case CHECKOUT_CAT_CREDIT_TOOLTIP: {
      const showToolTipStatus = action.isPrepaidCatCredit
        ? STATUS.SHOW
        : STATUS.HIDE;
      return {
        ...state,
        prepaidCertificates: {
          ...state.prepaidCertificates,
          catCreditStatus: STATUS.RESOLVED,
          showToolTipStatus: showToolTipStatus
        }
      };
    }

    case CHECKOUT_REMOVE_PROMO_SUCCESS:
      return {
        ...state,
        orderHeaderInformation: {
          ...(state.orderHeaderInformation || {}),
          appliedPromotionCode: ''
        },
        orderTotals: { ...action.payload.orderTotals },
        applyCatCreditsGlobal: {
          ...state.applyCatCreditsGlobal,
          applyCatCreditsGlobalBalance: {
            amountUsedCatCreditsGlobal:
              action.payload.orderTotals.amountUsedCatCreditsGlobal,
            usedAmountForeignCurrency:
              action.payload.orderTotals.usedAmountForeignCurrency,
            remainingCatCreditsGlobal:
              action.payload.orderTotals.remainingCatCreditsGlobal,
            remainingAmountForeignCurrency:
              action.payload.orderTotals.remainingAmountForeignCurrency
          }
        }
      };
    case CHECKOUT_LOAD_PICKUP_DELIVERY_BEGIN:
      return {
        ...state,
        isPUAndDLoading: !action.payload.isCallingFromCounter,
        isPUAndDLoadSuccessful: action.payload.isCallingFromCounter
      };
    case CHECKOUT_LOAD_PICKUP_DELIVERY_SUCCESS:
      return {
        ...state,
        [DROPBOX]: {
          ...(action.payload[DROPBOX] || state[DROPBOX] || {})
        },
        [PICKUP]: {
          ...(action.payload[PICKUP] || state[PICKUP] || {})
        },
        [SHOP_ONLINE]: {
          ...(action.payload[SHOP_ONLINE] || state[SHOP_ONLINE] || {})
        },

        isPUAndDLoading: false,
        isPUAndDLoadSuccessful: true,
        isDropboxServiceFail: action.payload.isDropboxServiceFail,
        isPickupServiceFail: action.payload.isPickupServiceFail,
        isDeliveryServiceFail: action.payload.isDeliveryServiceFail,
        isCallingFromCounter: action.payload.isCallingFromCounter
      };
    case CHECKOUT_LOAD_PICKUP_DELIVERY_FAIL:
      return {
        ...state,
        isPUAndDLoading: false,
        isPUAndDLoadSuccessful: false
      };
    case CHECKOUT_CALC_FREIGHT_BEGIN:
      return {
        ...state,
        isCheckFreightLoading: true
      };
    case CHECKOUT_CALC_FREIGHT_CANCEL:
      return {
        ...state,
        isCheckFreightLoading: false
      };
    case CHECKOUT_CALC_FREIGHT_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        isCheckFreightLoading: false,
        isCheckFreightCallSuccessful: true,
        isCallingFromCounter: action.payload.isCallingFromCounter
      };
    case CHECKOUT_CALC_FREIGHT_FAIL:
      const error = action.error?.response?.data?.errors?.[0];
      return {
        ...state,
        ...action.payload,
        isCheckFreightLoading: false,
        isCheckFreightCallFailed: true,
        taxAndFreightErrorDesc: error?.errorParameters?.[0] ?? ''
      };
    case CHECKOUT_CALC_FREIGHT_RESET:
      return {
        ...state,
        taxAndFreight: [],
        isCheckFreightLoading: false,
        isCheckFreightCallSuccessful: false,
        isCheckFreightCallFailed: false,
        taxAndFreightErrorDesc: ''
      };
    case CHECKOUT_LOAD_REQUIRED_FIELDS_BEGIN:
      return {
        ...state,
        isRequiredFieldsLoading: true,
        isRequiredFieldsLoadSuccessful: false,
        requiredFields: checkoutInitialStore.requiredFields
      };
    case CHECKOUT_LOAD_REQUIRED_FIELDS_SUCCESS:
      return {
        ...state,
        requiredFields: action.payload,
        isRequiredFieldsLoading: false,
        isRequiredFieldsLoadSuccessful: true
      };
    case CHECKOUT_LOAD_REQUIRED_FIELDS_FAIL:
      return {
        ...state,
        isRequiredFieldsLoading: false,
        isRequiredFieldsLoadSuccessful: false
      };

    case CHECKOUT_LOAD_SUMMARY_BEGIN:
      return { ...state, isSAndPLoading: true };
    case CHECKOUT_LOAD_SUMMARY_FAIL:
      return {
        ...state,
        isSAndPLoading: false,
        isSAndPLoadSuccessful: false
      };

    case CHECKOUT_LOAD_SUMMARY_SUCCESS:
      return {
        ...state,
        billingMethodOptions: [
          ...action.payload.supportedPaymentMethods.map(item => ({
            ...item,
            displayName: item.value.includes(BILLING_METHOD_CAT_PREPAID_CERT)
              ? replaceTokensInString(item.displayName, CAT_CREDIT)
              : item.displayName
          }))
        ],
        banks: action.payload.banks ? [...action.payload.banks] : [],
        addresses: {
          options: [...action.payload.billingAddress] // This payload gives more than just billingAddresses
        },
        isSAndPLoading: false,
        isSAndPLoadSuccessful: true,
        micoEnabled: action.payload.micoEnabled,
        paymentGateway: action.payload.paymentGateway,
        paymentGatewayType: action.payload.paymentGatewayType,
        paymentTokenizationEnabled:
          action.payload.paymentTokenizationEnabled === true ||
          action.payload.paymentTokenizationEnabled === 'true' ||
          action.payload.paymentTokenizationEnabled === 'TRUE',
        quoteEnabled: action.payload.quoteEnabled,
        userType: action.payload.userType,
        selectedBillingMethod: action.payload.selectedBillingMethod,
        selectedAdditionalBillingMethod:
          action.payload.selectedAdditionalBillingMethod,
        skipCreditCard: action.payload.skipCreditCard,
        zeroFreightMessageEnabled: action.payload.zeroFreightMessageEnabled,
        vatTaxEnabled: action.payload.vatTaxEnabled,
        isInstallmentEnable: action.payload.isInstallmentEnable,
        identityNumber: action.payload.identityNumber,
        isChilePayU: action.payload.isChilePayU,
        isArgentinaPayU: action.payload.isArgentinaPayU,
        countryPayU: action.payload.countryPayU,
        paymentOSTokenFields: action.payload.paymentOSTokenFields,
        paymentOSPlaceOrderFields: action.payload.paymentOSPlaceOrderFields,
        dropboxLabel: action.payload.dropboxLabel
      };

    case RESET_ADDITIONAL_BILLING_METHOD:
      return {
        ...state,
        selectedAdditionalBillingMethod: {
          displayName: 'Select billing method',
          value: '',
          isDefault: 'true'
        }
      };

    case CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_BEGIN:
      return {
        ...state,
        paymentGateway: {
          ...state.paymentGateway,
          isProcessing: true
        }
      };

    case CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_CANCEL:
    case CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_FAIL:
      return {
        ...state,
        paymentGateway: {
          ...state.paymentGateway,
          isProcessing: false
        }
      };

    case CHECKOUT_MIDTRANS_GET_TOKEN_BEGIN:
    case CHECKOUT_MIDTRANS_GET_TOKEN_FAIL:
      return {
        ...state,
        paymentGateway: {
          ...state.paymentGateway,
          token: null
        }
      };
    case CHECKOUT_MIDTRANS_GET_TOKEN_SUCCESS:
      return {
        ...state,
        paymentGateway: {
          ...state.paymentGateway,
          token: action.payload.token
        }
      };
    case CHECKOUT_SAVE_ADDRESS_BEGIN:
      return { ...state, aviLoading: true };
    case CHECKOUT_SAVE_ADDRESS_FAIL:
      return {
        ...state,
        aviLoading: false
      };
    case CHECKOUT_SAVE_ADDRESS_SUCCESS: {
      const { enabled, required, ...newAddressDetails } = action.payload;

      // if this was an edit, remove the old one from addresses.options
      const { nickName: oldNickName } = state.addresses.new;
      const oldAddressOptions = state.addresses.options.filter(
        ({ nickName }) => nickName !== oldNickName
      );

      const newAddress = {
        ...state.addresses.new,
        ...newAddressDetails
      };
      return {
        ...state,
        avi: null,
        addresses: {
          ...state.addresses,
          new: null,
          options: [...oldAddressOptions, { ...newAddress }],
          lastSaved: Date.now()
        },
        aviLoading: false
      };
    }
    case CHECKOUT_SAVE_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        orderHeaderInformation: {
          ...(state.orderHeaderInformation || {}),
          savedCreditCard: action.payload.enabled
        }
      };
    case CHECKOUT_VERIFY_ADDRESS_BEGIN:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          new: { ...action.payload }
        },
        aviLoading: true
      };
    case CHECKOUT_VERIFY_ADDRESS_FAIL: {
      return {
        ...state,
        aviLoading: false,
        // TODO: Put some error message here probably
        avi: {
          enabled: false
        }
      };
    }
    case CHECKOUT_VERIFY_ADDRESS_SUCCESS: {
      return {
        ...state,
        avi: { ...action.payload },
        aviLoading: false
      };
    }
    case CHECKOUT_SAVE_PONUMBER_SUCCESS:
      return {
        ...state,
        orderHeaderInformation: {
          ...(state.orderHeaderInformation || {}),
          purchaseOrder: {
            ...action.payload.purchaseOrderInfo,
            poNumber: action.payload.poNumber,
            poAttachmentName: action.payload.poAttachment?.fileName
          }
        }
      };
    case CHECKOUT_RESET:
      return checkoutInitialStore;
    case CHECKOUT_LOAD_CART_BEGIN:
      return { ...state, isShoppingCartPageLoading: true };
    case CHECKOUT_LOAD_CART_SUCCESS:
      return {
        ...state,
        deepLink: action.payload,
        isShoppingCartPageLoading: false
      };
    case CHECKOUT_LOAD_CART_FAIL:
      return {
        ...state,
        isShoppingCartPageLoading: false
      };
    case CHECKOUT_SUMMARY_SET_BILLING_ADDRESS:
      return {
        ...state,
        selectedBillingAddress: action.payload
      };
    case CHECKOUT_SUMMARY_SET_BILLING_METHOD:
      return {
        ...state,
        selectedBillingMethod: action.payload.billingMethod,
        selectedAdditionalBillingMethod:
          action.payload.selectedAdditionalBillingMethod
      };
    case CHECKOUT_SUMMARY_SET_ALTERNATIVE_PAYMENT:
      return {
        ...state,
        selectedAlternativePayment: action.payload.alternativePayment
      };
    case CHECKOUT_SUMMARY_SET_ADDITIONAL_BILLING_METHOD:
      return {
        ...state,
        selectedAdditionalBillingMethod: action.payload
      };
    case GET_DEALER_DCN_CUSTOMER_BEGIN:
      return {
        ...state,
        isGetDealerDCNLoading: true,
        isIIBFail: false
      };
    case GET_DEALER_DCN_CUSTOMER_SUCCESS:
      return {
        ...state,
        isGetDealerDCNLoading: false,
        dcnLoadingStatus: STATUS.RESOLVED,
        isIIBFail: false,
        dealerDCNAssociations: action.payload
      };
    case GET_DEALER_DCN_CUSTOMER_FAIL:
      return {
        ...state,
        dealerDCNAssociations: [],
        isGetDealerDCNLoading: false,
        isIIBFail: true
      };
    case CHECKOUT_PLACE_ORDER_BEGIN:
      return {
        ...state,
        isPlacingOrder: true
      };
    case CHECKOUT_PLACE_ORDER_FAIL:
    case CHECKOUT_PLACE_ORDER_END:
      return {
        ...state,
        isPlacingOrder: false
      };
    case CHECKOUT_SET_ORDER_INFORMATION_BEGIN:
      return {
        ...state,
        setOrderInformationStatus: STATUS.PENDING,
        isPartsRemovedByInvalidSOS: false,
        removedParts: []
      };
    case CHECKOUT_SET_ORDER_INFORMATION_FAIL:
      return {
        ...state,
        setOrderInformationStatus: STATUS.REJECTED
      };
    case CHECKOUT_SET_ORDER_INFORMATION_SUCCESS:
      return {
        ...state,
        setOrderInformationStatus: STATUS.RESOLVED,
        isPartsRemovedByInvalidSOS: action.payload.isPartsRemovedByInvalidSOS,
        removedParts: action.payload.removedParts
      };
    case CHECKOUT_SET_ORDER_INFORMATION_IDLE:
      return {
        ...state,
        setOrderInformationStatus: STATUS.IDLE
      };
    case CHECKOUT_CART_FORM_SUBMIT_BEGIN:
      return {
        ...state,
        cartFormSubmitStatus: STATUS.PENDING
      };
    case CHECKOUT_CART_FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        cartFormSubmitStatus: STATUS.RESOLVED
      };
    case CHECKOUT_CART_FORM_SUBMIT_FAIL:
      return {
        ...state,
        cartFormSubmitStatus: STATUS.REJECTED
      };
    case CHECKOUT_CART_FORM_SUBMIT_IDLE:
      return {
        ...state,
        cartFormSubmitStatus: STATUS.IDLE
      };
    case GET_SOS_DETAILS_SUCCESS:
      return {
        ...state,
        currentAddToCartModifications: action.payload.items,
        currentAddToCartHasSOS: action.payload.hasSOS,
        isGstAvailable: action.payload.isGstAvailable,
        isGstIncludesTax: action.payload.isGstIncludesTax,
        sosItemDetailsStatus: STATUS.RESOLVED
      };
    case GET_DEALER_PRICE_AND_AVAILABILITY_BEGIN:
      return {
        ...state,
        dealerPriceAndAvailabilityStatus: STATUS.PENDING,
        addToCartStatus: STATUS.IDLE
      };
    case GET_DEALER_PRICE_AND_AVAILABILITY_FAIL:
      return { ...state, dealerPriceAndAvailabilityStatus: STATUS.REJECTED };
    case GET_DEALER_PRICE_AND_AVAILABILITY_SUCCESS:
      return {
        ...state,
        currentAddToCartHasReplacement: action.payload.hasReplacement,
        currentAddToCartHasAlternate: action.payload.hasAlternate,
        dealerPriceAndAvailabilityStatus: STATUS.RESOLVED,
        currentAddToCartModifications: {
          ...state.currentAddToCartModifications,
          ...action.payload.modifications,
          items: action.payload.items
        }
      };
    case GET_DEALER_PRICE_AND_AVAILABILITY_RESET:
      return {
        ...state,
        dealerPriceAndAvailabilityStatus: STATUS.IDLE
      };
    case UPDATE_PARTS:
      return {
        ...state,
        currentAddToCartModifications: {
          ...state.currentAddToCartModifications,
          items: { ...action.payload }
        }
      };
    case ADD_TO_CART_MODIFICATIONS_RESET:
      return {
        ...state,
        currentAddToCartModifications: {},
        currentAddToCartHasSOS: false,
        currentAddToCartHasAlternate: false,
        currentAddToCartHasReplacement: false,
        currentAddToCartPhase: null,
        sosItemDetailsStatus: STATUS.IDLE,
        addToCartStatus: STATUS.IDLE
      };
    case ADD_TO_CART_SET_PHASE:
      return {
        ...state,
        currentAddToCartPhase: action.payload
      };
    case ADD_TO_CART_RESET_PHASE:
      return {
        ...state,
        currentAddToCartPhase: null
      };
    case ADD_TO_CART_RESET_PHASE_AND_FLAGS:
      return {
        ...state,
        currentAddToCartPhase: null,
        currentAddToCartHasSOS: false,
        currentAddToCartHasAlternate: false,
        currentAddToCartHasReplacement: false
      };
    case SET_IS_ADD_TO_CART_AND_CHECKOUT_FLOW:
      return {
        ...state,
        isCurrentAddToCartAndCheckoutFlow: action.payload
      };
    case GET_SOS_DETAILS_BEGIN:
      return {
        ...state,
        sosItemDetailsStatus: STATUS.PENDING
      };
    case GET_SOS_DETAILS_FAIL:
      return {
        ...state,
        sosItemDetailsStatus: STATUS.REJECTED
      };
    case ADD_QO_TO_CART_BEGIN:
      return {
        ...state,
        addToCartStatus: STATUS.PENDING
      };
    case ADD_QO_TO_CART_SUCCESS:
      return {
        ...state,
        addToCartStatus: STATUS.RESOLVED
      };
    case ADD_QO_TO_CART_FAIL:
      return {
        ...state,
        addToCartStatus: STATUS.REJECTED
      };
    case CHECKOUT_SIS_TO_CART_FAIL:
      return {
        ...state,
        sisImportValidAndInvalidParts: action.payload.errors,
        isInvalidParts: action.payload.isError,
        partItems: action.payload.items
      };
    case CHECKOUT_ADD_TO_CART_BEGIN:
      return {
        ...state,
        addToCartSubmitStatus: STATUS.PENDING
      };
    case CHECKOUT_ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addToCartSubmitStatus: STATUS.RESOLVED
      };
    case CHECKOUT_ADD_TO_CART_FAIL:
      return {
        ...state,
        addToCartSubmitStatus: STATUS.REJECTED
      };

    case PICKUP_DELIVERY_SAVE_ADDRESS_BEGIN:
      return {
        ...state,
        [SHOP_ONLINE]: {
          ...state[SHOP_ONLINE],
          saveAddressStatus: STATUS.PENDING
        }
      };
    case PICKUP_DELIVERY_SAVE_ADDRESS_SUCCESS:
      const { newAddresses } = action.payload;
      return {
        ...state,
        [SHOP_ONLINE]: {
          ...state[SHOP_ONLINE],
          address: newAddresses,
          saveAddressStatus: STATUS.RESOLVED
        }
      };
    case PICKUP_DELIVERY_SAVE_ADDRESS_FAILS:
      return {
        ...state,
        [SHOP_ONLINE]: {
          ...state[SHOP_ONLINE],
          saveAddressStatus: STATUS.REJECTED
        }
      };
    case PICKUP_DELIVERY_SUBMIT_BEGIN:
      return {
        ...state,
        isRequestByDateFails: false,
        formSubmitStatus: STATUS.PENDING
      };
    case PICKUP_DELIVERY_SUBMIT_SUCCESS:
      return {
        ...state,
        formSubmitStatus: STATUS.RESOLVED
      };
    case PICKUP_DELIVERY_SUBMIT_FAILS:
      return {
        ...state,
        formSubmitStatus: STATUS.REJECTED
      };
    case PICKUP_DELIVERY_EXPORT_COMPLIANCE_SUCCESS:
      return {
        ...state,
        exportComplianceFails: false,
        exportComplianceErrorMessage: ''
      };
    case PICKUP_DELIVERY_EXPORT_RESET:
      return {
        ...state,
        exportComplianceFails: false,
        exportComplianceErrorMessage: '',
        formSubmitStatus: STATUS.IDLE
      };
    case PICKUP_DELIVERY_EXPORT_COMPLIANCE_FAILS:
      return {
        ...state,
        exportComplianceFails: true,
        exportComplianceErrorMessage: action.payload.errorMessage,
        formSubmitStatus: STATUS.REJECTED
      };
    case PICKUP_DELIVERY_REQUEST_BY_DATE_FAILS:
      return {
        ...state,
        isRequestByDateFails: true,
        formSubmitStatus: STATUS.REJECTED
      };

    case QUICK_ORDER_CHECKOUT:
      return {
        ...state,
        isFromQuickOrder: action.payload.isFromQuickOrder
      };
    case RESET_CURRENT_MODAL_PHASE:
      return {
        ...state,
        currentModalPhase: ''
      };
    case SET_CURRENT_MODAL_PHASE:
      return {
        ...state,
        currentModalPhase: action.payload
      };

    case PIX_PAYMENT_STATUS_BEGIN:
      return { ...state, pixPaymentStatus: PIX_PAYMENT_STATUS_TYPES.PENDING };
    case PIX_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        pixPaymentStatus: action.payload.pixPaymentStatus
      };
    case PIX_PAYMENT_STATUS_FAIL:
      return {
        ...state,
        pixPaymentStatus: PIX_PAYMENT_STATUS_TYPES.FAILED
      };
    case CHECKOUT_GET_CAT_CARD_PROMOS_BEGIN:
      return {
        ...state,
        catCardPromotionStatus: STATUS.PENDING,
        promotions: []
      };
    case CHECKOUT_GET_CAT_CARD_PROMOS_SUCCESS:
      return {
        ...state,
        catCardPromotionOptionsToggle: false,
        catCardPromotionStatus: STATUS.RESOLVED,
        ...action.payload
      };
    case CHECKOUT_GET_CAT_CARD_PROMOS_FAIL:
      return {
        ...state,
        catCardPromotionStatus: STATUS.REJECTED,
        promotions: []
      };
    case CHECKOUT_CLEAR_PROMOTIONS:
      return {
        ...state,
        promotions: []
      };
    case CHECKOUT_SELECT_CAT_CARD_PROMOTION:
      return {
        ...state,
        catCardPromotionOptionsToggle: action.payload.isPromoActive,
        catCardSelectedPromotionOption: action.payload.value
      };
    case BUILD_SHARE_CART_LINK_BEGIN:
      return { ...state, shareCartStatus: STATUS.PENDING };
    case BUILD_SHARE_CART_LINK_SUCCESS:
      return {
        ...state,
        deepLinkUrl: action.payload.redirectUrl,
        shareCartStatus: STATUS.RESOLVED
      };
    case BUILD_SHARE_CART_LINK_FAIL:
      return {
        ...state,
        shareCartStatus: STATUS.REJECTED
      };

    case CHECK_SALESFORCE_ACCOUNT_BALANCE_CLEAR:
      return {
        ...state,
        catSalesForceAccountBalanceStatus: STATUS.IDLE,
        catSalesForceAccountBalance: {
          ...checkoutInitialStore.catSalesForceAccountBalance
        },
        applyCatCreditsGlobal: {
          ...checkoutInitialStore.applyCatCreditsGlobal
        }
      };

    case CHECK_SALESFORCE_ACCOUNT_BALANCE_BEGIN:
      return { ...state, catSalesForceAccountBalanceStatus: STATUS.PENDING };

    case CHECK_SALESFORCE_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...state,
        catSalesForceAccountBalance: {
          ...action.payload
        },
        catSalesForceAccountBalanceStatus: STATUS.RESOLVED
      };
    case CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_BEGIN:
      return {
        ...state,
        catCardGlobalOrderTotalPayload: {},
        catCardGlobalOrderTotalResponseStatus: STATUS.PENDING
      };

    case CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        catCardGlobalOrderTotalResponseStatus: STATUS.RESOLVED
      };

    case CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_FAIL:
      return {
        ...state,
        catCardGlobalOrderTotalResponse: {
          ...state.catCardGlobalOrderTotalResponse
        },
        catCardGlobalOrderTotalResponseStatus: STATUS.REJECTED
      };

    case CHECK_SALESFORCE_ACCOUNT_BALANCE_FAIL:
      return {
        ...state,
        catSalesForceAccountBalance: {
          ...state.catSalesForceAccountBalance,
          accountBalance: action.payload.balance
        },
        catSalesForceAccountBalanceStatus: STATUS.REJECTED
      };

    case CAT_CREDITS_DELETE_ACCOUNT_BEGIN:
      return {
        ...state,
        catCreditsDeleteAccount: STATUS.PENDING
      };

    case CAT_CREDITS_DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        catCreditsDeleteAccount: STATUS.RESOLVED
      };

    case CAT_CREDITS_DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        catCreditsDeleteAccount: STATUS.REJECTED
      };
    case CHECKOUT_APPLY_CAT_CREDITS_BEGIN:
      return {
        ...state,
        applyCatCreditsGlobal: {
          ...state.applyCatCreditsGlobal,
          applyCatCreditsGlobalStatus: STATUS.PENDING
        }
      };

    case CHECKOUT_APPLY_CAT_CREDITS_SUCCESS:
      return {
        ...state,
        applyCatCreditsGlobal: {
          wasClickedApplyCreditsBtn: true,
          applyCatCreditsGlobalBalance: { ...action.payload },
          applyCatCreditsGlobalStatus: STATUS.RESOLVED
        }
      };

    case CHECKOUT_APPLY_CAT_CREDITS_FAIL:
      return {
        ...state,
        applyCatCreditsGlobal: {
          ...state.applyCatCreditsGlobal,
          wasClickedApplyCreditsBtn: false,
          applyCatCreditsGlobalStatus: STATUS.REJECTED
        }
      };

    case CHECKOUT_APPLY_CAT_CREDITS_PLACE_ORDER:
      return {
        ...state,
        applyCatCreditsGlobal: {
          ...state.applyCatCreditsGlobal,
          shouldDisablePlaceOrderBtn: action.payload
        }
      };

    case SAVE_CAT_CREDITS_ACCOUNT_GLOBAL_EXPANSION_CHECKBOX:
      return {
        ...state,
        saveCatCreditsAccountCheckbox: action.payload
      };

    case SHARE_CONFIRMATION_EMAIL_IDLE:
      return {
        ...state,
        shareConfirmationStatus: STATUS.IDLE
      };
    case SHARE_CONFIRMATION_EMAIL_BEGIN:
      return {
        ...state,
        shareConfirmationStatus: STATUS.PENDING
      };
    case SHARE_CONFIRMATION_EMAIL_SUCCESS:
      return {
        ...state,
        shareConfirmationStatus: STATUS.RESOLVED
      };
    case SHARE_CONFIRMATION_EMAIL_FAIL:
      return {
        ...state,
        shareConfirmationStatus: STATUS.REJECTED
      };
    case APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_FAIL: {
      return {
        ...state,
        applyCatVantageRewardsToOrderStatus: STATUS.REJECTED
      };
    }
    case APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_BEGIN: {
      return {
        ...state,
        applyCatVantageRewardsToOrderStatus: STATUS.PENDING
      };
    }
    case APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_SUCCESS: {
      const orderTotals = action.payload.orderTotals;
      // For orders not using Cat Credits billing method, we only apply
      // Cat Vantage Regards as Cat Credits (The business requirements say so),
      // we should not include any E-certificate that the user added manually.
      // For users, with Cat Credits billing method selected, we use all the
      // order totals that the server send in the apply endpoint. They include
      // Everything (Cat Credits + rewards in the orderCatPrepaidCertificatesTotal)
      const orderTotalsForNonCatCreditBillingMethod = {
        ...orderTotals,
        orderCatPrepaidCertificatesTotal: orderTotals.rewardsAppliedToOrder,
        unformattedOrderCatPrepaidCertificatesTotal:
          orderTotals.unformattedRewardsAppliedToOrder
      };
      return {
        ...state,
        orderTotals: orderTotalsForNonCatCreditBillingMethod,
        catCreditTotals: orderTotals,
        applyCatVantageRewardsToOrderStatus: STATUS.RESOLVED
      };
    }
    default:
      return state;
  }
};
export { checkoutInitialStore };
export default checkoutReducer;
