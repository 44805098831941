import { isEmpty } from '@app/utils';
import { useSelector } from 'react-redux';
import { START_TYPING_IMAGE, START_TYPING_IMAGE_SIZE } from '../constant';
import { CatHeading } from '@blocks-react/components/Heading';
import { Conditional } from '@cat-ecom/pcc-components';
import styles from './AddEquipmentDrawer.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { CatLoadingIndicator } from '@blocks-react/components/LoadingIndicator';
import PropTypes from 'prop-types';

const SearchResults = ({
  loading,
  searchState,
  isBLOCKSSM,
  searchTerm,
  isSuccessfulSearch,
  getMultiSerialSearch,
  errorText
}) => {
  const [t] = useTranslation();
  const isLucid = useSelector(
    state => state.featureFlag.PCCLucidSolrAddEquipmentComponentFlag
  );
  const getHeader = (variant, content) => (
    <CatHeading variant={variant}>{content}</CatHeading>
  );
  const getNoMatchLabel = () => {
    if (isLucid) {
      return (
        <div className="pt-2 mt-1 ps-3">
          {getHeader(
            'label',
            <span className="u-color--DarkGray">{t('NO_RESULTS_FOUND')}</span>
          )}
          <div className="mt-2">
            {getHeader(
              'body-sm',
              <span className="u-color--Black">{t('REFINE_KEYWORD')}</span>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-2 ps-3">
          {getHeader('label', t('MEQ_NO_EXACT_MATCH'))}
        </div>
      );
    }
  };
  return (
    <>
      <Conditional test={loading && !searchState.success}>
        <div
          className={cx('text-center', {
            [styles['loader-container']]: !isBLOCKSSM,
            'my-4 py-3': isBLOCKSSM
          })}
        >
          <CatLoadingIndicator
            className="mb-3"
            data-testid="suggestions-loader"
          />
          <CatHeading variant="label">{t('MEQ_LOADING_RESULTS')}</CatHeading>
        </div>
      </Conditional>
      <Conditional
        test={isEmpty(searchTerm) && !isBLOCKSSM && !searchState.success}
      >
        <div className={`${styles['loader-container']}`}>
          <figure className="mx-auto mb-3">
            <img
              src={`${START_TYPING_IMAGE.imgUrl}${START_TYPING_IMAGE_SIZE.MOB}`}
              alt={START_TYPING_IMAGE.imgAlt}
              loading="lazy"
            />
          </figure>
          <CatHeading variant="label" className="text-center">
            {t('START_TYPING_MODEL')}
          </CatHeading>
        </div>
      </Conditional>
      <Conditional test={isSuccessfulSearch}>
        {!isEmpty(searchState.results) ? (
          <>
            {getMultiSerialSearch(searchState.results, searchTerm)}
            <Conditional test={searchState.results.length > 20 && isLucid}>
              <div className="my-2 text-center">
                {getHeader(
                  'body',
                  <span className="u-color--Black">
                    {t('MEQ_MODEL_TRY_REFINING')}
                  </span>
                )}
              </div>
            </Conditional>
          </>
        ) : (
          <>{getNoMatchLabel()}</>
        )}
        {!isLucid && !isEmpty(searchState.otherResults) && (
          <>
            <div className={`${styles['background-color']} p-1 ps-3`}>
              {getHeader('body', t('MEQ_OTHER_SUGGESTIONS'))}
            </div>
            {getMultiSerialSearch(searchState.otherResults, searchTerm)}
          </>
        )}
        {!isLucid &&
          (!isEmpty(searchState.results) ||
            !isEmpty(searchState.otherResults)) && (
            <div className="text-center mx-5 my-2">
              {getHeader('body', t('MEQ_MODEL_TRY_REFINING'))}
            </div>
          )}
      </Conditional>
      {errorText && (
        <div className="mx-3 my-2">
          <CatHeading color="error" variant="body-sm">
            {errorText}
          </CatHeading>
        </div>
      )}
    </>
  );
};

SearchResults.propTypes = {
  loading: PropTypes.bool,
  searchState: PropTypes.object,
  isBLOCKSSM: PropTypes.bool,
  searchTerm: PropTypes.string,
  isSuccessfulSearch: PropTypes.bool,
  getMultiSerialSearch: PropTypes.func,
  errorText: PropTypes.string
};

export default SearchResults;
