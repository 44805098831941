import PropTypes from 'prop-types';
import { CatPrimaryNavItem } from '@blocks-react/components/PrimaryNavItem';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { replaceTokensInString } from '@app/utils';
import links from '@app/constants/links';
import { useRouteMatch } from 'react-router-dom';
import { SHOPPING_CART_ROUTE } from '@app/constants/routes';
import { MAIN_NAV_TRACK } from '@app/constants/analyticsConstants';
import cx from 'classnames';
import styles from '../Subheader.modules.scss';

const CartListsWrapper = ({ isMobile = false }) => {
  const { t } = useTranslation();
  const isCurrentPage = useRouteMatch(SHOPPING_CART_ROUTE);

  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const catalogId = useSelector(s => s.common?.catalogId);

  const quickOrderHref = replaceTokensInString(
    links.SHOPPING_CART_LINK,
    storeId,
    langId,
    catalogId
  );

  return (
    <CatPrimaryNavItem
      data-testid="cartListsWrapperItem"
      href={quickOrderHref}
      text={t('BUILD_CART_LINK')}
      isCurrent={!!isCurrentPage}
      data-track-event={MAIN_NAV_TRACK.EVENT}
      data-track-attr-nltext={MAIN_NAV_TRACK.QUICK_ORDER_TEXT}
      data-track-attr-context={MAIN_NAV_TRACK.TOP_MENU_CONTEXT}
      className={cx({ [styles['item-border-bottom']]: isMobile })}
    />
  );
};

CartListsWrapper.propTypes = {
  isMobile: PropTypes.bool
};

export default CartListsWrapper;
