import resolvedEndpoints from '../../constants/endpoints';
import { getHttpInstance } from '@app/services/defaultHttpService';
import { replaceTokensInUrl } from '@app/utils';
import * as types from './constants';
import { getRequestServicePayload } from './utils';

const {
  REQUEST_SERVICE_DRAFT_URL,
  REQUEST_SERVICE_SUBMIT_URL,
  GET_EXISTING_REQUEST_SERVICE,
  HELIOS_FALLBACK_LANGUAGE
} = resolvedEndpoints;

export const getHeliosMappedLanguage = () => async (dispatch, getState) => {
  const { langId } = getState().common;
  const fallBackLanguageURL = replaceTokensInUrl(
    HELIOS_FALLBACK_LANGUAGE,
    langId
  );
  const http = getHttpInstance();
  try {
    const heliosLang = await http.get(fallBackLanguageURL);
    return heliosLang?.data;
  } catch (error) {
    console.log(error);
  }
};

export const requestServiceDraft =
  (
    onSuccessCB,
    checkForExsistingRequestId = true,
    fromServiceInsight = false,
    lang = 'en_US'
  ) =>
  async (dispatch, getState) => {
    const {
      requestService: { requestServiceReferenceId }
    } = getState();
    if (checkForExsistingRequestId && requestServiceReferenceId) {
      onSuccessCB();
      return;
    }
    const http = getHttpInstance();
    const {
      common: { firstName, lastName, profileAddress, storeId }
    } = getState();

    const draftPayload = {
      customerPartyNumber: '',
      userName: `${firstName} ${lastName}`,
      submitterEmail: profileAddress.email1 ?? '',
      locale: lang
    };
    const draftUrl = replaceTokensInUrl(REQUEST_SERVICE_DRAFT_URL, storeId);
    dispatch({ type: types.REQUEST_SERVICE_DRAFT_BEGIN });
    return http
      .post(
        draftUrl,
        draftPayload,
        fromServiceInsight && {
          params: { isSerRecModel: true }
        }
      )
      .then(res => {
        if (res.data && res.data.id) {
          dispatch({
            type: types.REQUEST_SERVICE_DRAFT_SUCCESS,
            payload: res.data.id
          });
          onSuccessCB();
        }
      })
      .catch(err => {
        dispatch({ type: types.REQUEST_SERVICE_DRAFT_FAIL });
      });
  };

export const requestServiceSubmit =
  (
    requestServiceData,
    equipmentDetails,
    requestServiceSubmitCB,
    serviceInsightDetails,
    lang = 'en_US'
  ) =>
  async (dispatch, getState) => {
    const http = getHttpInstance();
    const {
      requestService: { requestServiceReferenceId },
      common: { storeId }
    } = getState();

    const submitUrl = replaceTokensInUrl(REQUEST_SERVICE_SUBMIT_URL, storeId);

    dispatch({ type: types.REQUEST_SERVICE_SUBMIT_BEGIN });
    try {
      if (requestServiceReferenceId) {
        const submitPayload = getRequestServicePayload(
          equipmentDetails,
          requestServiceData,
          requestServiceReferenceId,
          lang,
          serviceInsightDetails
        );

        const { data } = await http.post(
          submitUrl,
          submitPayload,
          serviceInsightDetails?.fromServiceInsight && {
            params: { isSerRecModel: true }
          }
        );
        dispatch({
          type: types.REQUEST_SERVICE_SUBMIT_SUCCESS,
          payload: data
        });
        requestServiceSubmitCB(true);
      }
    } catch (err) {
      dispatch({ type: types.REQUEST_SERVICE_SUBMIT_FAIL });
      requestServiceSubmitCB(false);
    }
  };

export const getRequestService =
  (serialNumber, model) => (dispatch, getState) => {
    const http = getHttpInstance();
    const payload = {
      assetSerialNumber: serialNumber || model
    };
    const {
      common: { storeId }
    } = getState();
    const url = replaceTokensInUrl(GET_EXISTING_REQUEST_SERVICE, storeId);
    dispatch({ type: types.EXISTING_SERVICE_STATUS_BEGIN });
    return http
      .post(url, payload)
      .then(res => {
        if (res.data && res.data.requestService) {
          dispatch({
            type: types.EXISTING_SERVICE_STATUS_SUCCESS,
            payload: res.data.requestService
          });
        }
      })
      .catch(err => {
        dispatch({ type: types.EXISTING_SERVICE_STATUS_FAIL });
      });
  };
