import {
  Conditional,
  SubheaderContainer,
  SubheaderUtilityNav
} from '@cat-ecom/pcc-components';
import { CatLayoutContainer } from '@blocks-react/components/LayoutContainer';
import useCheckoutRoutes from '../header/hooks/useCheckoutRoutes';
import styles from './Subheader.modules.scss';
import SubHeaderPrimaryNavWrapper from './SubHeaderPrimaryNavWrapper';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import SubheaderUtilityNavLinks from './SubheaderUtilityNavLinks';
import { useSelector } from 'react-redux';
import SubheaderLoading from '../header/SubheaderLoading';
import cx from 'classnames';

const Subheader = () => {
  const isDesktop = useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);
  const isCheckoutRoute = useCheckoutRoutes();
  const showSubHeader = !isCheckoutRoute;
  const mobileBreakpoint = PAGE_BREAKPOINTS.BLOCKS_LG - 1;

  const getHeaderInfoSuccess = useSelector(s => s.header?.getHeaderInfoSuccess);
  const getHeaderInfoLoading = useSelector(s => s.header?.getHeaderInfoLoading);

  const isNavigationResolved =
    getHeaderInfoSuccess === true && getHeaderInfoLoading === false;
  const isNavigationRejected =
    getHeaderInfoSuccess === false && getHeaderInfoLoading === false;

  const isNavigationResovedOrRejected =
    isNavigationResolved || isNavigationRejected;

  return (
    <Conditional test={showSubHeader && isDesktop}>
      <div
        className={cx(styles['subheader'], 'd-print-none')}
        data-testid="subheader"
      >
        <CatLayoutContainer>
          <SubheaderContainer>
            <Conditional
              test={isNavigationResovedOrRejected}
              fallback={<SubheaderLoading />}
            >
              <SubHeaderPrimaryNavWrapper
                mobileBreakpoint={mobileBreakpoint}
                isMobile={!isDesktop}
              />
              <SubheaderUtilityNav>
                <SubheaderUtilityNavLinks displayMobileNavLink={!isDesktop} />
              </SubheaderUtilityNav>
            </Conditional>
          </SubheaderContainer>
        </CatLayoutContainer>
      </div>
    </Conditional>
  );
};

export default Subheader;
