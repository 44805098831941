import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CatHeading } from '@blocks-react/components/Heading';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  LoginDisclaimer,
  useModal
} from '@cat-ecom/pcc-components';
import SignInButton from '@app/components/common/LoginAndRegisterButtons/SignInButton';
import RegisterButton from '@app/components/common/LoginAndRegisterButtons/RegisterButton';
import { DealerLocatorModalWrapper } from '@app/components/dealer-locator-react';
import { CreateAccountDescription } from '@app/components/dealer-locator-react/CreateAccountDescription/CreateAccountDescription';

const LoginDrawer = ({
  children,
  closeDrawer,
  drawerProps,
  title,
  signinCallback = null
}) => {
  const { t } = useTranslation();
  const modal = useModal({
    isRequired: true,
    heading: `${t('STEP_1')}: ${t('REGISTER_MODAL_SELECT_DEALER_STORE')}`,
    backButton: false,
    size: 'md'
  });

  return (
    <>
      <Drawer {...drawerProps}>
        <DrawerHeader>
          <CatHeading variant="title-sm">{title}</CatHeading>
        </DrawerHeader>
        <DrawerBody className="pt-4">{children}</DrawerBody>
        <DrawerFooter>
          <LoginDisclaimer text={t('SIGN_CREATE_ACCOUNT_MSG')} />
          <SignInButton className="pb-3" signinCallback={signinCallback} />
          <RegisterButton
            closeDrawer={closeDrawer}
            openDealerLocator={modal.initiatorProps.onClick}
          />
        </DrawerFooter>
      </Drawer>
      <DealerLocatorModalWrapper isRegistration modal={modal}>
        <CreateAccountDescription />
      </DealerLocatorModalWrapper>
    </>
  );
};

LoginDrawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  closeDrawer: PropTypes.func,
  drawerProps: PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string,
    isActive: PropTypes.bool,
    onBlClose: PropTypes.func,
    hasBackdrop: PropTypes.bool,
    align: PropTypes.string,
    size: PropTypes.string,
    initialActive: PropTypes.func
  }),
  title: PropTypes.string,
  signinCallback: PropTypes.objectOf(
    PropTypes.shape({
      detail: { redirectUrl: PropTypes.string }
    })
  )
};

export default LoginDrawer;
