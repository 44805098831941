import PropTypes from 'prop-types';
import styles from './EspotSkeletonLoaderBlocks.module.scss';
import { CatSkeleton } from '@blocks-react/components/Skeleton';
import { ESPOT_VARIANTS } from '@app/constants/commonConstants';

const EspotSkeletonLoaderBlocks = ({ variant = ESPOT_VARIANTS.PINSTRIPE }) => {
  const getEspotContainerClassName = () => {
    if (variant in ESPOT_VARIANTS) {
      return `espot__loader__blocks-${variant}`;
    }

    return `espot__loader__blocks-${ESPOT_VARIANTS.PINSTRIPE}`;
  };

  return (
    <div
      className={`${styles['espot__loader__blocks']} ${
        styles[getEspotContainerClassName()]
      }`}
      data-testid="SkeletonContainer"
    >
      <CatSkeleton size="xxl" width="100%" data-testid="CatSkeleton" />
    </div>
  );
};

EspotSkeletonLoaderBlocks.propTypes = {
  variant: PropTypes.string
};

export default EspotSkeletonLoaderBlocks;
