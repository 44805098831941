import { WarningIcon } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import { CSR_TEXTS } from '../pages/csr-landing/texts';

export const OUTAGE_MESSAGE = 'OUTAGE_MESSAGE';
export const OUTAGE_MESSAGE_TYPE = 'ERP_OUTAGE_NOTIFICATION';
export const CUSTOM_OUTAGE_MESSAGE = 'CUSTOM_OUTAGE_MESSAGE';
export const CATSAS_IE_ALERT_ESPOT_HOME_PAGE =
  'CATSAS_IE_ALERT_ESPOT_HOME_PAGE';
export const PCC_EMERGENCY_MESSAGE = 'PCC_EMERGENCY_MESSAGE';
export const CUSTOM_ALL_DEALER_OUTAGE_MESSAGE =
  'CATSAS_PCC_ALL_DEALER_CMC_ESPOT_OUTAGE_MESSAGE';
export const OUTAGE_MESSAGE_WARNING_IMAGE_PROPS = {
  icon: WarningIcon,
  customClassName: 'u-fill-icon--MediumOrange pe-1'
};

export const OUTAGE_MESSAGE_TOKENS_NAME = [
  { '[XX:XX]': 'startTime' },
  { '[YY:YY]': 'endTime' },
  { '[TIMEZONE]': 'timezone' },
  { '[DAY]': 'day' },
  { '[MONTH_ENGLISH]': 'monthEnglish' },
  { '[MONTH_SPANISH]': 'monthSpanish' },
  { '[YEAR]': 'year' },
  { '[SUFFIX]': 'dayOfMonthSuffix' }
];

export const IS_MANUAL_APPROVAL_MESSAGE_VIEWED =
  'isManualApprovalMessageViewed';

export const GLOBAL_BANNER_ESPOTS = [
  CATSAS_IE_ALERT_ESPOT_HOME_PAGE
];

export const CUSTOM_BANNER_ESPOTS = [
  CUSTOM_OUTAGE_MESSAGE,
  CUSTOM_ALL_DEALER_OUTAGE_MESSAGE,
  CATSAS_IE_ALERT_ESPOT_HOME_PAGE
];

export const DELAY_VERIFIED_TOAST_MESSAGE_IN_SECONDS = 5;
export const BANNER_TOAST_MESSAGES_SHOWED = 'BANNER_TOAST_MESSAGES_SHOWED';

export const BANNER_TOASTS = {
  instantAccess: {
    name: 'instantAccess',
    message: CSR_TEXTS.INSTANT_ACCESS_TOAST_MESSAGE
  },
  cspCustomer: {
    name: 'cspCustomer',
    message: CSR_TEXTS.VERIFIED_ACCOUNT_MESSAGE
  },
  guestWarning: {
    name: 'guestWarning',
    message: CSR_TEXTS.CSR_CHECKING_ORDER_BY_GUEST_MESSAGE
  }
};

export const ESPOT_TYPE = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.shape({ [PropTypes.string]: PropTypes.string })),
  PropTypes.shape()
]);
