import PropTypes from 'prop-types';
import {
  MyEquipmentHeader,
  MyEquipmentLinkContent,
  Drawer,
  MyEquipmentFooter,
  MyEquipmentButton
} from '@cat-ecom/pcc-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CatHeading } from '@blocks-react/components/Heading';
import SelectedEquipmentInfo from '../../SelectedEquipmentInfo/SelectedEquipmentInfo';
import {
  clearErrors,
  RemoveEquipmentDataDetails,
  setEquipmentToast
} from '@app/store/myequipment/actions';
import ErrorBanner from '../../ErrorBanner';
import { EQUIPMENT_METHOD } from '../../constant';
import { replaceTokensInString } from '@app/utils';
import { capitalizeText } from '@app/utils/stringUtils';
import styles from './DeleteEquipmentDrawer.module.scss';
import { BUTTON_VARIANTS } from '@app/constants/commonConstants';

const DeleteEquipmentDrawer = ({
  drawerProps,
  onClose,
  currentEquipment,
  isChild = false
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const resetState = () => {
    dispatch(clearErrors());
  };
  const onCloseDelete = (_e = null, closeParent = false) => {
    resetState();
    onClose(closeParent);
  };
  const selectedEquipment = useSelector(
    s => s.myEquipment.equipments.selectedEquipment
  );
  const handleDrawerOperationSuccess = () => {
    const equipmentTitle = replaceTokensInString(
      selectedEquipment?.uniqueIndex === currentEquipment?.uniqueIndex
        ? `${t('MEQ_DELETE_MESSAGE')}`
        : `${t('MEQ_DELETE_UNSELECTED')}`,
      currentEquipment.model,
      capitalizeText(currentEquipment.equipmentFamily)
    );
    dispatch(
      setEquipmentToast({
        method: EQUIPMENT_METHOD.DELETE,
        message: equipmentTitle
      })
    );
    onCloseDelete(null, true);
  };
  const handleRemoveEquipment = () => {
    dispatch(
      RemoveEquipmentDataDetails(currentEquipment, null, () => {
        handleDrawerOperationSuccess();
      })
    );
  };

  return (
    <div id={styles['delete-equipment']}>
      <Drawer
        {...drawerProps}
        hideCloseButton={isChild}
        disableBackdropClick={isChild}
      >
        <MyEquipmentHeader
          className="d-flex"
          variant="title-sm"
          onBackButtonClick={onCloseDelete}
        >
          {t('DELETE_EQUIPMENT')}
        </MyEquipmentHeader>
        <ErrorBanner />
        <MyEquipmentLinkContent classname="mb-3 pb-2 mt-3">
          <CatHeading variant="label-lg">
            {t('DELETE_WIDGET_HEADER')}
          </CatHeading>
        </MyEquipmentLinkContent>
        <SelectedEquipmentInfo
          currentEquipment={currentEquipment}
          isFeatureFlagEnabled
        />
        <MyEquipmentFooter>
          <MyEquipmentButton variant="danger" onClick={handleRemoveEquipment}>
            {t('DELETE')}
          </MyEquipmentButton>
          <MyEquipmentButton
            onClick={onCloseDelete}
            variant={BUTTON_VARIANTS.GHOST}
            className="ps-3"
          >
            {t('CANCEL')}
          </MyEquipmentButton>
        </MyEquipmentFooter>
      </Drawer>
    </div>
  );
};

DeleteEquipmentDrawer.propTypes = {
  drawerProps: PropTypes.object,
  currentEquipment: PropTypes.object,
  onClose: PropTypes.func,
  isChild: PropTypes.bool
};

export default DeleteEquipmentDrawer;
