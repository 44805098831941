import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Formik, Form } from 'formik';
import {
  AlloyButton,
  Input,
  Toggle,
  StickySection,
  ToastContext,
  Validator
} from 'cat-ecommerce-alloy';
import cx from 'classnames';
import ExceptionFeedbackBySelector from '@app/components/common/Exception/ExceptionFeedbackBySelector';
import {
  addProductToSavedList,
  createSavedListDetails
} from '@app/store/account/actions';
import { replaceTokensInString } from '@app/utils';
import {
  AFFILIATION_DEALER,
  DEALER_LIST,
  PRIVATE_LIST,
  PUBLIC_LIST
} from '@app/constants/commonConstants';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import { useSystemFeedback } from '@app/hooks';
import useSavedLists from '../useSavedLists';
import { addItemsPayload } from '../ConflictNotes/utils';

const CreateListForm = ({
  close,
  isCreateListOnly = false,
  createSavedListData,
  fromPDPPage = false
}) => {
  const { items, saveType } = createSavedListData;
  const savedLists = useSelector(s => s.account?.savedLists);
  const hasExistingLists = savedLists?.length > 0;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ToastCtx = useContext(ToastContext);
  const { clearError } = useSystemFeedback();
  const isCSPCustomer = useSelector(s => s.common?.isCSPCustomer);
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const isCSR = useSelector(s => s.common?.isCatCSR) || false;
  const isBuyOnBehalf = useSelector(s => s.common.isBuyOnBehalf) || false;
  const isNotCSRUser = !isCSR && !isBuyOnBehalf;
  const isInstantAccess = useSelector(s => s.common?.isInstantAccess);
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;
  const showTypeToggle =
    isNotCSRUser && !isDealerUser && (isCSPCustomer || !isInstantAccess);
  const { showSavedListsModalAfterADP = false } = useSavedLists();
  const pdpPageFlag = fromPDPPage || showSavedListsModalAfterADP;
  const current = useSelector(s => s.orders.current);
  const newLineItemNotes = useSelector(
    state => state.orders?.byId?.[current]?.ascendingOrderItems
  );
  const addItemsPayloadData = addItemsPayload(
    newLineItemNotes,
    items,
    saveType
  );

  useEffect(() => {
    return () => {
      clearError(
        ERROR_DOMAIN.SHOPPING_LIST,
        ERROR_PATH.CREATE_SHOPPING_LIST_FORM
      );
      clearError(ERROR_DOMAIN.CONTROL_CENTER, ERROR_PATH.CREATE_LIST_MODAL);
    };
  }, [clearError]);

  const onSubmit = values => {
    const listType = values.publicList ? PUBLIC_LIST : PRIVATE_LIST;
    const savedListType = isDealerUser ? DEALER_LIST : listType;
    const errorObject = {
      title: 'SAVED_LIST_CREATION_ERROR_HEADER',
      message: 'UNKONWN_ERROR',
      severity: 'error'
    };
    clearError(
      ERROR_DOMAIN.SHOPPING_LIST,
      ERROR_PATH.CREATE_SHOPPING_LIST_FORM
    );
    clearError(ERROR_DOMAIN.CONTROL_CENTER, ERROR_PATH.CREATE_LIST_MODAL);

    const onSuccess = res => {
      if (res) {
        if (items.length > 0) {
          const requisitionListId = res.requisitionListId;
          dispatch(
            addProductToSavedList({
              status: values.publicList ? PUBLIC_LIST : PRIVATE_LIST,
              requisitionListId,
              nameData: values.listName,
              close,
              errorObject,
              saveListData: pdpPageFlag ? items : addItemsPayloadData,
              ToastCtx,
              toastMessage: t('PDP_ADD_TO_LIST_TOAST')
            })
          );
        }
        close();
        clearError(
          ERROR_DOMAIN.SHOPPING_LIST,
          ERROR_PATH.CREATE_SHOPPING_LIST_FORM
        );
      }
    };
    dispatch(
      createSavedListDetails(
        values.listName,
        savedListType,
        errorObject,
        ToastCtx,
        t('SAVED_LIST_CREATED'),
        isCreateListOnly ? false : onSuccess
      )
    ).then(data => data && close());
  };

  const error = replaceTokensInString(
    t('1626_REG_PAGE_ERROR'),
    t('SAVED_LIST_NAME')
  );
  const listNameValidator = val =>
    new Validator(val).required(error).getError();
  const initialValues = { listName: '', publicList: false };

  return (
    <div className="container px-0">
      <>
        <ExceptionFeedbackBySelector
          className="mb-3"
          selector={s =>
            s.errors?.[ERROR_DOMAIN.SHOPPING_LIST]?.[
              ERROR_PATH.CREATE_SHOPPING_LIST_FORM
            ]
          }
        />
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form name="create-list__form">
            <span className={cx('text-md', 'u-color--DarkGray')}>
              {hasExistingLists
                ? `${t('CREATE_NEW_LIST_BELOW')}`
                : `${t('NO_SAVED_LISTS')} ${t('CREATE_NEW_LIST_BELOW')}`}
            </span>

            <div className={cx('mt-3')}>
              <Field
                as={Input}
                name="listName"
                label={t('LIST_NAME')}
                maxLength="40"
                showRequired
                validate={listNameValidator}
                validator={listNameValidator}
              />
            </div>

            {showTypeToggle ? (
              <>
                <span className={cx('text-md', 'u-color--DarkGray')}>
                  {`${t('LISTS_DEFAULT_PRIVATE')} ${t('PUBLIC_TO_SHARE')}`}
                </span>

                <Field
                  as={Toggle}
                  id="publicListToggle"
                  name="publicList"
                  label={t('PUBLIC')}
                  className={cx('mt-2 pt-1 mb-2')}
                  type="switch"
                />
              </>
            ) : null}

            <div>
              <span className={cx('text-xs', 'u-color--DarkGray')}>
                {t('CAT_PARTS_MANUALS_REQ_FIELDS')}
              </span>
            </div>

            <StickySection
              className={cx(
                'bg-white py-3 mb-3 z-index-1 d-flex align-items-center justify-content-center'
              )}
            >
              <AlloyButton
                type="submit"
                className={cx('mt-0')}
                buttonType="secondary"
              >
                {t('CREATE_AND_SAVE')}
              </AlloyButton>
            </StickySection>
          </Form>
        </Formik>
      </>
    </div>
  );
};

CreateListForm.propTypes = {
  close: PropTypes.func,
  isCreateListOnly: PropTypes.bool,
  createSavedListData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.string,
        partNumber: PropTypes.string
      })
    ),
    saveType: PropTypes.string
  }),
  fromPDPPage: PropTypes.bool
};

export default CreateListForm;
