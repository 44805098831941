/**
 * Description: Inserts 'webapp/wcs/stores/servlet',to the beginning of each link. It can be accomplished by running npm run build:dev
 * eg: BUNDLES_LEARNMORE_LINK will run as 'webapp/wcs/stores/servlet/{0}/self-service-options' in local
 * INCLUDE only STATIC links that require prepending 'webapp/wcs/stores/servlet'
 */
import { isDev, normalizeLinks } from '@app/utils';
import { BFF_ENDPOINT_HOST } from './commonConstants';

const links = {
  ADDRESS_BOOK:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&route=AddressBook&storeId={2}',
  BUNDLES_LEARNMORE_LINK: `{0}/self-service-options`,
  CONTACT_DEALER_LINK: `/CATContactUsDisplayView?catalogId={0}&langId={1}&storeId={2}`,
  CORE_TRACKING_URL:
    '/AjaxLogonForm?route=coreTracking&catalogId={0}&langId={1}&storeId={2}',
  COOKIE_PAGE_LINK: `/CookieErrorView?catalogId={0}&langId={1}&storeId={2}&fromLoginCmd=Y`,
  CSR_LANDING_PAGE:
    '/CustomerServiceLandingPageView?catalogId={0}&langId={1}&storeId={2}',
  EDIT_EMAIL: `/b2cPreProfileUpdate?catalogId={0}&b2cEmailUpdate=true&storeId={1}&langId={2}&route={3}`,
  EDIT_PASSWORD: `/b2cPreProfileUpdate?catalogId={0}&b2cPasswordUpdate=true&storeId={1}&langId={2}&route={3}`,
  EDIT_MFA_SETTINGS: `/b2cPreProfileUpdate?catalogId={0}&b2cMFAUpdate=true&storeId={1}&langId={2}&route={3}`,
  INVOICES_URL:
    '/AjaxLogonForm?route=Invoices&catalogId={0}&langId={1}&storeId={2}',
  MA_ORDER_HISTORY_URL:
    '/AjaxLogonForm?showOrderHeader=false&route=OrderHistory&catalogId={0}&langId={1}&storeId={2}&orderStatusStyle=strong',
  MA_SUMMARY_URL:
    '/AjaxLogonForm?myAcctMain=1&catalogId={0}&langId={1}&storeId={2}',
  MANUAL_APPROVAL_LINK: `/AjaxLogonForm?catalogId={0}&langId={1}&storeId={2}&manualApprovalMessage=true`,
  NOTIFICATION_PREFERENCES_URL:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&route=notificationPreferences&storeId={2}',
  PAYMENT_METHODS_URL:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&route=PaymentMethods&storeId={2}',
  CAT_VANTAGE_REWARDS_URL:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&route=CatVantageRewards&storeId={2}',
  ORDER_AND_QUOTE_CONFIRMATION_PAGE:
    '/OrderShippingBillingConfirmationView?shipmentTypeId=1&quoteOrderFlow=true&storeId={0}&catalogId={1}&langId={2}&orderId={3}&isQuoteOrder={4}',
  PENDING_QUOTES_URL:
    '/AjaxLogonForm?selfPQ=true&catalogId={0}&myAcctMain=1&langId={1}&route=PendingQuotes&storeId={2}',
  PENDING_QUOTES_DETAILS:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&route=PendingQuoteDetails&storeId={2}&orderId={3}',
  PLACEHOLDER_LINK: '/{0}?catalogId={1}&langId={2}&storeId={3}',
  PMKIT_SSRO_BUNDLE_PAGE:
    '/selfServiceOptions?storeId={0}&catalogId={1}&langId={2}&ssId={3}&isPM={4}&serialNumber={5}&salesModel={6}&serialNoBegin={7}&serialNoEnd={8}&prefix={9}&unit={10}&isCATKit={11}',
  PMKIT_EXPORT_DOC_URL:
    '/CatPMKitExportCmd?ssId={0}&serialNumber={1}&model={2}&serialNoBegin={3}&serialNoEnd={4}&unit={5}&isPM={6}&isCATKit={7}&langId={8}&catalogId={9}',
  PMKIT_LEARNMORE_LINK: `{0}/plannedmaintenancekits`,
  ORDER_HISTORY_URL:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&route=OrderHistory&storeId={2}',
  ORDERS_TO_APPROVE_URL:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&route=OrdersToApprove&storeId={2}',
  ORGANIZATIONS_AND_USERS:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&route=OrganizationsAndUsers&storeId={2}',
  SAVED_LISTS_URL:
    '/AjaxLogonForm?myAcctMain=1&route=RequisitionListdisplayView&catalogId={0}&langId={1}&storeId={2}',
  SAVED_LIST_DETAIL_URL: `/AjaxLogonForm?catalogId={0}&langId={1}&storeId={2}&editable=true&createdBy={3}&requisitionListId={4}&requisitionListName={5}&route=RequisitionListDetailView`,
  SAVED_LISTS_DETAILS_PAGE_URL: `/AjaxLogonForm?catalogId={0}&langId={1}&storeId={2}&editable=true&createdBy={3}&requisitionListId={4}`,
  // TODO: What is the real difference between SHOPPING_CART and SHOPPING_CART_LINK?
  SHOPPING_CART: `/RESTOrderCalculate?updatePrices=1&doConfigurationValidation=Y&errorViewName=AjaxOrderItemDisplayView&catalogId={0}&langId={1}&URL={2}`,
  SHOPPING_CART_LINK: `/AjaxOrderItemDisplayView?catalogId={2}&langId={1}`,
  SHOPPING_CART_URL_PARAMETER: `/AjaxOrderItemDisplayView&storeId={0}&orderId=.`,
  SHOPPING_PREFERENCES_URL:
    '/AjaxLogonForm?myAcctMain=1&route=ShoppingPreferences&catalogId={0}&storeId={1}&langId={2}',
  SIS_EQUIP_LIST_URL:
    '/AjaxLogonForm?myAcctMain=1&route=MyEquipment&catalogId={0}&langId={1}&storeId={2}',
  SIS_REDIRECT_URL: '/CATSISRedirect?&storeId={0}&langId={1}&catalogId={2}',
  SUBHEADER_SEARCH_LINK: `/SearchDisplay?catalogId={0}&langId={1}&storeId={2}&searchTerm={3}&categoryId=&sType=SimpleSearch&resultCatEntryType=2&showResultsPage=true&searchSource=Q&pageView=&beginIndex=0&pageSize=16&sortBy=0`,
  USER_REGISTRATION_FORM_LINK: `/UserRegistrationForm?catalogId={0}&editRegistration=Y&userRegistrationStyle=strong&storeId={1}&langId={2}`,
  PERSONAL_INFORMATION_LINK:
    '/AjaxLogonForm?route=PersonalInformation&catalogId={0}&langId={1}&storeId={2}',
  RECENTLY_VIEWED:
    '/SearchDisplay?catalogId={0}&langId={1}&storeId={2}&searchTerm={3}&brHist=true',
  RECENTLY_VIEWED_MODERNIZED_VIEW:
    '/SearchDisplay?catalogId={0}&langId={1}&storeId={2}&searchTerm={3}&searchType={4}',
  REQUEST_RETURN_URL:
    '/AjaxLogonForm?storeId={0}&catalogId={1}&langId={2}&orderNumber={3}&orderId={4}&route=OrderReturnRequest',
  MY_ACCOUNT_ORGANIZATION_USERS_LINK:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&route=OrganizationsAndUsersDetails&storeId={2}&userId={3}',
  MY_ACCOUNT_PENDING_ORDER_DETAILS: '/AjaxLogonForm?orderId={0}&route={1}',
  CONTROL_CENTER_URL:
    '/AjaxLogonForm?myAcctMain=1&catalogId={0}&langId={1}&storeId={2}&route=ControlCenter',
  CSR_ORDER_DETAIL_LINK:
    '/AjaxLogonForm?fromOrderHistorySearchPage=false&storeId={0}&langId={1}&orderId={2}&dbsOrderId={3}&route=OrderDetail',
  INVOICE_DETAIL_URL:
    '/AjaxLogonForm?catalogId={0}&langId={1}&storeId={2}&invoiceId={3}&route=InvoiceDetail',
  EXPORT_ORDER_STATUS:
    '/CATOrderDetailsExportCmd?storeId={0}&landId={1}&catalogId={2}&format={3}&fileName={4}&orderId={5}',
  TRANSACT_AS_GUEST: '/guestUserSetInSession',
  PRIVACY_POLICY_URL: '/en/catcorp/LegalNotices#privacy',
  ADVANCED_SEARCH:
    '/AdvancedSearchDisplay?catalogId={0}&storeId={1}&langId={2}',
  QUICK_ORDER: '/QuickOrderViewCmd?catalogId={0}&storeId={1}&langId={2}',
  ORDER_STATUS_LINK:
    '/AjaxLogonForm?catalogId={0}&langId={1}&myAcctMain=1&orderId={2}&route=OrderStatus&storeId={3}&dbsOrderId={4}',
  OMM_BOOK_PAGE:
    '/OmmDetailsView?storeId={0}&langId={1}&bookURI={2}&serialNumber={3}&salesModel={4}',
  PENDING_ORDER_DETAILS:
    '/AjaxLogonForm?orderId={0}&storeId={1}&langId={2}&catalogId={3}&route=PendingOrderDetails',
  SSRO_BUNDLE_PAGE:
    '/selfServiceOptions?storeId={0}&catalogId={1}&langId={2}&ssId={3}&isPM={4}&serialNumber={5}&salesModel={6}&unit={7}'
};

export default normalizeLinks(links);

const BFF_PREFIX = isDev() ? BFF_ENDPOINT_HOST : window.location.origin;

export const headlessLinks = {
  HEADLESS_CONTROL_CENTER: `${BFF_PREFIX}{0}/account/control-center`,
  HEADLESS_ADDRESS_BOOK: `${BFF_PREFIX}{0}/account/address-book`,
  HEADLESS_ORDER_STATUS_LINK: `${BFF_PREFIX}{0}/account/orders/{1}/status`,
  HEADLESS_ORDER_STATUS_INQUIRY_LINK: `${BFF_PREFIX}{0}/orders/inquiry`,
  HEADLESS_ORDER_CONFIRMATION_LINK: `${BFF_PREFIX}{0}/orders/{1}/confirmation`,
  HEADLESS_SHOPPING_CART_LINK: `${BFF_PREFIX}{0}/cart`,
  HEADLESS_QUICK_ORDER_LINK: `${BFF_PREFIX}{0}/cart?openQuickOrder=true`,
  HEADLESS_PARTS_DIAGRAM_LINK: `${BFF_PREFIX}{0}/parts-diagram`,
  HEADLESS_SESSION_EXPIRED_LINK: `${BFF_PREFIX}{0}/session-expired`,
  HEADLESS_SAVED_LISTS: `${BFF_PREFIX}{0}/account/saved-lists`,
  HEADLESS_SITE_MAP_LINK: `${BFF_PREFIX}{0}/site-map`,
  HEADLESS_WARRANTY_AND_RETURNS: `${BFF_PREFIX}{0}/warranty-and-returns`,
  HEADLESS_MY_EQUIPMENT_LINK: `${BFF_PREFIX}{0}/my-equipment`,
  HEADLESS_ORDER_RETURN_LINK: `${BFF_PREFIX}{0}/account/orders/return/{1}`,
  HEADLESS_PARTS_DIAGRAM_REDIRECTION_LINK: `${BFF_PREFIX}{0}/parts-diagram?systemId={1}&componentId={2}&ieSystemControlNumber={3}`,
  HEADLESS_NOTIFICATION_PREFERENCES: `${BFF_PREFIX}{0}/account/notification-preferences`
};
