import { useCallback, useMemo } from 'react';
import { MyEquipmentToast } from '@cat-ecom/pcc-components';
import { useDispatch, useSelector } from 'react-redux';
import useLogin from '@app/components/login/hooks/useLogin';
import useRegister from '@app/components/login/hooks/useRegister';
import { useTranslation } from 'react-i18next';
import { CatButton } from '@blocks-react/components/Button';
import {
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import { CatHeading } from '@blocks-react/components/Heading';
import styles from './EquipmentToast.module.scss';
import { EQUIPMENT_METHOD } from '../constant';
import CapitalizeText from '../../../common/Text/CapitalizeText/CapitalizeText';
import { v4 as uuid } from 'uuid';
import { setEquipmentToast } from '@app/store/myequipment/actions';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import { clearError } from '@app/store/exception/actions';
import { replaceTokensInUrl } from '@app/utils';
import links from '@app/constants/links';

const EquipmentToast = () => {
  const [t] = useTranslation();
  const { login } = useLogin();
  const { register } = useRegister();
  const dispatch = useDispatch();
  const userType = useSelector(state => state.common.userType);
  const isGuestUser = userType === USER_TYPE_GUEST;
  const { equipmentSaveMethod, equipmentSaveMessage } = useSelector(
    s => s.myEquipment
  );
  const initialLoading = useSelector(s => s.myEquipment.initialLoading);

  const showDealerNotificationError = useSelector(
    state => state?.errors?.myEquipment?.dealerNotification?.message
  );
  const catalogId = useSelector(state => state.common.catalogId);
  const storeId = useSelector(state => state.common.storeId);
  const langId = useSelector(state => state.common.langId);
  const dealerNotificationErrorMessage = t(`${showDealerNotificationError}`);
  const stringified = sessionStorage.getItem('pendingToast');

  const pendingToast = useMemo(() => {
    return stringified ? JSON.parse(stringified) : {};
  }, [stringified]);

  const isSessionMethodAllowed = (method = null) => {
    if (pendingToast.method) {
      if (Array.isArray(pendingToast.method)) {
        const messageIndex = pendingToast.method.indexOf(method);
        return pendingToast.message[messageIndex];
      } else if (pendingToast.method === method) {
        return pendingToast.message;
      }
      return pendingToast.method === method;
    }
    return false;
  };

  const successSessionMessage = isSessionMethodAllowed('createAddToast');
  const errorSessionMessage = isSessionMethodAllowed('createErrorToast');

  const errorToastMessage =
    dealerNotificationErrorMessage || t(`${errorSessionMessage}`);
  const successToastMessage = equipmentSaveMessage || successSessionMessage;
  const showToast = useMemo(() => {
    if (!initialLoading) {
      return successToastMessage || errorToastMessage;
    }
  }, [initialLoading, equipmentSaveMessage, dealerNotificationErrorMessage]);

  const showButton = pendingToast.guestAddEquipment
    ? pendingToast.guestAddEquipment
    : isGuestUser;

  const getGuestContent = useMemo(() => {
    if (showButton) {
      return (
        <div>
          <CatHeading variant="body-sm">{t('GUEST_EQUIPMENT_SAVE')}</CatHeading>
          <div className="mt-2">
            <CatButton size={BUTTON_SIZES.SM} onClick={login}>
              <CapitalizeText text={t('SIGN_IN')} />
            </CatButton>
            <CatButton
              variant={BUTTON_VARIANTS.GHOST}
              size={BUTTON_SIZES.SM}
              onClick={register}
            >
              {t('REGISTER')}
            </CatButton>
          </div>
        </div>
      );
    }
  }, [login, register, showButton, t]);

  const handleToastClose = useCallback(() => {
    sessionStorage.removeItem('pendingToast');
    dispatch(setEquipmentToast());
    if (showDealerNotificationError) {
      dispatch(
        clearError(ERROR_DOMAIN.MY_EQUIPMENT, ERROR_PATH.DEALER_NOTIFICATION)
      );
    }
  }, [dispatch, showDealerNotificationError]);

  const handleContactUsClick = useCallback(
    e => {
      e.stopPropagation();
      if (catalogId && storeId && langId) {
        window.location.href = replaceTokensInUrl(
          links.CONTACT_DEALER_LINK,
          catalogId,
          langId,
          storeId
        );
      }
    },

    [catalogId, langId, storeId]
  );

  const getToastContent = useMemo(() => {
    let toastArray = [];
    if (
      equipmentSaveMethod === EQUIPMENT_METHOD.UPDATE ||
      equipmentSaveMethod === EQUIPMENT_METHOD.CREATE ||
      equipmentSaveMethod === EQUIPMENT_METHOD.SELECT ||
      pendingToast.guestAddEquipment
    ) {
      toastArray.push({
        key: uuid(),
        variant: 'success',
        content: (
          <div className="d-flex flex-column">
            <CatHeading data-testid="cat-heading-success" variant="label">
              {successToastMessage}
            </CatHeading>
            {getGuestContent}
          </div>
        )
      });
    } else {
      toastArray.push({
        key: uuid(),
        variant: 'success',
        content: (
          <CatHeading data-testid="cat-heading-success" variant="label">
            {successToastMessage}
          </CatHeading>
        )
      });
    }

    if (errorToastMessage) {
      toastArray.push({
        key: uuid(),
        variant: 'error',
        content: (
          <div className={'d-flex flex-column'}>
            <CatHeading
              data-testid="cat-heading-error"
              variant="label"
              className={`${styles['toast-heading']}`}
            >
              {errorToastMessage}
            </CatHeading>
            <CatButton
              size={BUTTON_SIZES.SM}
              className="mt-2"
              onClick={handleContactUsClick}
            >
              {t('CONTACT_YOUR_DEALER')}
            </CatButton>
          </div>
        )
      });
    }

    return toastArray;
  }, [
    equipmentSaveMethod,
    pendingToast.guestAddEquipment,
    errorToastMessage,
    successToastMessage,
    getGuestContent,
    handleContactUsClick,
    t
  ]);

  return (
    <>
      {showToast ? (
        <MyEquipmentToast
          isGuestUser={true}
          classname={styles['success-toast-container__width']}
          onToastClose={handleToastClose}
          toasts={getToastContent}
          delay={5}
        />
      ) : null}
    </>
  );
};

export default EquipmentToast;
