import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CatPopover } from '@blocks-react/components/Popover';
import { CatButton } from '@blocks-react/components/Button';
import { CatTextPassage } from '@blocks-react/components/TextPassage';
import styles from './PCCSalesforceChatWidget.module.scss';
import { useTranslation } from 'react-i18next';
import { getCookie, isConsentTypeAvailable } from '@app/utils';
import { ONETRUST } from '@app/constants/commonConstants';
import { OPTANON_ALERT_BOX_CLOSED } from '@app/constants/cookieContants';

const PCCSalesForceChatWidget = () => {
  const SESSION_STORAGE_KEY_CHAT_DISABLED_POPOVER =
    'IS_CHAT_DISABLED_POPOVER_CLOSED';

  const [t] = useTranslation();
  const salesforceChatPopoverRef = useRef(null);
  const reloadCookiesBannerButton = useRef(null);
  const [isOpen, setIsOpen] = useState(true);
  const [isCookiesBannerOpen, setIsCookiesBannerOpen] = useState(
    getCookie(OPTANON_ALERT_BOX_CLOSED) === null
  );
  const CATCsr = useSelector(s => s.common?.isCatCSR);
  const PCCuser = useSelector(s => s.common?.isCSPCustomer);
  const isBuyOnBehalf = useSelector(s => s.common?.isBuyOnBehalf) || false;
  const PCCSalesForceChat = useSelector(s => s.featureFlag?.PCCSalesForceChat);
  const acceptedFunctionalCookieExists = isConsentTypeAvailable(
    ONETRUST.FUNCTIONAL_COOKIES_ACCEPTED
  );
  const rejectedFunctionalCookieExists = isConsentTypeAvailable(
    ONETRUST.FUNCTIONAL_COOKIES_REJECTED
  );
  const isFunctionalCookieRejected =
    !acceptedFunctionalCookieExists &&
    rejectedFunctionalCookieExists &&
    !isCookiesBannerOpen;

  const chatDisabledPopoverSessionStorageItem = sessionStorage.getItem(
    SESSION_STORAGE_KEY_CHAT_DISABLED_POPOVER
  );
  const wasChatDisabledPopoverClosed =
    chatDisabledPopoverSessionStorageItem &&
    (chatDisabledPopoverSessionStorageItem === true ||
      chatDisabledPopoverSessionStorageItem === 'true');

  const handleOpenPopover = () => {
    if (salesforceChatPopoverRef?.current) {
      salesforceChatPopoverRef.current.open();
      if (isOpen) {
        sessionStorage.removeItem(SESSION_STORAGE_KEY_CHAT_DISABLED_POPOVER);
      }
    }
  };

  const handleClosePopover = () => {
    if (salesforceChatPopoverRef?.current) {
      setIsOpen(false);
      salesforceChatPopoverRef.current.close();
      sessionStorage.setItem(SESSION_STORAGE_KEY_CHAT_DISABLED_POPOVER, true);
    }
  };

  const handleCookieSettingsPopover = () => {
    reloadCookiesBannerButton.current.click();
    setIsOpen(false);
    sessionStorage.setItem(SESSION_STORAGE_KEY_CHAT_DISABLED_POPOVER, true);
  };

  // ✨ User Story - 1218429
  useEffect(() => {
    let intervalId;

    // Do the check only when the popover is opened.
    if (isOpen) {
      intervalId = setInterval(() => {
        const ele = document.querySelector('.otPcPanel');

        if (ele) {
          // Check if the cookies-settings drawer element is not in viewport.
          // Doing this is because, the cookies-settings element will always be there in the DOM.
          const computedStyle = getComputedStyle(ele);
          const isInViewport = computedStyle.display === 'none';

          // Make the chat-disabled popover close, when the cookies-settings is visible in the viewport.
          if (!isInViewport && isOpen && salesforceChatPopoverRef.current) {
            handleClosePopover();
          }

          // Stop the interval check when the chat-disabled popover is closed.
          if (
            !isInViewport &&
            !isOpen &&
            salesforceChatPopoverRef.current === null
          ) {
            clearInterval(intervalId);
          }
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isOpen]);

  useEffect(() => {
    const checkCookies = e => {
      setTimeout(() => {
        setIsCookiesBannerOpen(getCookie(OPTANON_ALERT_BOX_CLOSED) === null);
      }, 2000);
    };
    window.addEventListener(
      ONETRUST.GROUP_UPDATED_EVENT_LISTENER,
      checkCookies
    );
    const handleClickOutsidePopover = event => {
      if (
        salesforceChatPopoverRef?.current &&
        !salesforceChatPopoverRef?.current.contains(event.target)
      ) {
        setIsOpen(true);
        handleOpenPopover();
      }
    };
    if (
      PCCSalesForceChat &&
      isFunctionalCookieRejected &&
      salesforceChatPopoverRef &&
      !wasChatDisabledPopoverClosed &&
      isOpen
    ) {
      handleOpenPopover();
      document.addEventListener('mousedown', handleClickOutsidePopover);
    } else if (salesforceChatPopoverRef) {
      handleClosePopover();
      document.removeEventListener('mousedowm', handleClickOutsidePopover);
    }
    return () => {
      window.removeEventListener(
        ONETRUST.GROUP_UPDATED_EVENT_LISTENER,
        checkCookies
      );
      document.removeEventListener('mousedowm', handleClickOutsidePopover);
    };
  }, [
    rejectedFunctionalCookieExists,
    acceptedFunctionalCookieExists,
    PCCSalesForceChat,
    salesforceChatPopoverRef,
    isFunctionalCookieRejected,
    isOpen
  ]);

  if (
    !PCCSalesForceChat ||
    wasChatDisabledPopoverClosed ||
    (PCCuser && isBuyOnBehalf) ||
    (PCCuser && acceptedFunctionalCookieExists) ||
    CATCsr
  ) {
    return null;
  }

  return (
    <>
      {/* Story #1172995: This hidden button is required because we need to trigger the cookies banner, and the only way is adding the class 'ot-sdk-show-settings', we couldn't added that class in the visible button because it stops the other required functions to be executed. */}
      <CatButton
        type="button"
        ref={reloadCookiesBannerButton}
        className="ot-sdk-show-settings d-none"
        variant="primary"
      >
        {t('COOKIE_SETTINGS')}
      </CatButton>
      {isFunctionalCookieRejected && isOpen && (
        <CatPopover
          ref={salesforceChatPopoverRef}
          heading={t('CHAT_IS_DISABLED')}
          headingVariant="title-sm"
          className={styles['pcc_salesforce_chat_widget']}
          position="top-end"
          onBlPopoverClose={() => handleClosePopover()}
        >
          <React.Fragment key=".0">
            <button
              onMouseOver={handleOpenPopover}
              onClick={handleOpenPopover}
              className={styles['pcc_salesforce_chat_widget__button']}
              disabled
              slot="trigger"
              type="button"
            ></button>
            <div>
              <CatTextPassage size="sm">
                <p className="cat-u-display-flex flex-column text-wrap mt-0 mb-3">
                  {t('ENABLE_COOKIES_FOR_CHAT')}
                </p>
              </CatTextPassage>
              <div className="d-flex gap-3">
                <CatButton
                  variant="primary"
                  onClick={handleCookieSettingsPopover}
                >
                  {t('COOKIE_SETTINGS')}
                </CatButton>
                <CatButton variant="ghost" onClick={handleClosePopover}>
                  {t('CLOSE')}
                </CatButton>
              </div>
            </div>
          </React.Fragment>
        </CatPopover>
      )}
    </>
  );
};

export default PCCSalesForceChatWidget;
