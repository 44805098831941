import {
  doublebyteChecker,
  getCookie,
  removeCookie,
  setCookie
} from '@app/utils';
import { v4 } from 'uuid';
import { debounce } from 'lodash';
import { zeroPad } from '@app/utils/stringUtils';
import { formatPartNumber } from '@app/store/checkout/utils';
import {
  GENERAL,
  QUICK_ORDER_SAVED_ITEMS
} from '@app/constants/cookieContants';
import { FORMIK_INITAL_VALUES } from '../ShoppingCartPage/constants';
import { SIS_PARTS_LIST } from '@app/components/login/redirect/constants';
import { SIS_TO_CART_FALLBACK } from '../OrderItems/constants';
const { Validator } = require('cat-ecommerce-alloy');

export const quantityValidator = ({
  value,
  setValue,
  rowNum,
  touched,
  items,
  errorMessage,
  validateDoubleByte,
  doubleByteErrorMessage
}) => {
  const itemNumber = items[rowNum].itemNumber;
  const rowTouched = touched.items?.[rowNum];
  const parsedValue = parseFloat(value);

  if (rowTouched && itemNumber && (!value || parsedValue === 0)) {
    const handleValidation = () => {
      if (parsedValue === 0) {
        return errorMessage;
      }

      if (validateDoubleByte && doublebyteChecker(value)) {
        return doubleByteErrorMessage;
      }

      return;
    };
    const validator = new Validator(value)
      .isNumeric(errorMessage)
      .required(errorMessage)
      .isValidQuantity(handleValidation);
    return validator.getError();
  }
};

export const notesValidator = ({
  value,
  __,
  rowNum,
  touched,
  items,
  doubleByteErrorMessage,
  validateDoubleByte
}) => {
  const handleCharacterValidation = () => {
    if (validateDoubleByte && doublebyteChecker(value)) {
      return doubleByteErrorMessage;
    }
  };

  if (validateDoubleByte) {
    const validator = new Validator(value).isValidQuantity(
      handleCharacterValidation
    );

    return validator.getError();
  }
};

export const itemNumberValidator = ({
  value,
  setValue,
  rowNum,
  touched,
  items,
  requiredErrorMessage,
  invalidCharacterErrorMessage,
  validateDoubleByte,
  doubleByteErrorMessage
}) => {
  const quantity = items[rowNum].quantity;
  const rowTouched = touched.items?.[rowNum];

  if (rowTouched && !value && quantity) {
    const validator = new Validator(value).required(requiredErrorMessage);
    return validator.getError();
  }

  //Validates double byte character
  if (
    rowTouched &&
    value &&
    validateDoubleByte &&
    doublebyteChecker(value) &&
    doubleByteErrorMessage
  ) {
    return doubleByteErrorMessage;
  }

  if (
    rowTouched &&
    value &&
    !/^[a-zA-Z0-9 !\#$()*+,-./:;=?{}_`\"&]+$/.test(value)
  ) {
    return invalidCharacterErrorMessage;
  }
};

export const removeQuickOrderCookie = () =>
  removeCookie(QUICK_ORDER_SAVED_ITEMS);
/**
 * util to get quick order item saved in cookie
 * @param {Item[]} items item object used in quick order
 * @param {string} userId id of current user
 */
export const saveQOLineItemsToCookie = (items, userId) => {
  const cookieName = QUICK_ORDER_SAVED_ITEMS;
  removeCookie(cookieName);
  const cookieValue = items.reduce((acc, item) => {
    const {
      quantity = '',
      itemNumber = '',
      assetId = '',
      lineItemNote = '',
      customerPartNumber = '',
      customerItemNumber = '',
      page = ''
    } = item;
    if (
      quantity ||
      itemNumber ||
      assetId ||
      lineItemNote ||
      customerPartNumber ||
      customerItemNumber
    ) {
      return `${acc}${quantity.trim()}~${itemNumber.trim()}~${assetId.trim()}~${lineItemNote.trim()}~${customerPartNumber.trim()}~${customerItemNumber.trim()}~${page}||`;
    }
    return acc;
  }, `${userId};`);
  setCookie(cookieName, encodeURIComponent(cookieValue), 3);
};

/**
 * util to get quick order item saved in cookie
 * @param {string} currentUserId id of current user
 * @returns false or and array Item object used in quick order form
 */
export const getItemsFromQuickOrderCookie = currentUserId => {
  const cookieValue = decodeURIComponent(
    getCookie(QUICK_ORDER_SAVED_ITEMS) || ''
  );
  const [userId, itemsValue] = cookieValue.split(/;(.*)/s);
  if (userId !== currentUserId) {
    return false;
  }
  const rows = itemsValue.split('||');
  return rows.reduce(
    (acc, row = '', index) => {
      const values = row.split('~');
      acc[index] = {
        quantity: values[0] || '',
        itemNumber: values[1] || '',
        assetId: values[2] || '',
        lineItemNote: values[3] || '',
        customerPartNumber: values[4] || '',
        customerItemNumber: values[5] || '',
        page: values[6] || GENERAL,
        id: v4()
      };
      return acc;
    },
    [...FORMIK_INITAL_VALUES.items]
  );
};

/**
 * This is quick workaround to handle the SIS to Cart Mobile flow. Will clean this up as part of story 489544
 * util to get quick order item saved in cookie
 * @param {string} currentUserId id of current user
 * @returns false or and array Item object used in quick order form
 */
export const getItemsFromSisCookie = currentUserId => {
  const cookieValue = decodeURIComponent(getCookie(SIS_PARTS_LIST) || '');
  const rows = cookieValue.split(',');
  const sisCookieRows = rows.reduce(
    (acc, row = '', index) => {
      const values = row.split(':');
      acc[index] = {
        quantity: values[1] || '',
        itemNumber: values[0] || '',
        assetId: values[2] || '',
        lineItemNote: values[3] || '',
        customerPartNumber: values[4] || '',
        customerItemNumber: values[5] || '',
        id: v4()
      };
      return acc;
    },
    [...FORMIK_INITAL_VALUES.items]
  );
  const qoCookieRows = getItemsFromQuickOrderCookie(currentUserId);
  const filterSisCookieRows = sisCookieRows.filter(
    val => val.itemNumber && val.quantity
  );
  return [...filterSisCookieRows, ...qoCookieRows];
};

export const updateQuickOrderItemsCookie = debounce(
  (items, userId) => {
    const qoItems = items.filter(item => item.itemNumber !== '');
    saveQOLineItemsToCookie(qoItems, userId);
  },
  300,
  { leading: true, trailing: true }
);

export const setInvalidPartsErrors = (errors, values, setErrors, t) => {
  const formikItemErrors = values.items.reduce((acc, item, i) => {
    const hasRowError = Object.keys(errors).some(
      key =>
        formatPartNumber(key) === formatPartNumber(item.itemNumber) &&
        errors[key].itemNumber
    );
    if (hasRowError) {
      acc[i] = { itemNumber: t('INVALID_PART_NUMBER') };
    }
    return acc;
  }, []);
  setErrors({ items: formikItemErrors });
};

const errorMessages = {
  UPC5017: 'INVALID_PART_NUMBER',
  UPC5053: 'INVALID_PART_NUMBER',
  UPC5018: 'QO_ERROR_UPC_5018'
};

export const setSOSPartsErrors = (errors, values, setErrors, t) => {
  const formikItemErrors = values.items.reduce((acc, item, i) => {
    const rowError = errors[item.id];
    if (rowError) {
      acc[i] = {
        itemNumber: t(errorMessages[rowError.errorCode])
      };
    }
    return acc;
  }, []);
  setErrors({
    items: formikItemErrors
  });
};

export const setInvalidQuantityErrors = (errors, values, setErrors, t) => {
  const formikItemErrors = values.items.reduce((acc, item, i) => {
    const hasRowQuantityError =
      errors.includes(item.referenceId) || errors.includes(item.id);
    if (hasRowQuantityError) {
      acc[i] = { quantity: t('SKULIST_INVALID_QUANTITY') };
    }
    return acc;
  }, []);
  setErrors({ items: formikItemErrors });
};

export const formatZeros = index => zeroPad(index, 3);

export const numOfDaggers = isQuantityMergeEnabledFlag => {
  return !isQuantityMergeEnabledFlag ? (
    <sup>&dagger;&dagger;</sup>
  ) : (
    <sup>&dagger;</sup>
  );
};

export const mergeRepeatedQOValuesAndValidate = (
  items,
  validateItemQuantity
) => {
  const mergedItemQuantities = items.reduce((acc, item) => {
    if (item.partNumber) {
      const formattedPartNumber = formatPartNumber(item.partNumber);
      if (!acc[formattedPartNumber]) {
        acc[formattedPartNumber] = {
          requestedQuantity: 0,
          referenceId: [],
          partNumber: formattedPartNumber
        };
      }
      acc[formattedPartNumber] = {
        ...acc[formattedPartNumber],
        requestedQuantity:
          acc[formattedPartNumber].requestedQuantity + +item.requestedQuantity,
        referenceId: [...acc[formattedPartNumber].referenceId, item.referenceId]
      };
    }

    return acc;
  }, []);
  const errorReferenceIds = Object.keys(mergedItemQuantities).reduce(
    (acc, key) => {
      if (
        validateItemQuantity(
          mergedItemQuantities[key],
          mergedItemQuantities[key].requestedQuantity
        )
      ) {
        acc = acc.concat(mergedItemQuantities[key].referenceId);
      }
      return acc;
    },
    []
  );
  return errorReferenceIds;
};
export const getItemsFromSisFallbackCookie = currentUserId => {
  const cookieValue = decodeURIComponent(getCookie(SIS_TO_CART_FALLBACK) || '');
  const rows = cookieValue.split(',');
  const sisCookieRows = rows.reduce(
    (acc, row = '', index) => {
      const values = row.split(':');
      acc[index] = {
        quantity: values[1] || '',
        itemNumber: values[0] || '',
        assetId: values[2] || '',
        lineItemNote: values[3] || '',
        customerPartNumber: values[4] || '',
        customerItemNumber: values[5] || '',
        id: v4()
      };
      return acc;
    },
    [...FORMIK_INITAL_VALUES.items]
  );
  const qoCookieRows = getItemsFromQuickOrderCookie(currentUserId);
  const filterSisCookieRows = sisCookieRows.filter(
    val => val.itemNumber && val.quantity
  );
  return [...filterSisCookieRows, ...qoCookieRows];
};
export const updateSisFallbackCookie = items => {
  const cookieName = SIS_TO_CART_FALLBACK;
  removeCookie(cookieName);
  const cookieValue = items.reduce((acc, item) => {
    const { quantity = '', itemNumber = '', assetId = '' } = item;
    if (quantity || itemNumber || assetId) {
      return `${itemNumber.trim()}:${quantity.trim()}:${assetId.trim()},${acc}`;
    }
    return acc;
  }, []);
  const cookieValueFilter = cookieValue.replace(/,\s*$/, '');
  setCookie(cookieName, encodeURIComponent(cookieValueFilter), 3);
};
