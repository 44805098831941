import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Drawer,
  DealerLocatorHeader,
  DealerLocatorForm,
  DealerLocatorSearchResults,
  DealerLocatorSearchResult,
  DrawerBody,
  DrawerFooter
} from '@cat-ecom/pcc-components';
import DealerStoreDetails from './DealerStoreDetails/DealerStoreDetails';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getSessionStorage, setSessionStorage } from '@app/utils';
import { useSelector } from 'react-redux';
import Conditional from '../Conditional';
import { getFilteredAndOrderedDealerStores } from '@app/components/dealer-locator-react/utils';
import { CatIconWarningTriangle } from '@blocks-react/components/Icons/WarningTriangle';
import DealerOtherStoreAvailability from './DealerOtherStoreAvailability/DealerOtherStoreAvailability';
import { CatButton } from '@blocks-react/components/Button';
import styles from './DealerOtherBranchesDrawer.scss';
import useSelectStore from '@app/components/dealer-locator/hooks/useSelectStore';
import { CONSENT_SESSION_KEY, PART_QTY } from '@app/constants/commonConstants';
import ErrorWarning from '../Blocks/ErrorWarning';
import { SELECTED_STORE_ID } from '@app/constants/dealerLocatorConstants';

const DealerOtherBranchesModal = ({ drawer }) => {
  const [t] = useTranslation();

  const isConsentSelected =
    getSessionStorage(CONSENT_SESSION_KEY) &&
    JSON.parse(getSessionStorage(CONSENT_SESSION_KEY));

  const { getSelectStore } = useSelectStore();
  const storeFormMethods = useForm();
  const selectedStoreUniqueId = storeFormMethods.watch('selectedStoreId');
  const otherStores = useSelector(s => s.store?.otherStores);
  const current = useSelector(s => s.products?.current);
  const partNumber = useSelector(s => s.products?.byId?.[current]?.partNumber);
  const quantity = useSelector(
    s => s.products?.priceAndAvailability?.[partNumber]?.quantity
  );
  const dealerAssociation = useSelector(
    state => state?.dealerAssociation?.dealerAssociations
  );

  const [selectStoresError, setSelectStoresError] = useState(false);

  const getOtherStoreAvailability = store => (
    <DealerOtherStoreAvailability store={store} />
  );
  const { storeId, selectedStore } = useSelector(s => s.common);
  const isDefaultStoreSelected =
    selectedStoreUniqueId === storeId + selectedStore;
  const { filteredAndOrderedVisibleDealerStores } = useMemo(
    () =>
      getFilteredAndOrderedDealerStores(
        otherStores?.dealerStores?.slice(0, 6),
        null,
        6,
        true
      ), //Slicing First 5 array to display in the drawer
    [otherStores?.dealerStores]
  );

  const {
    id: selectedLocationId,
    storeId: dealerStoreId,
    seoURL
  } = filteredAndOrderedVisibleDealerStores.find(
    s => s.id === selectedStoreUniqueId
  ) || {};

  const showStoresList = !!filteredAndOrderedVisibleDealerStores[0];

  // This useEffect is to reset the error state to false when user tries to select a Different Store Location
  useEffect(() => {
    setSelectStoresError(false);
  }, [selectedStoreUniqueId]);

  const { isTermsConditionAccepted } =
    dealerAssociation?.find(fnd => fnd.value === dealerStoreId) || {};

  const selectOtherBranchHandler = () => {
    if (!!selectedLocationId) {
      const body = {
        isRegistration: false,
        fromEspot: false,
        locationId: selectedLocationId,
        storeId: dealerStoreId,
        pageURL: seoURL,
        consent: !!isConsentSelected || !!isTermsConditionAccepted,
        bobRedirect: null
      };
      getSelectStore(body, dealerStoreId)
        .then(data => {
          if (data) {
            setSessionStorage(
              PART_QTY,
              `${partNumber.toLowerCase()}_${quantity}`
            );
            drawer.handleClose();
            window.location.reload();
          }
        })
        .catch(() => {
          setSelectStoresError(true);
        });
    }
  };
  const setFirstStoreAsDefault = useCallback(() => {
    storeFormMethods.setValue(
      SELECTED_STORE_ID,
      filteredAndOrderedVisibleDealerStores[0]?.id
    );
  }, [storeFormMethods, filteredAndOrderedVisibleDealerStores]);

  // set the first store as default if the user never changed default radio selection and the first store in the list changes due to numDisplayed increasing
  useEffect(() => {
    if (showStoresList && drawer.isDrawerOpen) {
      setFirstStoreAsDefault();
    }
  }, [showStoresList, drawer.isDrawerOpen]);
  return (
    <FormProvider {...storeFormMethods}>
      <DealerLocatorForm>
        <Drawer
          className="color-black"
          {...drawer.drawerProps}
          disableBackdropClick={drawer.drawerProps.disableBackdropClick}
          hideCloseButton={drawer.drawerProps.hideCloseButton}
          hasBackdrop={drawer.drawerProps.hasBackdrop}
        >
          <DealerLocatorHeader
            title={t('AVAILABLE_AT_OTHER_LOCATIONS')}
          ></DealerLocatorHeader>
          {selectStoresError && (
            <div className={`${styles['drawer_selectStore_error']}`}>
              <ErrorWarning
                errorTitle={t('SORRY_ERROR_OCCURRED')}
                showContactUsButton={false}
              />
            </div>
          )}

          <DrawerBody className={`${styles['drawer_body_class']}`}>
            <Conditional test={showStoresList}>
              <DealerLocatorSearchResults
                visibleStores={filteredAndOrderedVisibleDealerStores}
              >
                {({ store }) => {
                  const { id } = store;
                  return (
                    <DealerLocatorSearchResult
                      key={id}
                      unitOfMeasure={otherStores?.uom || 'mi'}
                      closestLabel={t('FYD_RESULTS_CLOSEST')}
                      store={store}
                      showAvailabilityBTNAndMsg={true}
                      availabilityBtnAndMsg={getOtherStoreAvailability(store)}
                    >
                      <DealerStoreDetails store={store} />
                    </DealerLocatorSearchResult>
                  );
                }}
              </DealerLocatorSearchResults>
            </Conditional>
          </DrawerBody>
          <DrawerFooter>
            <span className="d-flex ">
              <CatIconWarningTriangle
                className={`${styles['drawer_warning_icon']}`}
                size="lg"
                data-testid="warning-icon"
              />

              <p className="u-fs--12 u-color--ExtraDarkGray ps-2">
                {t('AVAILABLE_DISCLAIMER_STORE_LOCATION')}
              </p>
            </span>
            <CatButton
              type="button"
              variant="primary"
              onClick={storeFormMethods.handleSubmit(selectOtherBranchHandler)}
              disabled={isDefaultStoreSelected || selectStoresError}
            >
              {t('CAT_SELECT_STORE')}
            </CatButton>
            <span className="mx-3">
              <CatButton
                type="button"
                variant="default"
                onClick={drawer.handleClose}
              >
                {t('CANCEL')}
              </CatButton>
            </span>
          </DrawerFooter>
        </Drawer>
      </DealerLocatorForm>
    </FormProvider>
  );
};

DealerOtherBranchesModal.propTypes = {
  heading: PropTypes.string,
  drawer: PropTypes.shape({
    initiatorProps: PropTypes.shape({
      'aria-controls': PropTypes.string,
      'aria-expanded': PropTypes.bool,
      onClick: PropTypes.func
    }),
    drawerProps: PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      onBlClose: PropTypes.func,
      hasBackdrop: PropTypes.bool,
      hideCloseButton: PropTypes.bool,
      disableBackdropClick: PropTypes.bool,
      align: PropTypes.string
    }),
    drawerControl: PropTypes.shape({
      isActive: PropTypes.bool,
      open: PropTypes.func,
      close: PropTypes.func
    }),
    closeDrawer: PropTypes.func,
    handleOpen: PropTypes.func,
    handleClose: PropTypes.func
  })
};

export { DealerOtherBranchesModal };
