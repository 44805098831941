import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  LEGAL_PRIVACY_PAGE,
  PAGE_BREAKPOINTS
} from '@app/constants/commonConstants';
import { useBreakpoint, useEspot } from '@app/hooks';
import {
  getSessionStorage,
  replaceTokensInString,
  setSessionStorage
} from '@app/utils';
import {
  FooterLegalLinksContainer,
  FooterCopyright,
  FooterLegalLinks
} from '@cat-ecom/pcc-components';
import {
  DO_NOT_SELL_PERSONAL_INFO,
  DO_NOT_SELL_PERSONAL_INFO_LINK,
  MY_MARKETING_PREFERENCES_LINK
} from '../constants';
import {
  TERMS_CONDITIONS_FEATURE_FLAG,
  PRIVACY_POLICY_FLAG
} from '@app/constants/featureFlags';

const FooterLegalLinksRow = ({ isCheckout }) => {
  const { t } = useTranslation();

  const [isLegalPrivacyBtnClicked, setIsLegalPrivacyBtnClicked] = useState(
    !!getSessionStorage(LEGAL_PRIVACY_PAGE)
  );

  const espotName = 'CAT_FOOTER_ABOUTCAT_ESPOT';
  const { getEspotContent } = useEspot(espotName);
  const aboutCatLink = getEspotContent(espotName);

  const dealerTermsAndConditionsURL = useSelector(
    s => s.dealer?.dealerTermsAndConditionsURL
  );
  const catPrivacyOrTermsAndConditionsURL = useSelector(
    s => s.dealer?.catPrivacyOrTermsAndConditionsURL
  );
  const dealerPrivacyPolicyURL = useSelector(
    s => s.dealer?.dealerPrivacyPolicyURL
  );
  const dealerName = useSelector(s => s.dealer?.dealerName);
  const esiteURL = useSelector(s => s.common?.esiteURL);

  const privacyPolicyFlagValue = useSelector(
    s => s.featureFlag?.[PRIVACY_POLICY_FLAG]
  );
  const enableTermsConditionFlag = useSelector(
    s => s.featureFlag?.[TERMS_CONDITIONS_FEATURE_FLAG]
  );
  const isSiteMapHeadlessFlag = useSelector(
    s => s.featureFlag?.PCCHeadlessSiteMapFeature
  );
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);

  const handleRedirectLegalLink = (link, target = '_self') => {
    window.open(link, target);
    const newValue = !isLegalPrivacyBtnClicked;
    setIsLegalPrivacyBtnClicked(newValue);
    setSessionStorage(LEGAL_PRIVACY_PAGE, newValue);
  };

  const legalLinks = [
    {
      name: 'FOOTER_SITE_ABOUTCAT',
      url:
        aboutCatLink?.contents?.length > 0 && aboutCatLink?.contents[0]?.html,
      display: isMD,
      target: '_blank',
      label: replaceTokensInString(t('FOOTER_SITE_ABOUTCAT'), dealerName)
    },
    {
      name: 'FOOTER_SITE_MAP',
      url: `${esiteURL}/${isSiteMapHeadlessFlag ? 'site-map' : 'sitemap'}`,
      display: isMD,
      label: replaceTokensInString(t('FOOTER_SITE_MAP'), dealerName)
    },
    {
      name: 'MY_MARKETING_PREFERENCES',
      url: MY_MARKETING_PREFERENCES_LINK,
      display: true,
      label: t('MY_MARKETING_PREFERENCES'),
      target: '_black'
    },
    {
      name: 'COOKIE_SETTINGS',
      url: '#',
      display: true,
      label: replaceTokensInString(t('COOKIE_SETTINGS'), dealerName),
      className: 'ot-sdk-show-settings'
    },
    {
      name: 'LEGAL',
      url: `${catPrivacyOrTermsAndConditionsURL}#legal`,
      display: true,
      label: replaceTokensInString(t('LEGAL'), dealerName),
      target: privacyPolicyFlagValue ? '_blank' : '_self'
    },
    {
      name: 'UR_CAT_PRIVACY_NOTICE_HREF',
      url: `${catPrivacyOrTermsAndConditionsURL}#privacy`,
      display: true,
      label: replaceTokensInString(t('UR_CAT_PRIVACY_NOTICE_HREF'), dealerName),
      target: privacyPolicyFlagValue ? '_blank' : '_self'
    },
    {
      name: 'FOOTER_DEALER_TERMS_CONDS',
      url: dealerTermsAndConditionsURL,
      display: !!dealerName,
      label: replaceTokensInString(t('FOOTER_DEALER_TERMS_CONDS'), dealerName),
      target: enableTermsConditionFlag ? '_blank' : '_self'
    },
    {
      name: 'FOOTER_DEALER_PRIVACY',
      url: dealerPrivacyPolicyURL,
      display: !!dealerName,
      label: replaceTokensInString(t('FOOTER_DEALER_PRIVACY'), dealerName),
      target: privacyPolicyFlagValue ? '_blank' : '_self'
    },
    {
      name: DO_NOT_SELL_PERSONAL_INFO,
      url: DO_NOT_SELL_PERSONAL_INFO_LINK,
      display: true,
      target: '_blank',
      label: DO_NOT_SELL_PERSONAL_INFO
    }
  ];
  const copyrightText = replaceTokensInString(
    t('Footer_Copyright'),
    new Date().getFullYear()
  );

  return (
    <FooterLegalLinksContainer isCheckout={isCheckout}>
      <FooterLegalLinks
        legalLinks={legalLinks}
        handleRedirectLegalLink={handleRedirectLegalLink}
      />
      <FooterCopyright text={copyrightText} />
    </FooterLegalLinksContainer>
  );
};

FooterLegalLinksRow.propTypes = {
  isCheckout: PropTypes.bool
};

export default FooterLegalLinksRow;
