import Conditional from '@app/components/common/Conditional';
import { createPortal } from 'react-dom';
import { MARKETING_RIBBON } from '@app/constants/targets';
import {
  isEmpty,
  isPickupAndDeliveryPage,
  isSummaryAndPaymentPage,
  isCLP
} from '@app/utils';
import PropTypes from 'prop-types';
import { CMC_ESPOT_PCC_CATSAS_RIBBON_TOP } from '@app/store/common/constants';
import { lazy, useEffect, useState } from 'react';
import { useEspot } from '@app/hooks';
import { useSelector } from 'react-redux';

const Espot = lazy(
  () =>
    import(/* webpackChunkName: 'espot' */ '@app/components/common/Espot/Espot')
);

let node;
const app = MARKETING_RIBBON;

const MarketingRibbon = () => {
  const { getEspotContent } = useEspot(CMC_ESPOT_PCC_CATSAS_RIBBON_TOP);
  const marketingRibbonMessage = getEspotContent(
    CMC_ESPOT_PCC_CATSAS_RIBBON_TOP
  );
  const isCSRUser = useSelector(s => s.common.isCatCSR);
  const isPerticulerPage =
    !isCLP() &&
    !isPickupAndDeliveryPage() &&
    !isCSRUser &&
    !isSummaryAndPaymentPage() &&
    !isEmpty(marketingRibbonMessage);

  const [newNode, setNewNode] = useState(null);

  useEffect(() => {
    if (!document.getElementById(app)) {
      node = document.createElement('div');
      node.setAttribute('id', app);
      const pageNode = document.getElementById('page');
      pageNode && pageNode.prepend(node);
    } else {
      node = document.getElementById(app);
    }
    setNewNode(node);
  }, []);

  return (
    <>
      {newNode &&
        createPortal(
          <>
            <Conditional test={isPerticulerPage}>
              <Espot html={marketingRibbonMessage} isMarketingRibbon={true} />
            </Conditional>
          </>,
          newNode
        )}
    </>
  );
};

export default MarketingRibbon;
MarketingRibbon.propTypes = {
  getEspotContent: PropTypes.func
};
