import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CatIconWarningCircle } from '@blocks-react/components/Icons/WarningCircle';
import { CatInlineNotification } from '@blocks-react/components/InlineNotification';
import { handleDismissedSuccessInfo } from '@app/store/notificationHeader/action';

const NotificationDismissedSuccess = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleDismissClose = useCallback(() => {
    dispatch(handleDismissedSuccessInfo(false));
  }, []);

  return (
    <CatInlineNotification
      variant="success"
      heading={t('DISMISS_NOTIFICATIONS_TOAST')}
      dismissible
      data-testid="notification-dismissed-success-banner"
      close={handleDismissClose}
    >
      <CatIconWarningCircle slot="before" />
    </CatInlineNotification>
  );
};

export default NotificationDismissedSuccess;
