import * as types from './constants';
import * as ordersTypes from '../orders/constants';
import { format } from 'date-fns/format';
import { v4 } from 'uuid';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import { getStoreInfo } from '../../services/storeInfoService';
import { replaceTokensInUrl } from '@app/utils';
import { setError } from '../exception/actions';
import { normalizeError } from '../exception/utils';
import { ERROR_DOMAIN, ERROR_PATH } from '../../constants/errorConstants';
import { DEFAULT_ROW_VALUES } from '@app/components/pages/checkout/shopping-cart/ShoppingCartPage/constants';
import links from '@app/constants/links';
import { duplicateSavedList } from '@app/services/savedListService';

export const getFormattedDate = dateToFormat => {
  const date = new Date(dateToFormat);
  return format(date, 'MM/dd/yyyy');
};

//requisitionListId = orderId, in containter this is available as savedListId
export const getSavedList = requisitionListId => async dispatch => {
  dispatch({ type: types.SAVED_LIST_DETAIL_GET_BEGIN });
  const { storeId, langId } = getStoreInfo();
  const http = getHttpInstance();
  const url = replaceTokensInUrl(
    endpoints.SAVED_LIST_DETAIL_GET,
    storeId,
    langId,
    requisitionListId
  );
  try {
    const { data } = await http.get(url);
    const lastUpdate = data?.savedListInfo?.lastUpdate; //API returns a date in a different format, we format before dispatching
    let orderData = { ...data };
    if (lastUpdate) {
      const date = getFormattedDate(lastUpdate);
      orderData = { ...data, savedListDetailsLastUpdate: date };
    }
    dispatch({
      type: types.SAVED_LIST_DETAIL_GET_SUCCESS,
      payload: orderData ?? {}
    });
  } catch (error) {
    dispatch({
      type: types.SAVED_LIST_DETAIL_GET_FAIL,
      payload: error
    });
    dispatch(
      setError(ERROR_DOMAIN.SAVED_LIST, ERROR_PATH.MAIN, normalizeError(error))
    );
  }
};

export const updateStatus =
  ({ requisitionListId, formValues }, onSuccess, onError) =>
  async (dispatch, getState) => {
    const { storeId } = getStoreInfo();
    const http = getHttpInstance();
    dispatch({ type: ordersTypes.ORDERS_UPDATE_STATUS_BEGIN });

    const isCATCSR = getState().common.isCatCSR || false;
    let isCustomerSupportSharedCartListsEnabled =
      getState().featureFlag?.PCC_CustomerSupportSharedCartLists || false;

    const currentUserId = Number(getState().common.userId);
    const currentOrderCreateById = Number(
      getState().savedListDetailUI.orderCreatedBy
    );
    const isOwner = currentUserId === currentOrderCreateById;

    try {
      if (isCustomerSupportSharedCartListsEnabled && isCATCSR && !isOwner) {
        // Call BFF Copy & Delete
        const {
          common: { storeId, catalogId, langId, locale }
        } = getState();
        const cloneAndDeleteURL = replaceTokensInUrl(
          endpoints.SAVED_LIST_CSR_UPDATE,
          requisitionListId,
          storeId,
          locale
        );
        const { data: cloneResponse } = await http.post(
          cloneAndDeleteURL,
          null,
          { withCredentials: true }
        );
        const newShoppingListId = cloneResponse?.shoppingList.shoppingListId;

        const editNameURL = replaceTokensInUrl(
          endpoints.ORDERS_USER_INFORMATION,
          storeId,
          newShoppingListId
        );
        await http.request({
          method: 'put',
          url: editNameURL,
          data: { ...formValues },
          withCredentials: true
        });

        // Redirect
        const newShoppingListURL = replaceTokensInUrl(
          links.SAVED_LIST_DETAIL_URL,
          catalogId,
          langId,
          storeId,
          '',
          newShoppingListId,
          formValues.name
        );
        window.location.replace(newShoppingListURL);
      } else {
        // Regular update flow
        const url = replaceTokensInUrl(
          endpoints.ORDERS_USER_INFORMATION,
          storeId,
          requisitionListId
        );
        await http.request({
          url,
          method: 'put',
          data: { ...formValues }
        });
        dispatch({
          type: ordersTypes.ORDERS_UPDATE_STATUS_SUCCESS,
          payload: {
            orderStatus: formValues.status,
            orderId: requisitionListId
          }
        });
        onSuccess({ listName: formValues.name });
      }
    } catch (error) {
      dispatch({
        type: ordersTypes.ORDERS_UPDATE_STATUS_FAIL,
        payload: error
      });
      dispatch(
        setError(
          ERROR_DOMAIN.SAVED_LIST,
          ERROR_PATH.MODAL,
          normalizeError(error)
        )
      );
      onError();
    }
  };

export const updateQuantity =
  ({ currentId, listName, payload, partNumber }, onSuccess = null) =>
  async (dispatch, getState) => {
    const { storeId, catalogId, langId } = getStoreInfo();
    const http = getHttpInstance();
    const url = replaceTokensInUrl(
      endpoints.SAVED_LIST_DETAIL_UPDATE_QUANTITY,
      storeId
    );

    const dispatchError = () => {
      dispatch(
        setError(
          ERROR_DOMAIN.SAVED_LIST,
          ERROR_PATH.TABLE,
          normalizeError(error)
        )
      );
    };
    //service to duplicate self owned cart list
    const response = await duplicateSavedList(
      currentId,
      getState(),
      dispatchError,
      payload?.orderItemId,
      partNumber
    );

    if (response?.newListId) {
      const newListId = response.newListId;
      const orderItemId = response.orderItemId;
      const isDuplicated = newListId !== currentId;
      try {
        if (isDuplicated) {
          await http.request({
            url,
            method: 'post',
            data: {
              requisitionListId: newListId,
              quantity: payload.quantity,
              orderItemId,
              actionFrom: 'PCC'
            }
          });
          const redirectURL = replaceTokensInUrl(
            links.SAVED_LIST_DETAIL_URL,
            catalogId,
            langId,
            storeId,
            getState().common.csrName,
            newListId,
            listName,
            types.SAVED_LIST_DETAIL_PATH_STRING
          );
          window.location.replace(redirectURL);
        } else {
          //deleting an item hits the same endpoint in the same way, only with qty = 0
          //the action createor is reused
          const actionDeleteType = payload.quantity === '0';
          actionDeleteType &&
            dispatch({
              type: ordersTypes.SAVED_LIST_DELETE_LINE_ITEM_BEGIN,
              payload: {
                currentId: newListId,
                orderItemId
              }
            });
          dispatch({
            type: ordersTypes.SAVED_LIST_DETAIL_UPDATE_QUANTITY_BEGIN,
            payload: {
              currentId: newListId,
              orderItemId,
              latestQuantityValue: payload.quantity
            }
          });

          await http.request({
            url,
            method: 'post',
            data: {
              requisitionListId: newListId,
              quantity: payload.quantity,
              orderItemId,
              actionFrom: 'PCC'
            }
          });
          actionDeleteType
            ? setTimeout(() => {
                //Timeout allows animation to run
                dispatch({
                  type: ordersTypes.SAVED_LIST_DELETE_LINE_ITEM_SUCCESS,
                  payload: {
                    currentId: newListId,
                    orderItemId
                  }
                });
                onSuccess && onSuccess();
              }, 500)
            : dispatch({
                type: ordersTypes.SAVED_LIST_UPDATE_QUANTITY,
                payload: {
                  quantity: payload.quantity,
                  currentId: newListId,
                  orderItemId
                }
              });
        }
      } catch (error) {
        if (!isDuplicated) {
          actionDeleteType
            ? setTimeout(() => {
                dispatch({
                  type: ordersTypes.SAVED_LIST_DELETE_LINE_ITEM_FAIL,
                  payload: {
                    currentId: newListId,
                    orderItemId: orderItemId
                  }
                });
              }, 500)
            : dispatch({
                type: ordersTypes.SAVED_LIST_UPDATE_QUANTITY_FAIL,
                payload: {
                  currentId: newListId,
                  orderItemId: orderItemId
                }
              });
        }
        dispatchError();
      }
    }
  };

export const addItems =
  (listId, listName, items, onSuccess) => async (dispatch, getState) => {
    dispatch({ type: types.SAVED_LIST_DETAIL_ADD_ITEM_BEGINS });
    const http = getHttpInstance();
    const { storeId, catalogId, langId } = getStoreInfo();

    const mergedItems = Object.values(
      items.reduce((acc, { itemNumber, quantity }) => {
        if (!acc[itemNumber]) {
          acc[itemNumber] = {
            itemNumber,
            quantity: Number(quantity)
          };
        } else {
          acc[itemNumber].quantity += Number(quantity);
        }

        return acc;
      }, {})
    );

    const newItem = mergedItems.map(({ itemNumber, quantity }) => ({
      partNumber: itemNumber,
      quantity
    }));

    const dispatchError = error => {
      dispatch({
        type: types.SAVED_LIST_DETAIL_ADD_ITEM_FAIL,
        payload: error
      });
      dispatch(
        setError(
          ERROR_DOMAIN.SAVED_LIST,
          ERROR_PATH.MAIN,
          normalizeError(error)
        )
      );
    };

    //service to duplicate self owned cart list
    const response = await duplicateSavedList(
      listId,
      getState(),
      dispatchError
    );

    if (response?.newListId) {
      const newListId = response.newListId;
      try {
        const url = replaceTokensInUrl(
          endpoints.SAVED_LIST_ADD_ITEMS,
          storeId,
          langId,
          catalogId
        );
        const data = {
          requisitionListId: newListId,
          items: newItem.filter(({ partNumber }) => partNumber !== '')
        };
        await http.request({
          url,
          method: 'post',
          data,
          responseType: 'json'
        });
        dispatch({ type: types.SAVED_LIST_DETAIL_ADD_ITEM_SUCCESS });
        onSuccess && onSuccess();
        if (newListId !== listId) {
          const redirectURL = replaceTokensInUrl(
            links.SAVED_LIST_DETAIL_URL,
            catalogId,
            langId,
            storeId,
            getState().common.csrName,
            newListId,
            listName,
            types.SAVED_LIST_DETAIL_PATH_STRING
          );
          window.location.replace(redirectURL);
        }
      } catch (error) {
        dispatchError(error);
      }
    }
  };

/** TODO: This Need to be replaced for the updateLineItemNotes from Orders */
export const updateItemNotes =
  (inputValue, orderItemId, requisitionListId, type, focusedInputNoteId) =>
  async dispatch => {
    const { storeId, langId } = getStoreInfo();
    const http = getHttpInstance();
    const url = replaceTokensInUrl(
      endpoints.ORDERS_UPDATE_LINE_ITEM_NOTES_URL,
      storeId,
      langId
    );

    const data = {
      input: inputValue,
      orderId: requisitionListId,
      orderItemId,
      type,
      focusedInputNoteId
    };
    try {
      await http.request({
        url,
        method: 'put',
        data,
        responseType: 'json'
      });

      dispatch({
        type: ordersTypes.SAVED_LIST_UPDATE_LINE_ITEM_NOTE,
        payload: data
      });

      dispatch({
        type: types.ITEM_NOTES_UPDATE_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: types.ITEM_NOTES_UPDATE_FAIL,
        payload: error
      });
      dispatch(
        setError(
          ERROR_DOMAIN.SAVED_LIST,
          ERROR_PATH.MAIN,
          normalizeError(error)
        )
      );
    }
  };

export const addItemsToQuickOrder =
  ({ cleanValues, setFieldValue, close, ascendingOrderItems }) =>
  async () => {
    if (ascendingOrderItems) {
      const newItems = ascendingOrderItems.reverse().reduce((prev, current) => {
        const newValue = {
          ...DEFAULT_ROW_VALUES,
          quantity: current.quantity,
          itemNumber: current.partNumber,
          assetId:
            current?.orderItemExtendAttribute?.lineItemNotes
              ?.customerSerialNumber,
          customerItemNumber:
            current?.orderItemExtendAttribute?.lineItemNotes?.lineItemNo,
          customerPartNumber:
            current?.orderItemExtendAttribute?.lineItemNotes
              ?.customerPartNumber,
          lineItemNote:
            current?.orderItemExtendAttribute?.lineItemNotes?.partNote,
          id: v4()
        };
        return [...prev, newValue];
      }, cleanValues);
      const modifiedNewItems = [
        ...newItems,
        { quantity: '', itemNumber: '', id: v4() }
      ];
      setFieldValue('items', modifiedNewItems);
      close();
    }
  };
