import { getStoreLocationInfo } from '@app/store/store/actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import styles from '../../../dealer-locator/DealerLocationListForm/DealerLocationList/DealerLocation/DealerLocation.module.scss';
import DealerLocationStoreHours from '../DealerLocationList/DealerLocation/DealerLocationStoreHours';
import DealerLocationServiceOffered from '../DealerLocationList/DealerLocation/DealerLocationServiceOffered';
import StoreLocationContactInfo from '@app/components/common/StoreLocationContactInfo';
import { normalizeUrl, replaceTokensInString } from '@app/utils';
import endpoints from '@app/constants/endpoints';
import Conditional from '@app/components/common/Conditional';
import { ComponentLoader } from '@cat-ecom/pcc-components';

const DealerLocationDetails = ({ storeLocation, dealerStoreId }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const locationCode = storeLocation?.substring(dealerStoreId?.length);
  const storeDetails = useSelector(s => s?.store?.[locationCode]);
  const isDisplayStoreDetailsLoading = useSelector(
    s => s.store?.getStoreLocationInfoLoading
  );

  useEffect(() => {
    if (!storeDetails) {
      dispatch(
        getStoreLocationInfo({}, true, locationCode, true, dealerStoreId)
      );
    }
  }, [storeDetails, locationCode, dealerStoreId]);
  const hasContactInfo =
    storeDetails?.email ||
    storeDetails?.phone ||
    storeDetails?.website ||
    storeDetails?.drivingDirections;
  const hasServiceOffered = storeDetails?.serviceOffered.length > 0;
  const storeHours = storeDetails?.storeHours;
  const { catalogId, langId, storeId } = useSelector(s => s?.common);
  const { seoURL } = useSelector(s => s?.dealer);
  const contactUrl = normalizeUrl(
    replaceTokensInString(
      `${seoURL}${endpoints.CONTACT_US_DEALER}`,
      catalogId,
      langId,
      storeId
    )
  );
  return (
    <>
      <Conditional
        test={!!storeDetails}
        fallback={isDisplayStoreDetailsLoading ? <ComponentLoader /> : null}
      >
        <p
          className={cx(
            'exclude-global',
            'u-color--DarkGray',
            'm-0',
            'text-univers',
            'text-start',
            styles['dealer-location__details-indent']
          )}
        >
          {storeDetails?.country ?? ''}
        </p>
        <div
          className={cx(
            'row',
            'flex-column',
            'flex-lg-row',
            'pt-3',
            styles['dealer-location__details-indent']
          )}
        >
          {(hasContactInfo || hasServiceOffered) && (
            <div className="col">
              {hasContactInfo && (
                <section className="mb-4">
                  <header>
                    <h3 className="h6 mb-1 text-start">
                      {t('FOOTER_CUSTOMER_SERVICE')}:
                    </h3>
                  </header>
                  <StoreLocationContactInfo
                    contactUrl={contactUrl}
                    email={storeDetails?.email}
                    iconSize={16}
                    phone={storeDetails?.phone}
                    website={storeDetails?.website}
                    drivingDirections={storeDetails?.drivingDirections}
                  />
                </section>
              )}
              {hasServiceOffered && (
                <DealerLocationServiceOffered
                  serviceOffered={storeDetails?.serviceOffered}
                />
              )}
            </div>
          )}
          {storeHours && (
            <DealerLocationStoreHours
              storeHours={storeDetails?.storeHours}
              alwaysOpen={storeDetails?.alwaysOpen}
            />
          )}
        </div>
      </Conditional>
    </>
  );
};

export default DealerLocationDetails;
