import { ASSET_MAKE } from '@app/constants/commonConstants';
import * as types from './constants';

export const getRequestServiceAdditionalParams = (
  requestServiceReferenceId,
  locale,
  serviceInsightDetails
) => {
  const { id, title, impact, dateTime, fromServiceInsight } =
    serviceInsightDetails || {};
  const reference = fromServiceInsight
    ? { id, type: types.COMMERCIAL_OFFER, title, impact, dateTime }
    : { id: requestServiceReferenceId, type: types.GENERAL };
  return {
    reference,
    locale,
    ...(fromServiceInsight && { locationId: requestServiceReferenceId })
  };
};

export const getRequestServicePayload = (
  equipmentData,
  requestServiceData,
  requestServiceReferenceId,
  locale,
  serviceInsightDetails
) => {
  const equipmentModelFamily = `${equipmentData.model} ${equipmentData.equipmentFamily}`;
  const { preferredDateTime, ...requestServiceFormData } = requestServiceData;
  delete preferredDateTime.month;
  const dates = preferredDateTime?.dates
    ? preferredDateTime.dates.map(({ formattedDate }) => formattedDate)
    : [];
  const datePayload = {
    preferedDateTime: {
      ...preferredDateTime,
      dates
    }
  };
  const additionalParams = getRequestServiceAdditionalParams(
    requestServiceReferenceId,
    locale,
    serviceInsightDetails
  );
  return {
    assetId: equipmentData.validatedAsset ? equipmentData.assetId : '',
    assetMake: ASSET_MAKE,
    assetModel: equipmentData.model,
    assetSerialNumber: equipmentData.serialNumber
      ? equipmentData.serialNumber
      : equipmentModelFamily,
    ...requestServiceFormData,
    service: datePayload,
    ...additionalParams
  };
};
